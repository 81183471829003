import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { Category, UserTypeEnum } from '@Types';
import { useUserContext } from '@Contexts';

import styles from './CategoriesList.module.scss';
import { CategoryTile } from '../CategoryTile/CategoryTile';
import { Divider } from '@Components/Divider/Divider';

const DEFAULT_VALUE: never[] = [];

interface CategoriesListProps {
  name: string;
  categoriesList: Category[];
  disabled?: boolean;
  required?: boolean;
}

export const CategoriesList: React.FC<CategoriesListProps> = ({
  name,
  categoriesList,
  disabled,
  required,
}) => {
  const { t } = useTranslation('common');
  const { control, errors: fieldErrors } = useFormContext();
  const { userData } = useUserContext();

  const getCategoryDescription = (category: Category) =>
    userData?.user_type === UserTypeEnum.FUNDER
      ? category.description_for_funders
      : category.description_for_groups;

  return (
    <Fragment>
      <Controller
        name={name}
        control={control}
        defaultValue={DEFAULT_VALUE}
        rules={{
          validate: (checked: number[]) =>
            (required && checked.length > 0) || (t('errors.no_category') as string),
        }}
        render={({ value, onChange, ref }) => {
          return (
            <Grid
              container
              spacing={2}
              className={classnames({ [styles.categoriesError]: fieldErrors[name] })}
            >
              <Grid item xs={12}>
                <CategoryTile
                  id={0}
                  name={'All'}
                  checked={value.includes(0)}
                  description={''}
                  image={''}
                  disabled={disabled}
                  onChange={(e) => {
                    let newArray: number[] = [];
                    if (e.target.checked) {
                      newArray = [0, ...categoriesList.map((cat) => cat.id)];
                    } else {
                      newArray = [];
                    }
                    onChange(newArray);
                  }}
                  inputRef={ref}
                />
                <Divider className={styles.divider} />
              </Grid>
              {categoriesList.map((cat, i) => (
                <Grid item xs={12} key={cat.id}>
                  <CategoryTile
                    id={cat.id}
                    name={cat.name}
                    checked={value.includes(cat.id)}
                    description={getCategoryDescription(cat)}
                    image={cat.image}
                    disabled={disabled}
                    onChange={(e) => {
                      let newArray = [];
                      if (e.target.checked) {
                        newArray = [...value, cat.id];
                        if (newArray.length === categoriesList.length) {
                          newArray.push(0);
                        }
                      } else {
                        newArray = value.filter(
                          (catToCheck: number) => cat.id !== catToCheck && catToCheck !== 0,
                        );
                      }

                      onChange(newArray);
                    }}
                    inputRef={ref}
                  />
                  {i + 1 !== categoriesList.length && <Divider className={styles.divider} />}
                </Grid>
              ))}
            </Grid>
          );
        }}
      />
      {fieldErrors[name] && (
        <p className={styles.errorMessage}>{fieldErrors.categories?.message}</p>
      )}
    </Fragment>
  );
};
