import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ContentModal, Loader } from '@Components';
import { useCategories } from '@Hooks/Api';
import { CategoriesList } from './CategoriesList';

import styles from './CategoriesListController.module.scss';
import { useTranslation } from 'react-i18next';

interface CategoriesListControllerProps {
  name: string;
}

export const CategoriesListController: React.FC<CategoriesListControllerProps> = ({ name }) => {
  const { t } = useTranslation('socialValue');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { errors: fieldErrors } = useFormContext();

  const [fetchCategories, { data: categoriesData, loading: isCategoriesLoading }] =
    useCategories(true);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const focusAreasValue: number[] | null | undefined = useWatch({ name: 'focusAreas' });
  const focusAreasCount = focusAreasValue
    ? focusAreasValue.filter((areaId: number) => areaId !== 0).length
    : 0;

  if (isCategoriesLoading || !categoriesData) return <Loader />;

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <p
        className={styles.selectedCounter}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {t('categoriesSelected', { categoriesCount: focusAreasCount })}
      </p>
      {fieldErrors[name] && <p className={styles.errorMessage}>{fieldErrors[name]?.message}</p>}
      {/* We use keepMounted here to ensure the form field in this modal never leaves the DOM, as that removes the value from the form */}
      <ContentModal keepMounted isOpen={isModalOpen} onClose={closeModal} title="Categories">
        <CategoriesList
          categoriesList={categoriesData}
          name={name}
          disabled={false}
          required={true}
        />
      </ContentModal>
    </>
  );
};
