import { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsListPage } from '@Components';
import { useCategories, useGetLocalAuthorities, useViewMoreFundsList } from '@Hooks/Api';
import { RoutingPaths } from '@App/paths';
import { useUserContext } from '@Contexts';
import { UserTypeEnum } from '@Types';

enum CTATypeEnum {
  'UNLOGGED' = 'UNLOGGED',
  'FUNDER' = 'FUNDER',
  'GROUP' = 'GROUP',
}

const MAP_CTA_LINKS = {
  [CTATypeEnum.UNLOGGED]: RoutingPaths.SIGNUP_GROUP,
  [CTATypeEnum.FUNDER]: RoutingPaths.DASHBOARD_FUNDER,
  [CTATypeEnum.GROUP]: RoutingPaths.DASHBOARD_GROUP,
};

export const FundsListPageController = () => {
  const {
    data: fundsList,
    loading: isFundsListLoading,
    hasNextPage,
    fetchMoreFunds,
    isFetchMoreLoading,
  } = useViewMoreFundsList();
  const { t } = useTranslation('project');
  const { userData } = useUserContext();
  const [currCtaType, setCurrCtaType] = useState<CTATypeEnum>(CTATypeEnum.UNLOGGED);
  const [getCategories, { data: categoriesList, loading: isCategoriesListLoading }] =
    useCategories(true);
  const [getLocalAuthorities, { data: localAuthorities, loading: isLocalAuthoritiesLoading }] =
    useGetLocalAuthorities();

  useEffect(() => {
    getLocalAuthorities();
  }, [getLocalAuthorities]);

  useEffect(() => {
    if (userData?.user_type === UserTypeEnum.FUNDER) {
      setCurrCtaType(CTATypeEnum.FUNDER);
    }
    if (userData?.user_type === UserTypeEnum.GROUP) {
      setCurrCtaType(CTATypeEnum.GROUP);
    }
  }, [userData?.user_type]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const handleFetchMoreFunds = useCallback(() => {
    fetchMoreFunds();
  }, [fetchMoreFunds]);

  const getCtaData = useCallback(
    (ctaType: CTATypeEnum) => ({
      headerTitle: t('common:funds_list_page.header.title'),
      headerSubtitle: t(`common:funds_list_page.header.${ctaType}.subtitle`),
      headerButton: t(`common:funds_list_page.header.${ctaType}.button`),
      cardText: t(`common:funds_list_page.cta_card.${ctaType}.text`),
      cardButtonText: t(`common:funds_list_page.cta_card.${ctaType}.button`),
      ctaLink: MAP_CTA_LINKS[ctaType],
    }),
    [t],
  );

  const ctaData = getCtaData(currCtaType);

  return (
    <ActionsListPage
      fundsList={fundsList}
      fetchMore={handleFetchMoreFunds}
      isFetchMoreLoading={isFetchMoreLoading}
      hasNextPage={hasNextPage}
      isLoading={isFundsListLoading}
      headerTitle={ctaData.headerTitle}
      headerSubtitle={ctaData.headerSubtitle}
      headerButton={ctaData.headerButton}
      cardText={ctaData.cardText}
      cardButtonText={ctaData.cardButtonText}
      ctaLink={ctaData.ctaLink}
      categoriesList={categoriesList}
      localAuthorities={localAuthorities}
    />
  );
};
