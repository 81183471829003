import { Button, Tile } from '@Components/common';
import { Fund, FundStatusEnum } from '@Types';
import { useTranslation } from 'react-i18next';
import styles from './FundPreviewCard.module.scss';
import { FundName } from './FundName';
import { FundPreviewInfo } from './FundPreviewInfo';
import { Routes } from '@App/paths';

export interface FundPreviewCardProps {
  fund: Fund;
}

export const FundPreviewCard = ({ fund }: FundPreviewCardProps) => {
  const { id, status, funded_projects_count: numberOfFundedProjects, details } = fund;
  const { name, starting_amount: totalFunds, remaining_amount: remainingFunds } = details;
  const { t } = useTranslation('fund');
  const distributedFunds = Number(totalFunds) - Number(remainingFunds);

  return (
    <Tile className={styles.container}>
      <>
        <FundName name={name} status={status} />
        <div className={styles.infoContainer}>
          <FundPreviewInfo
            numberOfFundedProjects={numberOfFundedProjects || 0}
            totalFunds={Number(totalFunds)}
            distributedFunds={distributedFunds}
          />
          {[FundStatusEnum.LIVE, FundStatusEnum.CLOSED].includes(status) ? (
            <Button size="small" buttonType="secondary" to={Routes.FUND_MANAGEMENT(id)}>
              {t('preview.manage')}
            </Button>
          ) : (
            <Button size="small" buttonType="secondary" to={Routes.FUND_IMPACT(id)}>
              {t('preview.view_impact')}
            </Button>
          )}
        </div>
      </>
    </Tile>
  );
};
