import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFunderReportSummaryData } from '@Hooks/Api/useGetFunderReportSummaryData';
import { RiMoneyPoundCircleFill, RiHandHeartFill, RiEmpathizeFill } from 'react-icons/ri';
import { TotalizerCard, TotalizerProps } from '@Components/Impact/TotalizerCard';
import styles from './FunderDashboardTotalizers.module.scss';
import { TFunctionResult } from 'i18next';

export interface FunderDashboardTotalizersProps {
  organizationId: number;
}

export const FunderDashboardTotalizers = ({ organizationId }: FunderDashboardTotalizersProps) => {
  const { t } = useTranslation('dashboard');
  const [getFunderReportSummaryData, { data: summaryData }] = useGetFunderReportSummaryData();

  const {
    total_funding_distributed = 0,
    projects_funded_count = 0,
    total_people_supported = 0,
  } = summaryData || {};

  const totalizers: TotalizerProps[] = [
    {
      title: t('impact.total_distributed'),
      amount: t('common:whole_currency', { amount: total_funding_distributed }),
      icon: <RiMoneyPoundCircleFill size={22} />,
      tooltipText: t('impact.total_distributed_tooltip'),
    },
    {
      title: t('impact.projects_funded'),
      amount: t('common:whole_number', { amount: projects_funded_count }),
      icon: <RiHandHeartFill size={22} />,
      tooltipText: t('impact.projects_funded_tooltip'),
    },
    {
      title: t('impact.people_supported'),
      amount: t('common:whole_number', { amount: total_people_supported }),
      icon: <RiEmpathizeFill size={22} />,
      tooltipText: t('impact.people_supported_tooltip'),
    },
  ];

  useEffect(() => {
    if (organizationId) {
      getFunderReportSummaryData(organizationId);
    }
  }, [organizationId, getFunderReportSummaryData]);

  return (
    <div className={styles.totalizersContainer}>
      {totalizers.map((totalizer) => (
        <TotalizerCard totalizer={totalizer} />
      ))}
    </div>
  );
};
