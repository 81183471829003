import { Divider, Loader } from '@Components';
import { Tile, Title } from '@Components/common';
import { Fund } from '@Types';
import { Trans, useTranslation } from 'react-i18next';
import styles from './FundsPreview.module.scss';
import { Routes } from '@App/paths';
import { Grid } from '@material-ui/core';
import { FundPreviewCard } from './Components/FundPreviewCard';
import { FundPreviewCTACard } from './Components/FundPreviewCTACard';

export interface FundsPreviewProps {
  fundsList: Fund[];
  isFundsListLoading: boolean;
}

export const FundsPreview = ({ fundsList, isFundsListLoading }: FundsPreviewProps) => {
  const { t } = useTranslation('fund');

  const hasFunds = fundsList.length > 0;

  return (
    <Tile className={styles.container}>
      <>
        <Title
          title={t('preview.title')}
          subtitle={
            <Trans
              ns="fund"
              i18nKey={hasFunds ? 'preview.subtitle' : 'preview.subtitle_no_funds'}
              components={[
                <a
                  href={hasFunds ? Routes.MY_FUNDS : Routes.FUND_CREATE}
                  className={styles.subtitleLink}
                />,
              ]}
            />
          }
          value={fundsList.length || 0}
        />
        <Divider small />
        <Grid container spacing={1} className={styles.fundContainer}>
          {!isFundsListLoading ? (
            <>
              {hasFunds ? (
                <>
                  {fundsList.map((fund) => (
                    <Grid item className={styles.fundItem} key={fund.id}>
                      <FundPreviewCard fund={fund} />
                    </Grid>
                  ))}
                </>
              ) : (
                <FundPreviewCTACard />
              )}
            </>
          ) : (
            <Loader />
          )}
        </Grid>
      </>
    </Tile>
  );
};
