import React from 'react';
import styles from './ProfileChecklist.module.scss';
import { useTranslation } from 'react-i18next';
import { FundingProfileData, GroupProfileData, GroupTypes, UserTypeEnum } from '@Types';
import classnames from 'classnames';
import { RiCircleFill, RiCheckboxCircleFill } from 'react-icons/ri';

interface ProfileChecklistProps {
  profileData: GroupProfileData | FundingProfileData;
  userType: UserTypeEnum;
}

export const ProfileChecklist = ({ profileData, userType }: ProfileChecklistProps) => {
  const { t } = useTranslation('dashboard');
  // Same logic we use in the BE to determine if we
  // count the charity/company number to the completion %
  const charityNumberCheck =
    profileData.type === GroupTypes.COMMUNITY_GROUP || userType === UserTypeEnum.FUNDER;

  //  Have to check this differently to the others as !! notation would count 0 as false
  //  when we class it as true because they have given an answer.
  const paidStaffNumberCompleted = profileData.paid_staff_number != null;
  const volunteerNumberCompleted = profileData.volunteers_number != null;

  // The breakdown of this logic and why it was chosen this way can be found here:
  // https://www.notion.so/actionfunder/Profile-Completion-Breakdown-a4fa285a4e004216a7e6fa4f7183ce2e
  const checklist = {
    description: !!profileData.description && !!profileData.type,
    location:
      !!profileData.address1 &&
      !!profileData.postcode &&
      !!profileData.city &&
      !!profileData.county,
    avatarImage: !!profileData.avatar_image,
    focusAreas: !!profileData.categories,
    legal:
      !!profileData.legal_structure &&
      !!profileData.set_up_year &&
      (charityNumberCheck ? true : !!profileData.charity_or_company_number),
    socials:
      !!profileData.facebook_url ||
      !!profileData.twitter_url ||
      !!profileData.instagram_url ||
      !!profileData.linkedin_url ||
      !!profileData.website_url,
    contact:
      !!profileData.phone_number &&
      paidStaffNumberCompleted &&
      volunteerNumberCompleted &&
      !!profileData.member_name_1 &&
      !!profileData.member_email_1 &&
      !!profileData.member_name_2 &&
      !!profileData.member_email_2,
  };

  return (
    <div>
      {Object.entries(checklist).map(([item, value], index) => (
        <div key={index}>
          <div className={classnames(styles.item, value && styles.completed)}>
            {value ? <RiCheckboxCircleFill /> : <RiCircleFill />}
            {t(`profile_checklist.${item}`)}
          </div>
        </div>
      ))}
    </div>
  );
};
