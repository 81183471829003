import { useCategories, useGetExploreProjectsPost } from '@Hooks/Api';
import { useCallback, useEffect, useState } from 'react';
import { ResultsPage } from './ResultsPage';
import { Loader } from '@Components';
import { DrawingMode } from '@Types';
import { getCookie, setCookie } from '@Helpers/cookies';
import { GROUP_TYPE_CHOICES } from '@Helpers/choices';

export const ResultsPageController = () => {
  const [postcode, setPostcode] = useState<string | null>(null);
  const [category, setCategory] = useState<number[] | null>(null);
  const numberOfRequestsCookie = Number(getCookie('numberOfRequests'));
  const [numberOfRequests, setNumberOfRequests] = useState<number>(numberOfRequestsCookie || 0);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  useEffect(() => {
    setCookie('numberOfRequests', `${numberOfRequests}`, { expires: 7 });
  }, [numberOfRequests]);

  const [
    getMatchesPreview,
    { data: previewProjectsData, loading: isMatchesPreviewLoading, error: matchesPreviewErrors },
  ] = useGetExploreProjectsPost();
  const [fetchCategories, { data: categoriesData, loading: isCategoriesLoading }] =
    useCategories(true);

  const getMatches = useCallback(
    (category: number[] | null, postcode: string | null) => {
      getMatchesPreview({
        catchment_type: DrawingMode.UK_WIDE,
        categories: category,
        group_types_to_support: GROUP_TYPE_CHOICES,
        video_pitch_required: false,
        postcode: postcode,
        radius: null,
        maximum_grant_amount: null,
      }).then(() => {
        setIsFilterModalOpen(false);
      });
      setNumberOfRequests((numberOfRequests) => numberOfRequests + 1);
    },
    [getMatchesPreview],
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setPostcode(params.get('postcode') || null);
    const category = Number(params.get('category')) || null;
    if (category) {
      setCategory([category]);
    } else {
      setCategory(null);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  if (isCategoriesLoading) {
    return <Loader />;
  }
  return (
    <ResultsPage
      categoriesData={categoriesData}
      setPostcode={setPostcode}
      postcode={postcode}
      setCategory={setCategory}
      category={category}
      previewProjectsData={previewProjectsData}
      isMatchesPreviewLoading={isMatchesPreviewLoading}
      getMatches={getMatches}
      numberOfRequests={numberOfRequests}
      numberOfRequestsCookie={numberOfRequestsCookie}
      errors={matchesPreviewErrors}
      isFilterModalOpen={isFilterModalOpen}
      setIsFilterModalOpen={setIsFilterModalOpen}
    />
  );
};
