import { createTheme } from '@material-ui/core/styles';
import styleVariables from '@Styles/_gk_variables.module.scss';

export const gkTheme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        color: styleVariables.GKcolorT2,
        fontWeight: 700,
        fontFamily: styleVariables.fSofia,
        letterSpacing: '-0.5px',
        '&::placeholder': {
          color: styleVariables.GKcolorT2,
          fontWeight: 400,
        },
        '&$disabled': {
          color: styleVariables.GKcolorT2Pale,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff',
        '&:hover $notchedOutline': {
          borderColor: styleVariables.GKcolorP1,
        },
        '&$focused $notchedOutline': {
          borderColor: styleVariables.GKcolorP1,
        },
        '&$disabled $notchedOutline': {
          opacity: '0.6',
        },
        '&$error $notchedOutline': {
          borderColor: styleVariables.GKcolorE1,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: styleVariables.fSofia,
        '&$error': {
          color: styleVariables.GKcolorE1,
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'flex-start',
        marginRight: '0',
      },
    },
    MuiIconButton: {
      label: {
        backgroundColor: '#fff',
      },
      colorSecondary: {
        '&:hover': {
          backgroundColor: styleVariables.GKcolorP2Pale,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: styleVariables.GKcolorP2,
        '&$checked': {
          color: styleVariables.GKcolorP2,
          '&:hover': {
            backgroundColor: styleVariables.GKcolorP2Pale,
          },
        },
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: styleVariables.fSofia,
        letterSpacing: '-0.5px',
      },
    },
  },
});
