import React from 'react';
import { withStyles, createStyles, LinearProgress, LinearProgressProps } from '@material-ui/core';
import styleVariables from '@Styles/_variables.module.scss';

export const StyledProgressBar = withStyles(() =>
  createStyles({
    root: {
      height: '6px',
    },
    colorPrimary: {
      backgroundColor: styleVariables.colorT2Paler,
    },
    colorSecondary: {
      backgroundColor: styleVariables.colorT2Paler,
    },
    barColorPrimary: {
      backgroundColor: styleVariables.colorT1,
    },
    barColorSecondary: {
      backgroundColor: styleVariables.colorT4,
    },
    dashedColorPrimary: {
      backgroundImage: 'none',
    },
    bar1Buffer: {
      backgroundColor: styleVariables.colorT1,
    },
    bar2Buffer: {
      backgroundColor: styleVariables.colorT3,
    },
  }),
)(LinearProgress);

interface ProgressProps {
  progress: number;
  progressBuffer?: number;
  className?: string;
  danger?: boolean;
  variant: LinearProgressProps['variant'];
}

export const Progress: React.FC<ProgressProps> = ({
  children,
  progress,
  progressBuffer,
  className,
  danger,
  variant,
}) => {
  return (
    <div className={className}>
      {children}
      <StyledProgressBar
        variant={variant}
        value={progress}
        valueBuffer={progressBuffer}
        color={danger ? 'secondary' : 'primary'}
      />
    </div>
  );
};
