import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Content, PageTitle } from '@Components';
import { Link } from '@Components/common';
import { useUserContext, useNotificationsContext } from '@Contexts';
import { useRouter } from '@Helpers/useRouter';
import { useResendVerificationEmail } from '@Hooks/Api';
import { Routes, RoutingPaths } from '@App/paths';

import styles from './ConfirmEmail.module.scss';
import { UserTypeEnum } from '@Types';

export const ConfirmEmailController = () => {
  const { t } = useTranslation('createAccount');
  const { userData, isUserLoading } = useUserContext();
  const { push } = useRouter();
  const userLoaded = !isUserLoading && userData;
  const [resendVerificationEmail, { loading: isVerificationEmailResending }] =
    useResendVerificationEmail();
  const { success: successNotification, error: errorNotification } = useNotificationsContext();

  const onResendVerificationEmail = () => {
    resendVerificationEmail()
      .then(() => {
        successNotification(t('verify_email.resend_email_success'));
      })
      .catch(() => {
        errorNotification(t('verify_email.resend_email_fail'));
      });
  };

  useEffect(() => {
    if (userLoaded && userData?.email_confirmed && !userData.has_organization_profile) {
      if (userData.user_type === UserTypeEnum.GROUP) {
        push(Routes.GROUP_PROFILE_CREATE);
      } else {
        push(Routes.FUNDER_PROFILE_CREATE);
      }
    }
  }, [
    userLoaded,
    push,
    userData?.email_confirmed,
    userData?.user_type,
    userData?.has_organization_profile,
  ]);

  return (
    <Content size="small">
      <div className={styles.container}>
        <PageTitle title={t('confirm_email.title')} subtitle={t('confirm_email.check_inbox')} />
        <div className={styles.troubleBox}>
          <h3 className={styles.troubleTitle}>{t('confirm_email.having_trouble')}</h3>
          <ul className={styles.troubleList}>
            <li>{t('confirm_email.check_spam')}</li>
            <li>
              <Trans
                ns="createAccount"
                i18nKey="confirm_email.resend"
                components={[
                  <Link
                    onClick={onResendVerificationEmail}
                    secondary
                    disabled={isVerificationEmailResending}
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                ns="createAccount"
                i18nKey="confirm_email.contact_support"
                components={[<Link href="mailto:hello@actionfunder.org" secondary />]}
              />
            </li>
          </ul>
        </div>
      </div>
    </Content>
  );
};
