import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useFormContext, Controller } from 'react-hook-form';
import {
  InputAdornment,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
} from '@material-ui/core';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { RiTwitterXFill } from 'react-icons/ri';

import LockIcon from '@material-ui/icons/Lock';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';

import {
  createMenuItems,
  CreateMenuItemsProps,
  FieldNote,
  AvatarUpload,
  SectionHeader,
  StatusChip,
} from '@Components';
import { Button, TextInput, SelectInput } from '@Components/common';
import { OrganizationStructureEnum } from '@Types';
import { useIsProfileComplete } from '@Helpers/useIsProfileComplete';

import styles from './AdditionalInfoFields.module.scss';

const useStyles = makeStyles({
  toggleBtn: {
    width: '100%',
    marginBottom: '24px',
    justifyContent: 'space-between',
  },
});

interface AdditionalInfoFieldsProps {
  isSaving?: boolean;
}

export const AdditionalInfoFields: React.FC<AdditionalInfoFieldsProps> = ({ isSaving }) => {
  const { t } = useTranslation('createAccount');
  const { register, errors: fieldErrors, control } = useFormContext();
  const [isFieldsVisible, setIsFieldsVisible] = useState(false);
  const classes = useStyles();
  const { isProfileComplete } = useIsProfileComplete();

  useEffect(() => {
    if (isProfileComplete || fieldErrors) {
      setIsFieldsVisible(true);
    }
  }, [fieldErrors, isProfileComplete]);

  const toggleIsFieldsVisible = () => {
    setIsFieldsVisible(!isFieldsVisible);
  };

  const organizationStructureOptions: CreateMenuItemsProps['items'] = useMemo(
    () =>
      Object.keys(OrganizationStructureEnum).map((key) => ({
        label: t(`create_profile_common.organization_structure.${key}`),
        menuItemProps: {
          value: key,
        },
      })),
    [t],
  );

  return (
    <>
      <SectionHeader title={t('create_profile_common.organization_avatar_header')} />
      <AvatarUpload />

      <Button
        buttonType="tertiary"
        endIcon={isFieldsVisible ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        className={classes.toggleBtn}
        onClick={toggleIsFieldsVisible}
      >
        {isFieldsVisible
          ? t('create_profile_common.hide_additional_fields')
          : t('create_profile_common.show_additional_fields')}
      </Button>

      <div className={classnames(!isFieldsVisible && styles.hidden)}>
        <div className={styles.halfWidth}>
          <TextInput
            label={t('create_profile_common.organization_set_up_year')}
            type="number"
            inputRef={register({ valueAsNumber: true })}
            name="set_up_year"
            error={!!fieldErrors.set_up_year}
            helperText={fieldErrors.set_up_year?.message}
            disabled={isSaving}
          />
          <TextInput
            label={t('create_profile_common.charity_or_company_number')}
            inputRef={register}
            name="charity_or_company_number"
            error={!!fieldErrors.charity_or_company_number}
            helperText={fieldErrors.charity_or_company_number?.message}
            disabled={isSaving}
          />
        </div>

        <SectionHeader title={t('create_profile_common.legal_structure_header')} />

        <FormControl error={!!fieldErrors.legal_structure}>
          <InputLabel>{t('create_profile_common.legal_structure')}</InputLabel>
          <Controller
            name="legal_structure"
            control={control}
            defaultValue={''}
            render={({ ref, onChange, value }) => (
              <SelectInput
                inputRef={ref}
                onChange={onChange}
                label={t('create_profile_common.legal_structure')}
                value={value}
                error={!!fieldErrors.legal_structure}
                disabled={isSaving}
              >
                {createMenuItems({ items: organizationStructureOptions })}
              </SelectInput>
            )}
          />
          {fieldErrors.legal_structure?.message && (
            <FormHelperText>{fieldErrors.legal_structure?.message}</FormHelperText>
          )}
        </FormControl>

        <SectionHeader
          title={t('create_profile_common.online_presence')}
          subtitle={t('create_profile_common.online_presence_hint')}
        />

        <TextInput
          label={t('create_profile_common.website')}
          placeholder="https://www.example.com"
          inputRef={register}
          name="website_url"
          error={!!fieldErrors.website_url}
          helperText={fieldErrors.website_url?.message}
          disabled={isSaving}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LanguageIcon />
              </InputAdornment>
            ),
          }}
        />

        <div className={styles.halfWidth}>
          <TextInput
            label={t('create_profile_common.facebook')}
            placeholder="www.facebook.com/MyUsername"
            inputRef={register}
            name="facebook_url"
            error={!!fieldErrors.facebook_url}
            helperText={fieldErrors.facebook_url?.message}
            disabled={isSaving}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FacebookIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            label={t('create_profile_common.linkedin')}
            placeholder="www.linkedin.com/company/MyUsername"
            inputRef={register}
            name="linkedin_url"
            error={!!fieldErrors.linkedin_url}
            helperText={fieldErrors.linkedin_url?.message}
            disabled={isSaving}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LinkedInIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={styles.halfWidth}>
          <TextInput
            label={t('create_profile_common.instagram')}
            placeholder="www.instagram.com/MyUsername"
            inputRef={register}
            name="instagram_url"
            error={!!fieldErrors.instagram_url}
            helperText={fieldErrors.instagram_url?.message}
            disabled={isSaving}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InstagramIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            label={t('create_profile_common.twitter')}
            placeholder="www.x.com/MyUsername"
            inputRef={register}
            name="twitter_url"
            error={!!fieldErrors.twitter_url}
            helperText={fieldErrors.twitter_url?.message}
            disabled={isSaving}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <RiTwitterXFill />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <SectionHeader
          title={
            <span className={styles.headerWithChip}>
              <span>{t('create_profile_common.phone_number_header')}</span>
              <StatusChip
                color="primary"
                label={
                  <span className={styles.chipContent}>
                    <LockIcon /> {t('create_profile_common.private')}
                  </span>
                }
              />
            </span>
          }
          subtitle={t('create_profile_common.phone_number_subheader')}
        />
        <TextInput
          inputRef={register}
          name="phone_number"
          placeholder="+441234567890"
          error={!!fieldErrors.phone_number}
          helperText={fieldErrors.phone_number?.message}
          disabled={isSaving}
        />
        <FieldNote icon={<EmojiObjectsOutlinedIcon style={{ fontSize: 15 }} />}>
          {t('create_profile_common.phone_number_hint')}
        </FieldNote>

        <SectionHeader
          title={
            <span className={styles.headerWithChip}>
              <span>{t('create_profile_common.your_people_header')}</span>
              <StatusChip
                color="primary"
                label={
                  <span className={styles.chipContent}>
                    <LockIcon /> {t('create_profile_common.private')}
                  </span>
                }
              />
            </span>
          }
          subtitle={t('create_profile_common.your_people_subtitle')}
        />

        <div className={styles.halfWidth}>
          <TextInput
            label={t('create_profile_common.paid_staff_number')}
            inputRef={register({ valueAsNumber: true })}
            type="number"
            name="paid_staff_number"
            error={!!fieldErrors.paid_staff_number}
            helperText={fieldErrors.paid_staff_number?.message}
            disabled={isSaving}
          />
          <TextInput
            label={t('create_profile_common.volunteers_number')}
            inputRef={register({ valueAsNumber: true })}
            type="number"
            name="volunteers_number"
            error={!!fieldErrors.volunteers_number}
            helperText={fieldErrors.volunteers_number?.message}
            disabled={isSaving}
          />
        </div>

        <SectionHeader
          subtitle={t('create_profile_common.members_contact_header')}
          className={styles.membersContactHeader}
        />

        <div className={styles.halfWidth}>
          <TextInput
            label={t('create_profile_common.member_full_name')}
            inputRef={register}
            name="member_name_1"
            error={!!fieldErrors.member_name_1}
            helperText={fieldErrors.member_name_1?.message}
            disabled={isSaving}
          />
          <TextInput
            label={t('create_profile_common.member_email')}
            inputRef={register}
            type="email"
            name="member_email_1"
            error={!!fieldErrors.member_email_1}
            helperText={fieldErrors.member_email_1?.message}
            disabled={isSaving}
          />
        </div>
        <div className={styles.halfWidth}>
          <TextInput
            label={t('create_profile_common.member_full_name')}
            inputRef={register}
            name="member_name_2"
            error={!!fieldErrors.member_name_2}
            helperText={fieldErrors.member_name_2?.message}
            disabled={isSaving}
          />
          <TextInput
            label={t('create_profile_common.member_email')}
            inputRef={register}
            type="email"
            name="member_email_2"
            error={!!fieldErrors.member_email_2}
            helperText={fieldErrors.member_email_2?.message}
            disabled={isSaving}
          />
        </div>
      </div>
    </>
  );
};
