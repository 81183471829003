import styles from './ProjectStatus.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { AFRightUpIcon } from '@Components/Icons/AFRightUpIcon';
import { Link } from '@Components/common';
import { getFundPath } from '@Helpers';

interface ProjectStatusProps {
  status: string;
  small?: boolean;
  fundedBy?: { name: string; id: number };
}

export const ProjectStatus = ({ status, small, fundedBy }: ProjectStatusProps) => {
  const { t } = useTranslation('project');

  return (
    <div className={classnames(styles[status], styles.container)}>
      <AFRightUpIcon
        className={classnames(styles[status], styles.icon, small && styles.smallIcon)}
      />
      <p className={classnames(styles.status, small && styles.smallStatus)}>
        {t(`status.${status}`)}
      </p>
      {fundedBy && (
        <p className={styles.fundedBy}>
          {
            <Trans
              ns="project"
              i18nKey="management.funded_by"
              components={[<Link to={getFundPath(fundedBy.id)} underline></Link>]}
              values={{ fund_name: fundedBy.name }}
            />
          }
        </p>
      )}
    </div>
  );
};
