import { GroupTypes } from '@Types/OrganizationTypes';

export const ALL_CHOICE = 'ALL';

export const enumToChoices = (theEnum: any, includeAllChoice: boolean) => {
  const result = Object.keys(theEnum);
  if (includeAllChoice) {
    return [ALL_CHOICE, ...result];
  } else {
    return result;
  }
};

export const GROUP_TYPE_CHOICES = enumToChoices(GroupTypes, false);
export const GROUP_TYPE_CHOICES_WITH_ALL = enumToChoices(GroupTypes, true);
