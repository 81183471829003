import { Button, Tile } from '@Components/common';
import { Project, ProjectStatusEnum } from '@Types';
import styles from './PreviewCard.module.scss';
import { ProjectThumbnail } from './ProjectThumbnail';
import { getHeaderImage } from '@Helpers/getHeaderImage';
import classnames from 'classnames';
import { PreviewInfo } from './PreviewInfo';
import { Routes } from '@App/paths';
import { useTranslation } from 'react-i18next';

export interface PreviewCardProps {
  project: Project;
}

export const PreviewCard = ({ project }: PreviewCardProps) => {
  const {
    status,
    update_count,
    funding_offers_count,
    details: { name },
    matches_count,
    written_pitch,
    video_pitch,
    id,
    has_accepted_invitation,
  } = project;
  const { t } = useTranslation('project');
  return (
    <Tile
      className={classnames(
        styles.tile,
        !!funding_offers_count && styles.fundingOffered,
        has_accepted_invitation && styles.hasAcceptedFunding,
      )}
    >
      <div className={styles.container}>
        <div>
          <ProjectThumbnail
            image={getHeaderImage(written_pitch?.image, video_pitch?.video_url_1)}
            video={!!video_pitch}
            status={status}
          />
        </div>
        <div className={styles.projectInfoContainer}>
          <h2 className={styles.projectName}>{name}</h2>
          <PreviewInfo
            status={status}
            matchesCount={matches_count}
            updateCount={update_count}
            fundingOffered={!!funding_offers_count}
            hasAcceptedInvitation={has_accepted_invitation}
          />
        </div>
        <div className={styles.CTA}>
          {status === ProjectStatusEnum.DRAFT ? (
            <Button buttonType="secondary" to={Routes.PROJECT_EDIT(id)} className={styles.button}>
              {t('management.edit')}
            </Button>
          ) : (
            <Button
              buttonType="secondary"
              to={Routes.PROJECT_MANAGEMENT(id)}
              className={styles.button}
            >
              {t('management.manage')}
            </Button>
          )}
        </div>
      </div>
    </Tile>
  );
};
