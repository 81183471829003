import React, { useEffect, useCallback, useRef, useMemo, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { FundDetailsPage } from '@Components';
import { PathParams } from '@App/paths';
import { useRouter } from '@Helpers/useRouter';
import * as topLoader from '@Helpers/topLoader';
import {
  useCategories,
  useGetFundData,
  useGetFundProjectUpdates,
  useGetFundedProjects,
  useGetLocalAuthorityWithFeature,
  useHideProjectUpdate,
  useGetGroupEligibilityForFund,
} from '@Hooks/Api';
import { useNotificationsContext, useUserContext } from '@Contexts';
import { DrawingMode, Fund, FundStatusEnum, LatLngExpression, UserTypeEnum } from '@Types';
import router from 'next/router';
import { useGetCurrentTab } from '@Hooks/useGetCurrentTab';

export const UK_CENTER_COORDINATES: LatLngExpression = [55.2, -2.9];
export const UK_WIDE_RADIUS = 400;

interface FundDetailsPageControllerProps {
  fundData: Fund;
}

export const FundDetailsPageController = ({ fundData }: FundDetailsPageControllerProps) => {
  const { params } = useRouter();
  const fundId = Number(params[PathParams.FUND_ID]);
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const { userData } = useUserContext();
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const fundedProjectsRef = useRef<HTMLHeadingElement | null>(null);
  const fundUpdatesRef = useRef<HTMLHeadingElement | null>(null);
  const [getFundData, { data }] = useGetFundData();
  const [hideUpdate, { loading: isHideUpdatesLoading }] = useHideProjectUpdate();
  const [getCategories, { data: categoriesList, loading: isCategoriesListLoading }] =
    useCategories(true);
  const [getFundProjectUpdates, { paginatedData: updatesResponse }] = useGetFundProjectUpdates();
  const { totalCount: fundProjectUpdatesTotalCount, loading: isUpdatesListLoading } =
    updatesResponse;
  const [getFundedProjects, { paginatedData: projectsResponse }] = useGetFundedProjects();
  const {
    totalCount: totalProjectsCount,
    loading: isFundedProjectsLoading,
    // We don't use the next/previous page func as we only ever show 10 on this page.
  } = projectsResponse;

  const [getLocalAuthority, { data: localAuthority }] = useGetLocalAuthorityWithFeature();
  const { t } = useTranslation('fund');

  const [
    getGroupEligibilityForFund,
    { data: groupEligibilityForFundData, loading: groupEligibilityForFundDataLoading },
  ] = useGetGroupEligibilityForFund();

  useEffect(() => {
    if (userData?.user_type == UserTypeEnum.GROUP) {
      getGroupEligibilityForFund(fundId);
    }
  }, [fundId, getGroupEligibilityForFund, userData?.user_type]);

  useEffect(() => {
    if (userData?.user_type) {
      topLoader.start();
      getFundData(fundId).finally(() => {
        topLoader.done();
      });
    }
  }, [getFundData, fundId, userData?.user_type]);

  useEffect(() => {
    getFundProjectUpdates(fundId);
  }, [fundId, getFundProjectUpdates]);

  useEffect(() => {
    getFundedProjects({ fundId });
  }, [fundId, getFundedProjects]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (data && data.details.local_authority) {
      getLocalAuthority(data.details.local_authority);
    } else if (fundData && fundData.details.local_authority) {
      getLocalAuthority(fundData.details.local_authority);
    }
  }, [data, fundData, getLocalAuthority]);

  const handleScroll = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top + window.scrollY - 100;

      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  const hideProjectUpdate = useCallback(
    (projectUpdateId: number) =>
      hideUpdate(projectUpdateId)
        .then(() => {
          successNotification(t('projectUpdate:hide_update_success'));
          getFundProjectUpdates(fundId);
        })
        .catch(() => {
          errorNotification(t('projectUpdate:hide_update_fail'));
        }),
    [errorNotification, fundId, getFundProjectUpdates, hideUpdate, successNotification, t],
  );

  const fundDataToDisplay = data || fundData;

  const {
    id,
    details: {
      name,
      starting_amount,
      maximum_grant_amount,
      minimum_grant_amount,
      purpose,
      group_types_to_support,
      project_delivery_deadline,
      postcode,
      radius,
      location,
      launch_from,
      deadline_to,
      categories,
      promotion_hashtag,
      catchment_area_type,
      areas,
      video_pitch_required,
      selection_deadline,
    },
    funding_profile: {
      name: organizationName,
      type,
      avatar_image,
      website_url,
      facebook_url,
      linkedin_url,
      instagram_url,
      twitter_url,
      organization_id,
    },
    status,
    user_can_manage,
    group_has_project_matched,
  } = fundDataToDisplay;

  const tabs = useMemo(
    () =>
      [FundStatusEnum.IN_DELIVERY, FundStatusEnum.CLOSED, FundStatusEnum.COMPLETE].includes(status)
        ? [
            { title: t('details_page.tabs.about'), slug: 'about', ref: aboutRef },
            {
              title: t('details_page.tabs.funded_projects', {
                projectsCount: isFundedProjectsLoading ? '…' : totalProjectsCount,
              }),
              disabled: isFundedProjectsLoading || totalProjectsCount === 0,
              slug: 'funded-projects',
              ref: fundedProjectsRef,
            },
            {
              title: t('details_page.tabs.updates', {
                updatesCount:
                  isFundedProjectsLoading || isUpdatesListLoading
                    ? '…'
                    : fundProjectUpdatesTotalCount,
              }),
              disabled: isUpdatesListLoading || fundProjectUpdatesTotalCount === 0,
              slug: 'updates',
              ref: fundUpdatesRef,
            },
          ]
        : undefined,
    [
      fundProjectUpdatesTotalCount,
      totalProjectsCount,
      isFundedProjectsLoading,
      isUpdatesListLoading,
      status,
      t,
    ],
  );

  // Set tab based on router and update modal state
  let currentTab = useGetCurrentTab(params.fundSlug?.[0], tabs);

  const setTab = useCallback(
    (tabNumber: number) => {
      if (tabs) {
        handleScroll(tabs[tabNumber].ref);
        return router.push(`/fund/${fundId}/${tabs?.[tabNumber].slug}`, undefined, {
          shallow: true,
        });
      }
    },
    [fundId, tabs],
  );

  const radiusValue = catchment_area_type === DrawingMode.UK_WIDE ? UK_WIDE_RADIUS : radius;
  const coordinatesValue: LatLngExpression =
    catchment_area_type === DrawingMode.UK_WIDE
      ? UK_CENTER_COORDINATES
      : [location.coordinates[1], location.coordinates[0]];

  return (
    <FundDetailsPage
      fund
      fundId={id}
      title={name}
      status={t(`fund_status.${status}`)}
      totalAmount={starting_amount}
      maximumGrantAmount={maximum_grant_amount}
      minimumGrantAmount={minimum_grant_amount}
      catchment_area_type={catchment_area_type}
      areas={areas ? areas : undefined}
      purpose={purpose}
      groupTypes={group_types_to_support}
      postcode={postcode}
      radius={radiusValue}
      coordinates={coordinatesValue}
      launchFrom={launch_from}
      deadlineTo={deadline_to}
      deliveryDeadline={
        project_delivery_deadline && format(parseISO(project_delivery_deadline), 'dd/MM/yyyy')
      }
      organizationName={organizationName}
      organizationType={t(`common:funding_types.${type}`)}
      categoriesIds={categories}
      userCanManage={user_can_manage}
      avatarImage={avatar_image}
      orgWebsiteUrl={website_url}
      orgFacebookUrl={facebook_url}
      orgLinkedinUrl={linkedin_url}
      orgInstagramUrl={instagram_url}
      orgTwitterUrl={twitter_url}
      organizationId={organization_id}
      hideProjectUpdate={user_can_manage ? hideProjectUpdate : undefined}
      isHideUpdatesLoading={isHideUpdatesLoading}
      categoriesList={categoriesList}
      isCategoriesListLoading={isCategoriesListLoading}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={setTab}
      aboutRef={aboutRef}
      fundedProjectsRef={fundedProjectsRef}
      fundUpdatesRef={fundUpdatesRef}
      hashtag={promotion_hashtag}
      video_pitch_required={video_pitch_required}
      localAuthority={localAuthority}
      updatesPaginatedResponse={updatesResponse}
      projectsPaginatedResponse={projectsResponse}
      groupEligibilityForFundData={groupEligibilityForFundData}
      groupEligibilityForFundDataLoading={groupEligibilityForFundDataLoading}
      groupHasProjectMatched={group_has_project_matched}
      selection_deadline={selection_deadline}
    />
  );
};
