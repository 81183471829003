import { ProjectStatusEnum } from '@Types';
import { useTranslation } from 'react-i18next';
import styles from './PreviewInfo.module.scss';

import { RiShieldCheckFill, RiMessage2Fill, RiCircleFill } from 'react-icons/ri';
import classnames from 'classnames';

export interface PreviewInfoProps {
  status: ProjectStatusEnum;
  matchesCount?: number;
  fundingOffered?: boolean;
  updateCount?: number;
  hasAcceptedInvitation?: boolean;
}

export const PreviewInfo = ({
  status,
  matchesCount,
  fundingOffered,
  updateCount,
  hasAcceptedInvitation,
}: PreviewInfoProps) => {
  const { t } = useTranslation('project');
  let infoMessage;
  let icon;
  if (status === ProjectStatusEnum.PUBLISHED) {
    if (fundingOffered) {
      icon = <RiCircleFill />;
      infoMessage = t('preview.info.funding_offered');
    } else if (hasAcceptedInvitation) {
      icon = <RiCircleFill />;
      infoMessage = t('preview.info.funding_invitation_accepted');
    } else {
      icon = <RiShieldCheckFill />;
      infoMessage = t('preview.info.submitted_to_fund', { count: matchesCount || 0 });
    }
  } else if (status === ProjectStatusEnum.FUNDED || status === ProjectStatusEnum.COMPLETE) {
    icon = <RiMessage2Fill />;
    if (updateCount && updateCount > 2) {
      infoMessage = t('preview.info.updates', { count: updateCount || 0 });
    } else {
      infoMessage = t('preview.info.updates_needed', { count: updateCount || 0 });
    }
  }
  return (
    <div
      className={classnames(
        styles.container,
        fundingOffered && styles.fundingOffered,
        hasAcceptedInvitation && styles.hasAcceptedInvitation,
      )}
    >
      {icon}
      {infoMessage}
    </div>
  );
};
