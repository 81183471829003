import React from 'react';
import LockRoundedIcon from '@material-ui/icons/LockRounded';

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { CategoriesListController, AddressSection, FieldNote, SectionHeader } from '@Components';
import { TextInput } from '@Components/common';
import { msgFieldInvalid, msgFieldRequired } from '@Helpers/errorMessages';
import styles from './ProjectDetails.module.scss';

const GRANT_SIZE_LIMIT = 50000;

interface ProjectDetailsProps {
  isLoading: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => Promise<void>;
  buttons: JSX.Element;
}

export const ProjectDetails: React.FC<ProjectDetailsProps> = ({ isLoading, onSubmit, buttons }) => {
  const { t } = useTranslation('project');
  const { register, errors: fieldErrors } = useFormContext();

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <FieldNote className={styles.userNote} icon={<LockRoundedIcon style={{ fontSize: 12 }} />}>
        {t('delivery.info')}
      </FieldNote>
      <SectionHeader
        title={t('details.project_name_header')}
        subtitle={t('details.project_name_description')}
      />
      <TextInput
        label={t('details.project_name_header')}
        name="name"
        inputRef={register({ required: msgFieldRequired })}
        error={!!fieldErrors.name}
        helperText={fieldErrors.name?.message}
        disabled={isLoading}
      />

      <SectionHeader
        title={t('details.funding_header')}
        subtitle={t('details.funding_header_description')}
      />
      <TextInput
        type="number"
        label={t('details.project_grant_size')}
        name="amount"
        inputRef={register({
          required: msgFieldRequired,
          max: {
            value: GRANT_SIZE_LIMIT,
            message: t('details.grant_size_limit_error', {
              amount: GRANT_SIZE_LIMIT,
            }),
          },
          pattern: {
            value: /^0*[1-9]\d*(.00)?$/,
            message: t('common:whole_number_error'),
          },
        })}
        error={!!fieldErrors.amount}
        helperText={fieldErrors.amount?.message}
        disabled={isLoading}
      />

      <SectionHeader title={t('details.location')} subtitle={t('details.location_desc')} />
      <AddressSection isLoading={isLoading} />
      {/* is_sensitive temporarily disabled */}
      {/* <Controller
        name="is_sensitive"
        control={control}
        defaultValue={false}
        render={({ ref, onChange, value }) => (
          <Checkbox
            inputRef={ref}
            value={ref.current.checked}
            checked={Boolean(value)}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
            error={!!fieldErrors.is_sensitive}
            helperText={fieldErrors.is_sensitive?.message}
            disabled={isLoading}
          >
            <span className={styles.checkboxContent}>
              {t('details.private_location_checkbox')}
              <Tooltip title={t('details.checkbox_tooltip') as string}>
                <HelpIcon style={{ color: styleVariables.colorT3 }} />
              </Tooltip>
            </span>
          </Checkbox>
        )}
      /> */}

      <SectionHeader title={t('details.focus_header')} subtitle={t('details.focus_description')} />
      <CategoriesListController name="categories" required parentOnly disabled={isLoading} />
      {buttons}
    </form>
  );
};
