import { DrawingMode, LocalAuthority } from '@Types';
import { MultiPolygon } from 'geojson';

export enum ProjectApplicationFormat {
  VIDEO_AND_WRITTEN_PITCHES = 'VIDEO_AND_WRITTEN_PITCHES',
  VIDEO_ONLY_PITCHES = 'VIDEO_ONLY_PITCHES',
}

export interface CriteriaData {
  name: string | null;
  drawingMode: DrawingMode;
  areas: MultiPolygon | null;
  maximum_grant_amount: string | null;
  minimum_grant_amount: string | null;
  postcode: string | null;
  radius: number | null;
  starting_amount: string | null;
  focusAreas: number[];
  nonProfitTypes: string[];
  localAuthority: number | null;
  beneficiaryTypesPeople: string[];
}

export enum BeneficiaryPeopleTypesEnum {
  REFUGEES = 'refugees',
  DISCRIMINATION = 'discrimination',
  HOMELESS = 'homeless',
  LGBTQIA = 'lgbtqia+',
  OLDER_PEOPLE = 'older_people',
  YOUNG_PEOPLE = 'young_people',
  DISADVANTAGED = 'disadvantaged',
  DISABILITIES = 'disabilities',
  WOMEN = 'women',
  MEN = 'men',
  EX_OFFENDERS = 'ex_offenders',
  VETERANS = 'veterans',
  OTHER = 'other',
}
