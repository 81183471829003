import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@Components';
import * as Sentry from '@sentry/react';
import {
  useCompleteProject,
  useGetProject,
  useGetProjectMatches,
  useGetProjectSelections,
  useProjectRejectsInvitation,
  useRejectMatch,
} from '@Hooks/Api';
import { useRouter } from '@Helpers/useRouter';
import { PathParams, Routes } from '@App/paths';
import { Fund, SelectionStatusEnum } from '@Types';
import { useNotificationsContext, useUserContext } from '@Contexts';
import { FundWithSelectionData, ProjectManagement } from './ProjectManagement';
import router from 'next/router';
import { useGetCurrentTab } from '@Hooks/useGetCurrentTab';

const INVITATION_STATUSES = [
  SelectionStatusEnum.INVITED,
  SelectionStatusEnum.INVITATION_ACCEPTED,
  SelectionStatusEnum.PAYMENT_FAILED,
  SelectionStatusEnum.FUNDED,
];

export const ProjectManagementController = () => {
  // Layout
  const [isRejectFundingModalOpen, setIsRejectFundingModalOpen] = useState(false);
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const { t } = useTranslation('project');

  // Initial data loading
  const { userData } = useUserContext();
  const { push, params } = useRouter();
  const projectId = Number(params[PathParams.PROJECT_ID]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [getProjectData, { data: projectData, loading: isProjectDataLoading }] = useGetProject();
  const [getProjectMatches, { paginatedData: matchesPaginatedResponse }] = useGetProjectMatches();
  const {
    page: projectMatchesData,
    loading: isProjectMatchesLoading,
    totalCount: totalMatchesCount,
  } = matchesPaginatedResponse;
  const [
    getProjectSelections,
    { loading: isProjectSelectionsLoading, data: projectSelectionsData },
  ] = useGetProjectSelections();

  // On accept or reject interactions
  const [selectionId, setSelectionId] = useState<number | undefined>(undefined);
  const [fundDetails, setFundDetails] = useState<Fund | null>(null);
  const [projectRejectsInvitation] = useProjectRejectsInvitation();
  const [isEditProjectModalVisible, setIsEditProjectModalVisible] = useState(false);
  const [isCompleteProjectModalVisible, setIsCompleteProjectModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [completeProject, { loading: isProjectCompleteLoading }] = useCompleteProject();
  const [rejectMatch] = useRejectMatch();

  const handleEditModalShow = useCallback(() => {
    setIsEditProjectModalVisible(true);
  }, []);

  const handleEditModalHide = useCallback(() => {
    setIsEditProjectModalVisible(false);
  }, []);

  const handleEditModalConfirm = useCallback(() => {
    setIsEditProjectModalVisible(false);
    push(Routes.PROJECT_EDIT(projectId));
  }, [setIsEditProjectModalVisible, push, projectId]);

  const setProjectComplete = useCallback(
    (projectId: number) =>
      completeProject(projectId)
        .then(() => {
          getProjectData(projectId);
          setIsSuccessModalVisible(true);
        })
        .catch((e: any) => {
          Sentry.captureException(
            new Error(
              `Changing project status to complete failed. Error: ${
                e?.data?.detail ? e.data.detail.join(' ') : 'Unknown error'
              }`,
            ),
          );
          errorNotification(t('dashboard:group.complete_project_fail'));
        }),
    [completeProject, errorNotification, getProjectData, t],
  );

  const handleCompleteModalShow = useCallback(() => {
    setIsCompleteProjectModalVisible(true);
  }, []);

  const handleCompleteModalHide = useCallback(() => {
    setIsCompleteProjectModalVisible(false);
  }, []);

  const handleCompleteModalConfirm = useCallback(() => {
    setIsCompleteProjectModalVisible(false);
    setProjectComplete(projectId);
  }, [setProjectComplete, projectId]);

  // Set tab based on router and update modal state
  useEffect(() => {
    const projectManageSlugParams = params[PathParams.PROJECT_MANAGE_SLUG];
    let selectionId: number | undefined = undefined;

    if (projectManageSlugParams && projectManageSlugParams.length > 1) {
      selectionId = Number(projectManageSlugParams[1]);
      setSelectionId(selectionId);
    }

    if (projectManageSlugParams?.length === 3 && projectManageSlugParams[0] === 'funding-offers') {
      projectSelectionsData.find((selection) => {
        if (selection.id === selectionId) {
          setFundDetails(selection.fund);
        }
      });
    }
    if (projectManageSlugParams?.length === 3 && selectionId !== undefined) {
      setIsRejectFundingModalOpen(projectManageSlugParams[2] == 'reject');
    } else {
      setIsRejectFundingModalOpen(false);
    }
    projectSelectionsData.find((selection) => {
      if (selection.status === 'FUNDED') {
        setSelectionId(selection.id);
      }
    });
  }, [params, projectSelectionsData]);

  useEffect(() => {
    setIsInitialized(true);
    getProjectData(projectId);
  }, [getProjectData, projectId]);

  useEffect(() => {
    getProjectMatches(projectId);
  }, [projectId, getProjectMatches]);

  useEffect(() => {
    getProjectSelections(projectId);
  }, [projectId, getProjectSelections]);

  const rejectInvitationToFund = useCallback(
    (selectionId: number, fundName: string) =>
      projectRejectsInvitation(projectId, selectionId)
        .then(() => {
          successNotification(t('management.reject_invite_to_fund_success', { fundName }));
          getProjectSelections(projectId);
        })
        .catch(() => {
          errorNotification(t('management.reject_invite_to_fund_fail', { fundName }));
        }),
    [
      errorNotification,
      getProjectSelections,
      projectId,
      projectRejectsInvitation,
      successNotification,
      t,
    ],
  );

  const rejectMatchedFund = useCallback(
    (matchId: number, fundName: string) =>
      rejectMatch(matchId)
        .then(() => {
          successNotification(t('management.reject_match_success', { fundName }));
          getProjectMatches(projectId);
        })
        .catch((err) => {
          errorNotification(
            err?.data?.detail[0] || t('management.reject_match_fail', { fundName }),
          );
        }),
    [errorNotification, projectId, getProjectMatches, rejectMatch, successNotification, t],
  );

  const invitationsList =
    projectSelectionsData &&
    projectSelectionsData.reduce((acc: FundWithSelectionData[], curr) => {
      if (INVITATION_STATUSES.find((invStatus) => invStatus === curr.status)) {
        return [...acc, { selectionId: curr.id, selectionStatus: curr.status, ...curr.fund }];
      }
      return acc;
    }, []);

  const tabs = useMemo(
    () => [
      {
        title: t('management.tabs.applications', {
          matchCount: isProjectMatchesLoading ? '…' : totalMatchesCount,
        }),
        slug: 'applications',
      },
      {
        title: t('management.tabs.invitations', {
          invitationsCount: isProjectSelectionsLoading ? '…' : invitationsList?.length,
        }),
        slug: 'funding-offers',
      },
    ],
    [
      invitationsList?.length,
      isProjectMatchesLoading,
      isProjectSelectionsLoading,
      totalMatchesCount,
      t,
    ],
  );

  // Set tab based on router and update modal state
  let currentTab = useGetCurrentTab(params.projectManageSlug?.[0], tabs);

  const setTab = useCallback(
    (tabNumber: number) => {
      return router.push(`/project/${projectId}/manage/${tabs[tabNumber].slug}`, undefined, {
        shallow: true,
      });
    },
    [projectId, tabs],
  );

  if (isProjectDataLoading || !isInitialized) return <Loader />;

  return (
    <ProjectManagement
      fundId={fundDetails?.id}
      fundName={fundDetails?.details.name}
      projectData={projectData}
      projectId={projectId}
      projectName={projectData?.details.name}
      status={projectData?.status}
      currentTab={currentTab}
      setCurrentTab={setTab}
      tabs={tabs}
      isListsLoading={isProjectMatchesLoading || isProjectSelectionsLoading}
      invitationsList={invitationsList}
      rejectInvitationToFund={rejectInvitationToFund}
      isRejectFundingModalOpen={isRejectFundingModalOpen}
      isProfileComplete={userData?.progress === 100}
      selectionId={selectionId}
      rejectMatch={rejectMatchedFund}
      updateProjectData={getProjectData}
      handleEditModalShow={handleEditModalShow}
      handleEditModalHide={handleEditModalHide}
      handleEditModalConfirm={handleEditModalConfirm}
      isEditProjectModalVisible={isEditProjectModalVisible}
      handleCompleteModalShow={handleCompleteModalShow}
      handleCompleteModalHide={handleCompleteModalHide}
      handleCompleteModalConfirm={handleCompleteModalConfirm}
      isCompleteProjectModalVisible={isCompleteProjectModalVisible}
      matchesPaginatedResponse={matchesPaginatedResponse}
      isSuccessModalVisible={isSuccessModalVisible}
      setIsSuccessModalVisible={setIsSuccessModalVisible}
    />
  );
};
