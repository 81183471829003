import { useCallback, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FundingProfileData, FundingTypes } from '@Types';
import { useSetFieldErrors } from '@Hooks';
import { useUpdateFunderProfile } from '@Hooks/Api';
import { Loader } from '@Components';
import { useNotificationsContext } from '@Contexts';
import { useRouter } from '@Helpers';
import isServer from '@Helpers/isServer';
import { RoutingPaths } from '@App/paths';

import { useFormData } from './useFormData';
import { GroupProfile } from '../GroupProfile';
import { useProfileTypes } from '../useProfileTypes';

const TYPES = Object.keys(FundingTypes);

export const FunderProfileController = ({ forceNoProfile = false, organizationName = '' }) => {
  const { t } = useTranslation();
  const methods = useForm<FundingProfileData>();
  const { setError, reset } = methods;
  const [{ data: dataFunder, loading: loadingFunder, isError: noFunder }] = useFormData(
    forceNoProfile,
    organizationName,
  );
  const [updateFunderProfile, response] = useUpdateFunderProfile(noFunder);
  const { error, loading: isSaving } = response;
  const { push } = useRouter();
  const typeList = useProfileTypes('funding_types', TYPES);
  const { error: errorNotification } = useNotificationsContext();

  useEffect(() => {
    if (!loadingFunder && dataFunder) {
      reset(dataFunder);
    }
  }, [reset, loadingFunder, dataFunder]);

  const onSubmit = useCallback(
    (data: FundingProfileData) => {
      updateFunderProfile(data)
        .then(() => {
          if (noFunder && !isServer && window.dataLayer) {
            window.dataLayer.push({ event: 'funderCreateProfile' });
          }
        })
        .catch(({ data: errData }) => {
          if (errData.detail[0]) {
            errorNotification(errData.detail[0]);
          }
        });
    },
    [errorNotification, noFunder, updateFunderProfile],
  );

  const onCancel = useCallback(() => {
    push(RoutingPaths.DASHBOARD_FUNDER);
  }, [push]);

  useSetFieldErrors({
    fieldErrors: error?.field_errors,
    setError,
    loading: loadingFunder,
  });

  if (!forceNoProfile && (loadingFunder || !dataFunder)) return <Loader />;

  return (
    <FormProvider {...methods}>
      <GroupProfile
        isFunder={true}
        onSubmit={methods.handleSubmit(onSubmit)}
        onCancel={onCancel}
        isSaving={isSaving}
        typeList={typeList}
        createMode={noFunder}
        headerDisclaimer={t('createAccount:funder_profile.header_disclaimer')}
        focusAreaDisclaimer={t('createAccount:funder_profile.focus_areas_disclaimer')}
      />
    </FormProvider>
  );
};
