import { useUserContext } from '@Contexts';
import { FunderProfileController } from './FunderProfileController';

export const CreateFunderProfileController = () => {
  const { userData, isUserLoading } = useUserContext();

  if (isUserLoading) {
    return null;
  }
  return (
    <FunderProfileController forceNoProfile organizationName={userData?.organization_name ?? ''} />
  );
};
