export const getBeneficiaryPeopleCategories = (
  t: any,
): {
  label: string;
  value: string;
  hintText?: string;
}[] => {
  return [
    { label: t('people.refugees'), value: 'refugees' },
    {
      label: t('people.discrimination'),
      value: 'discrimination',
      hintText: t('people.discrimination_hint'),
    },
    { label: t('people.homeless'), value: 'homeless' },
    { label: t('people.lgbtqia+'), value: 'lgbtqia+' },
    { label: t('people.older_people'), value: 'older_people' },
    { label: t('people.young_people'), value: 'young_people' },
    {
      label: t('people.disadvantaged'),
      value: 'disadvantaged',
      hintText: t('people.disadvantaged_hint'),
    },
    {
      label: t('people.disabilities'),
      value: 'disabilities',
    },
    { label: t('people.women'), value: 'women' },
    { label: t('people.men'), value: 'men' },
    { label: t('people.ex_offenders'), value: 'ex_offenders' },
    { label: t('people.veterans'), value: 'veterans' },
    {
      label: t('people.other'),
      value: 'other',
      hintText: t('people.other_hint'),
    },
  ];
};
