import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { Organization } from 'Types';

export const useOrganizationLookup = () => {
  const [fetch, response] = useRequest<Organization[]>();

  const organizationLookup = useCallback(
    (organizationName: string) => {
      return fetch(API.paths.organization_lookup, 'post', {
        data: { organization_name: organizationName },
      });
    },
    [fetch],
  );

  return {
    organizationLookup,
    response,
  } as const;
};
