import { Button, Tile } from '@Components/common';
import { useTranslation } from 'react-i18next';
import styles from './FundPreviewCTACard.module.scss';
import { RiSeoLine } from 'react-icons/ri';
import { Routes } from '@App/paths';

export const FundPreviewCTACard = () => {
  const { t } = useTranslation('fund');

  return (
    <Tile className={styles.container}>
      <>
        <RiSeoLine className={styles.icon} size={36} />
        <div className={styles.textContainer}>
          <h3 className={styles.title}>{t('preview.CTA.title')}</h3>
          <p className={styles.subtitle}>{t('preview.CTA.subtitle')}</p>
        </div>
        <Button buttonType="primary" to={Routes.FUND_CREATE} className={styles.cta}>
          {t('preview.CTA.get_started')}
        </Button>
      </>
    </Tile>
  );
};
