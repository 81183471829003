export const FeaturedMarker = () => {
  return (
    <svg width="450" height="600" viewBox="0 0 450 600" xmlns="http://www.w3.org/2000/svg">
      <filter
        id="filter1"
        x="0"
        y="0"
        width="450"
        height="600"
        filterUnits="userSpaceOnUse"
        primitiveUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feGaussianBlur stdDeviation="11" />
        <feOffset dx="17.67767" dy="17.67767" result="offsetblur" />
        <feFlood floodColor="#646464" floodOpacity="0.5" />
        <feComposite in2="offsetblur" operator="in" />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <path
        id="Path"
        fill="#0a04a9"
        stroke="#ffffff"
        strokeWidth="10"
        filter="url(#filter1)"
        d="M 23 258.311005 C 23 132.285492 118.993996 51 225.133011 51 C 331.272003 51 427.266022 132.285492 427.266022 258.311005 C 427.266022 298.186005 411.898987 342.031006 380.569 389.987 C 349.249023 437.927002 302.15799 489.695007 239.186996 545.312012 C 231.233002 552.206055 219.289993 552.206055 211.337006 545.310974 C 148.235992 489.692993 101.080101 437.925018 69.728104 389.984985 C 38.366898 342.030029 23 298.186005 23 258.311005 Z M 168.350006 253.132996 C 168.350006 284.375 193.890991 309.916016 225.133011 309.916016 C 256.375 309.916016 281.915985 284.375 281.915985 253.132996 C 281.915985 221.890991 256.375 196.350006 225.133011 196.350006 C 193.890991 196.350006 168.350006 221.890991 168.350006 253.132996 Z"
      />
    </svg>
  );
};
