import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { FunderSurveyReportSummaryData } from '@Types';
import { useCallback } from 'react';

export const useGetFunderReportSummaryData = () => {
  const [fetch, response] = useRequest<FunderSurveyReportSummaryData>();

  const getFunderReportSummaryData = useCallback(
    (organizationId: number) => fetch(API.paths.funder_report_summary_data(organizationId), 'get'),

    [fetch],
  );

  return [getFunderReportSummaryData, response] as const;
};
