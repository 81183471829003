import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { CreateActionButtons, Loader } from '@Components';
import { RoutingPaths } from '@App/paths';
import { useRouter } from '@Helpers';
import isServer from '@Helpers/isServer';
import { DeliveryPlan } from '@Views/Project/DeliveryPlan/DeliveryPlan';
import { ProjectStatusEnum, ProjectDelivery } from '@Types';
import { useUpdateProject, useGetProject, usePublishProject } from '@Hooks/Api';
import { useSetFieldErrors } from '@Hooks';
import { useNotificationsContext } from '@Contexts';

interface ProjectDetailsProps {
  currentStep: number;
  stepsCount: number;
  prevStep: () => void;
  projectId?: number;
  grantAmount?: number;
  isProjectPublished?: boolean;
  isProjectInitiallyPublished?: boolean;
  isDeliveryCreated?: boolean;
  setProjectData: (
    id: number,
    grantAmount: number,
    isProjectEditable?: boolean,
    isProjectPublished?: boolean,
    isDeliveryCreated?: boolean,
  ) => void;
  isEdit?: boolean;
  setDeliveryPlanFormData: (formData: any) => void;
  deliveryPlanFormData?: ProjectDelivery;
}

export const DeliveryPlanController: React.FC<ProjectDetailsProps> = ({
  currentStep,
  stepsCount,
  prevStep,
  projectId,
  grantAmount,
  isProjectPublished,
  isProjectInitiallyPublished,
  isDeliveryCreated,
  setProjectData,
  isEdit = false,
  setDeliveryPlanFormData,
  deliveryPlanFormData,
}) => {
  const { t } = useTranslation('project');
  const { push } = useRouter();
  const methods = useForm();
  const { handleSubmit, reset, setError, getValues, formState } = methods;
  const [updateProject, { loading: isUpdateProjectLoading, error: updateProjectError }] =
    useUpdateProject();
  const [getProject, { loading: isGetProjectLoading, data: projectData }] = useGetProject();
  const [publishProject, { loading: isPublishProjectLoading }] = usePublishProject();
  const { error: errorNotification, success: successNotification } = useNotificationsContext();
  const isFormSaving = isUpdateProjectLoading || isPublishProjectLoading;
  const [hasVolunteeringOpportunity, setHasVolunteeringOpportunity] = useState(false);

  useEffect(() => {
    if (deliveryPlanFormData) {
      reset(deliveryPlanFormData);
    } else if (projectId && isDeliveryCreated) {
      getProject(projectId).then(({ data }) => {
        reset(data);
      });
    }
  }, [deliveryPlanFormData, getProject, isDeliveryCreated, projectId, reset]);

  useEffect(() => {
    /*
     * Re-fetch project details.
     * Project status could be updated in the background when submitting the previous steps.
     *
     */
    if (projectId) {
      getProject(projectId)
        .then(({ data }) => {
          setProjectData(
            data.id,
            Number(data.details.amount),
            data.user_can_edit,
            data.status !== ProjectStatusEnum.DRAFT,
          );
        })
        .catch(() => {
          /**
           * This function is used to fetch form data.
           * This catch prevents throwing an unhandled error and sending it to sentry,
           * if there is nothing to fetch (404)
           */
        });
    }
  }, [getProject, projectId, setProjectData]);

  const saveProjectDeliveryPlan = useCallback(
    (data: ProjectDelivery) => {
      let isFieldPairError = false;
      const sanitizedBudgetBreakdown = data.delivery_plan.budget_breakdown.filter((item) => {
        if ((!item.amount && item.cost_description) || (item.amount && !item.cost_description)) {
          errorNotification(t('delivery.entries_must_be_pairs'));
          isFieldPairError = true;
        }
        return item.amount && item.cost_description;
      });
      if (isFieldPairError) return;

      const budgetBreakdownSum = sanitizedBudgetBreakdown.reduce(
        (acc, { amount }) => Number(acc) + Number(amount),
        0,
      );

      if (budgetBreakdownSum !== grantAmount) {
        errorNotification(t('delivery.sum_error'));
        return;
      }

      if (projectData) {
        projectData.delivery_plan = {
          ...data.delivery_plan,
          budget_breakdown: sanitizedBudgetBreakdown,
        };
        if (hasVolunteeringOpportunity) {
          projectData.volunteering_opportunity = { ...data.volunteering_opportunity };
        } else {
          projectData.volunteering_opportunity = null;
        }
        return updateProject(projectData);
      }
    },
    [grantAmount, projectData, errorNotification, t, hasVolunteeringOpportunity, updateProject],
  );

  const onSubmit = useCallback(
    (data: ProjectDelivery) => {
      saveProjectDeliveryPlan(data)?.then(() => {
        if (projectId) {
          publishProject(projectId).then(() => {
            if (!isServer && window.dataLayer) {
              window.dataLayer.push({ event: 'publishProject' });
            }
            successNotification(t('published'));
            push(RoutingPaths.DASHBOARD_GROUP);
          });
        }
      });
    },
    [saveProjectDeliveryPlan, push, publishProject, successNotification, projectId, t],
  );

  const onSubmitDraft = useCallback(
    (data: ProjectDelivery) => {
      saveProjectDeliveryPlan(data)?.then(() => {
        if (!isProjectPublished) {
          successNotification(t('saved_draft'));
        } else {
          successNotification(t('published'));
        }
        push(RoutingPaths.DASHBOARD_GROUP);
      });
    },
    [push, saveProjectDeliveryPlan, isProjectPublished, successNotification, t],
  );

  useSetFieldErrors({
    fieldErrors: updateProjectError?.field_errors,
    setError,
    loading: isFormSaving,
  });

  const submit = handleSubmit(onSubmit);
  const submitDraft = handleSubmit(onSubmitDraft);

  // Allows us to store the data when going back to a previous stage
  // this gets around needing validation for saving OR losing data due to component unmount
  const storeDeliveryPlanFormData = () => {
    const formData = getValues();
    if (formData) {
      setDeliveryPlanFormData(formData);
    }
  };

  if (isGetProjectLoading) return <Loader />;

  return (
    <FormProvider {...methods}>
      <DeliveryPlan
        isLoading={isFormSaving}
        onSubmit={submit}
        grantAmount={grantAmount}
        budgetBreakdownElementsCount={projectData?.delivery_plan?.budget_breakdown.length}
        volunteeringOpportunity={Boolean(projectData?.volunteering_opportunity)}
        setHasVolunteeringOpportunity={setHasVolunteeringOpportunity}
        hasVolunteeringOpportunity={hasVolunteeringOpportunity}
        buttons={
          <CreateActionButtons
            currentStep={currentStep}
            stepsCount={stepsCount}
            backButtonProps={{
              buttonType: 'text',
              onClick: () => {
                storeDeliveryPlanFormData();
                prevStep();
              },
              disabled: isFormSaving,
            }}
            draftButtonProps={
              isProjectPublished
                ? undefined
                : {
                    buttonType: 'tertiary',
                    onClick: submitDraft,
                    disabled: isFormSaving,
                  }
            }
            nextButtonProps={{
              buttonType: 'primary',
              onClick: isProjectPublished ? submitDraft : submit,
              disabled: isFormSaving,
              children: t(
                isProjectPublished
                  ? 'delivery.save'
                  : isEdit && isProjectInitiallyPublished
                  ? 'delivery.republish_project'
                  : 'delivery.publish_project',
              ),
            }}
            projectId={projectId}
          />
        }
      />
    </FormProvider>
  );
};
