import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { FundPreviewData, FundPreviewDataParams } from '@Types';
import { useCallback } from 'react';

export const useGetFundPreviewData = () => {
  const [fetch, response] = useRequest<FundPreviewData>();

  const getFundPreviewData = useCallback(
    (data: FundPreviewDataParams) =>
      fetch(API.paths.fund_preview_data, 'post', {
        data,
      }),
    [fetch],
  );

  return [getFundPreviewData, response] as const;
};
