import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@Components/common';
import { ContentModal } from '@Components/ContentModal/ContentModal';

import styles from './SavePlannedFundModal.module.scss';

interface SavePlannedFundModalProps {
  isOpen: boolean;
  onClose: () => void;
  onExit: () => void;
}

export const SavePlannedFundModal = ({ isOpen, onClose, onExit }: SavePlannedFundModalProps) => {
  const { t } = useTranslation('socialValue');

  return (
    <ContentModal isOpen={isOpen} onClose={onClose}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('savedSearchModal.header')}</h2>
      </div>
      <div className={styles.content}>
        <p className={styles.subtitle}>{t('savedSearchModal.subtitle')}</p>
      </div>
      <div className={styles.footer}>
        <Button buttonType="tertiary" onClick={onExit}>
          {t('savedSearchModal.exit')}
        </Button>
        <Button buttonType="tertiary" onClick={onClose}>
          {t('savedSearchModal.edit_again')}
        </Button>
      </div>
    </ContentModal>
  );
};
