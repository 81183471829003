import { useUserContext } from '@Contexts';
import { UserTypeEnum } from 'Types';
import { RoutingPaths } from '@App/paths';
import { useRouter } from '@Helpers/useRouter';

export const DashboardController = () => {
  const { userData } = useUserContext();
  const { push } = useRouter();

  switch (userData?.user_type) {
    case UserTypeEnum.FUNDER:
      push(RoutingPaths.DASHBOARD_FUNDER);
      return null;
    case UserTypeEnum.GROUP:
      push(RoutingPaths.DASHBOARD_GROUP);
      return null;
    default:
      console.warn(`User type not found: ${userData?.user_type}`);
      push(RoutingPaths.SIGN_IN);
      return null;
  }
};
