import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { useTranslation, Trans } from 'react-i18next';
import * as Sentry from '@sentry/nextjs';

import { Link } from '@Components/common';
import { useRouter } from '@Helpers';
import * as authToken from '@Helpers/authToken';
import { useVerifyEmail, useResendVerificationEmail } from '@Hooks/Api';
import { RoutingPaths } from '@App/paths';
import { useNotificationsContext, useUserContext } from '@Contexts';

import styleVariables from '@Styles/_variables.module.scss';

const useStyles = makeStyles({
  backdrop: {
    // @ts-ignore
    zIndex: styleVariables.zIndexVerifyEmailOverlay,
    color: styleVariables.colorT2Paler,
    flexDirection: 'column',
  },
});

enum VerifyState {
  SUCCESS,
  LOADING,
  ERROR_NOT_FOUND,
  ERROR_EXPIRED,
  ERROR_UNKNOWN,
}

export const VerifyEmailController = () => {
  const classes = useStyles();
  const { t } = useTranslation('createAccount');
  const [verifyState, setVerifyState] = useState(VerifyState.LOADING);
  const {
    params: { verifyKey },
    push,
  } = useRouter();
  const { data, error, isError, loading } = useVerifyEmail(verifyKey as string);
  const [resendVerificationEmail, { loading: isVerificationEmailResending }] =
    useResendVerificationEmail(verifyKey as string);
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const { fetchUserData } = useUserContext();

  const onResendVerificationEmail = () => {
    resendVerificationEmail()
      .then(() => {
        successNotification(t('verify_email.resend_email_success'));
        push(RoutingPaths.CONFIRM_EMAIL);
      })
      .catch(() => {
        errorNotification(t('verify_email.resend_email_fail'));
      });
  };

  useEffect(() => {
    if (data) {
      // TODO: Do we need to care about data.details === 'ok'?
      setVerifyState(VerifyState.SUCCESS);
    } else if (isError && !loading) {
      switch (error?.code) {
        case 'NOT_FOUND':
          setVerifyState(VerifyState.ERROR_NOT_FOUND);
          break;
        case 'PERMISSION_DENIED':
          setVerifyState(VerifyState.ERROR_EXPIRED);
          break;
        default:
          setVerifyState(VerifyState.ERROR_UNKNOWN);
      }
    }
  }, [isError, data, error, loading]);

  switch (verifyState) {
    case VerifyState.LOADING:
      return (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color="inherit" />
          <p>{t('verify_email.loading')}</p>
        </Backdrop>
      );
    case VerifyState.SUCCESS:
      if (!authToken.get() && data?.key) {
        authToken.set(data.key);
        fetchUserData().then(() => {
          push(RoutingPaths.DASHBOARD);
        });
      } else {
        push(RoutingPaths.DASHBOARD);
      }
      return null;
    case VerifyState.ERROR_EXPIRED:
      return (
        <span>
          <Trans
            ns="createAccount"
            i18nKey="verify_email.verify_key_expired"
            components={[
              <Link
                disabled={isVerificationEmailResending}
                onClick={onResendVerificationEmail}
                primary
              />,
            ]}
            values={{ verifyKey }}
          />
        </span>
      );
    case VerifyState.ERROR_NOT_FOUND:
      return (
        <span>
          <Trans
            ns="createAccount"
            i18nKey="verify_email.verify_key_not_found"
            components={[
              <Link
                disabled={isVerificationEmailResending}
                onClick={onResendVerificationEmail}
                primary
              />,
            ]}
            values={{ verifyKey }}
          />
        </span>
      );
    default:
      Sentry.captureException(
        new Error(
          `Email verification failed. Error: ${
            error?.detail ? error.detail.join(' ') : 'Unknown error'
          }`,
        ),
      );
      return (
        <span>
          <Trans
            ns="createAccount"
            i18nKey="verify_email.unknown_error"
            components={[
              <Link
                disabled={isVerificationEmailResending}
                onClick={onResendVerificationEmail}
                href="mailto:hello@actionfunder.org"
                primary
              />,
            ]}
          />
        </span>
      );
  }
};
