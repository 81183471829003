import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { formatDate } from '@Helpers/formatDate';
import {
  RiPencilFill,
  RiEyeFill,
  RiMessage2Fill,
  RiCheckboxCircleFill,
  RiArrowLeftLine,
  RiCalendarScheduleFill,
  RiBarChartGroupedFill,
  RiAlertFill,
} from 'react-icons/ri';

import {
  StatusChip,
  OldTabs,
  Loader,
  ManagementTile,
  ConfirmModal,
  OldTabProps,
  CreateProjectUpdateModalController,
  ContentModal,
} from '@Components';
import { Fund, Match, Project, ProjectStatusEnum, SelectionStatusEnum } from '@Types';
import { useRouter } from '@Helpers/useRouter';
import styles from './ProjectManagement.module.scss';
import { Grid } from '@material-ui/core';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';
import { Button, Link, Pagination, Tile } from '@Components/common';
import { useGetProjectFundingSource, useGetSurveyPreviewConfig } from '@Hooks/Api';
import { HelpCenterRoutes, Routes } from '@App/paths';
import { Action } from './Components/Action';
import { openUrl } from '@Helpers';
import { AxiosResponse } from 'axios';
import { ArchiveProjectButton } from '@Components/common/Button/ArchiveProjectButton';
import { ProjectStatus } from '@Components/Projects/Components/ProjectStatus';
import { getHelpCenterURL } from '@Helpers/helpCenter';
import { useUserContext } from '@Contexts';
import { getDeadlineDateInformationBreakdown } from '@Helpers/getDeadlineDateInformationBreakdown';
import classnames from 'classnames';
import { SuccessModal } from '@Components/SuccessModal/SuccessModal';
import { Survey } from '@Components/Surveys/Survey/Survey';
import { camelCaseObject } from '@Helpers/case';

export interface FundWithSelectionData extends Fund {
  selectionId: number;
  selectionStatus: SelectionStatusEnum;
}

interface ProjectManagementProps {
  fundId?: number;
  fundName?: string;
  projectId: number;
  projectName?: string;
  projectData?: Project;
  status?: string;
  currentTab: number;
  setCurrentTab: (tabIndex: number) => void;
  tabs: OldTabProps[];
  isListsLoading?: boolean;
  invitationsList?: FundWithSelectionData[];
  rejectInvitationToFund: (selectionId: number, fundName: string) => Promise<void>;
  rejectMatch: (matchId: number, fundName: string) => Promise<void>;
  isRejectFundingModalOpen: boolean;
  isProfileComplete?: boolean;
  selectionId?: number;
  updateProjectData?: (projectId: number) => Promise<AxiosResponse<Project>>;
  handleEditModalConfirm: () => void;
  handleEditModalShow: () => void;
  handleEditModalHide: () => void;
  isEditProjectModalVisible: boolean;
  handleCompleteModalShow: () => void;
  handleCompleteModalHide: () => void;
  handleCompleteModalConfirm: () => void;
  isCompleteProjectModalVisible: boolean;
  matchesPaginatedResponse: PaginatedResponse<Match>;
  setIsSuccessModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccessModalVisible: boolean;
}

export const ProjectManagement: React.FC<ProjectManagementProps> = ({
  fundId,
  fundName,
  projectId,
  projectName,
  projectData,
  status,
  currentTab,
  setCurrentTab,
  tabs,
  isListsLoading,
  invitationsList,
  rejectInvitationToFund,
  rejectMatch,
  isRejectFundingModalOpen,
  isProfileComplete,
  selectionId,
  updateProjectData,
  handleEditModalConfirm,
  handleEditModalShow,
  handleEditModalHide,
  isEditProjectModalVisible,
  handleCompleteModalShow,
  handleCompleteModalHide,
  handleCompleteModalConfirm,
  isCompleteProjectModalVisible,
  matchesPaginatedResponse,
  setIsSuccessModalVisible,
  isSuccessModalVisible,
}) => {
  const { t } = useTranslation('project');
  const { push } = useRouter();
  const [getProjectFundingSource, { data: projectFundingSource, loading: isFundingSourceLoading }] =
    useGetProjectFundingSource();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const { totalCount: totalMatchesCount, page: matchesList } = matchesPaginatedResponse;

  const userCanManage = projectData?.user_can_manage;
  const userCanEdit = projectData?.user_can_edit;
  const userCanUpdate = projectData?.user_can_manage_updates || false;
  const userCanArchive = projectData?.user_can_archive;
  const surveyUrl = projectData?.impact_survey_response?.url;
  const updateCount = projectData?.update_count || 0;
  const hasAcceptedFunding = !!projectFundingSource;
  const completedImpactSurvey = projectData?.impact_survey_response?.status === 'COMPLETED';
  const estimatedDeliveryDate = projectData?.delivery_plan?.estimated_delivery_date;
  const [isSurveyPreviewModalVisible, setIsSurveyPreviewModalVisible] = useState(false);
  const { userData, isUserLoading } = useUserContext();
  const [
    getSurveyPreviewConfig,
    { data: surveyPreviewConfig, loading: isSurveyPreviewConfigLoading },
  ] = useGetSurveyPreviewConfig();

  const onClose = () => {
    push(`/project/${projectId}/manage/funding-offers`, undefined, { shallow: true });
  };

  useEffect(() => {
    getProjectFundingSource(projectId);
  }, [getProjectFundingSource, projectId]);

  const createHandleAcceptClick = useCallback(
    (selectionId: number) => () => {
      push(`/project/${projectId}/manage/funding-offers/${selectionId}/accept`, undefined, {
        shallow: true,
      });
    },
    [projectId, push],
  );

  const createHandleRejectClick = useCallback(
    (selectionId: number) => () => {
      push(`/project/${projectId}/manage/funding-offers/${selectionId}/reject`, undefined, {
        shallow: true,
      });
    },
    [projectId, push],
  );

  const handleRejectInvitationToFund = useCallback(() => {
    if (selectionId && fundName && fundId) {
      rejectInvitationToFund(selectionId, fundName).finally(() => {
        push(`/project/${projectId}/manage/funding-offers`, undefined, { shallow: true });
      });
    }
  }, [rejectInvitationToFund, projectId, push, selectionId, fundName, fundId]);

  const handleEditClick = () => {
    const confirmationNeeded =
      status === ProjectStatusEnum.PUBLISHED && (projectData?.matches_count || 0) > 0;
    if (confirmationNeeded) {
      handleEditModalShow();
    } else {
      handleEditModalConfirm();
    }
  };

  const openUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const handleOpenSurvey = () => {
    if (surveyUrl) {
      openUrl(surveyUrl);
    }
  };

  const handleOpenPreviewSurvey = () => {
    if (selectionId) {
      getSurveyPreviewConfig(selectionId);
      setIsSurveyPreviewModalVisible(true);
    }
  };
  const handleViewImpact = () => {
    // This will be the code to send the NP to the impact page
  };

  const { isBeforeDeadline, distanceToDeadline, numberOfDaysBeforeDeadline } =
    getDeadlineDateInformationBreakdown(estimatedDeliveryDate);
  const updateAction = {
    title: t('management.actions.post_update.title'),
    text:
      updateCount < 2 ? (
        <div className={classnames(styles.actionSubtitle, styles.warning)}>
          <RiAlertFill className={styles.warningIcon} />
          <p className={styles.actionSubtitleText}>
            <Trans
              ns="project"
              i18nKey="management.actions.update_required.text"
              components={[<b />]}
              values={{ update_count: updateCount, remaining_count: 2 - updateCount }}
            />
          </p>
        </div>
      ) : (
        <Trans
          ns="project"
          i18nKey="management.actions.post_update.text"
          components={[<b />]}
          values={{ update_count: updateCount }}
        />
      ),
    showButton: userCanUpdate,
    buttonText: t('management.actions.post_update.button'),
    buttonIcon: <RiMessage2Fill />,
    onClick: openUpdateModal,
    complete: updateCount >= 2 && completedImpactSurvey,
    icon: <RiMessage2Fill size={24} />,
  };

  const completeAction = {
    title: t('management.actions.mark_complete.title'),
    text: (
      <>
        {status !== ProjectStatusEnum.COMPLETE ? (
          estimatedDeliveryDate ? (
            <>
              {isBeforeDeadline ? (
                <>
                  {numberOfDaysBeforeDeadline && numberOfDaysBeforeDeadline > 6 ? (
                    <Trans
                      ns="project"
                      i18nKey="management.actions.mark_complete.text_date"
                      components={[<b />]}
                      values={{ date: formatDate(estimatedDeliveryDate) }}
                    />
                  ) : (
                    <div className={styles.actionSubtitle}>
                      <p className={styles.actionSubtitleText}>
                        <Trans
                          ns="project"
                          i18nKey="management.actions.mark_complete.text_distance"
                          components={[<b className={styles.warning} />]}
                          values={{
                            date_distance: distanceToDeadline,
                            date: formatDate(estimatedDeliveryDate),
                          }}
                        />
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <Trans
                  ns="project"
                  i18nKey="management.actions.mark_complete.text_overdue"
                  components={[<b className={styles.overdueText} />]}
                  values={{ date: formatDate(estimatedDeliveryDate) }}
                />
              )}
            </>
          ) : (
            t('management.actions.mark_complete.text_backup')
          )
        ) : (
          t('management.actions.mark_complete.text_complete')
        )}
      </>
    ),

    showButton: status === ProjectStatusEnum.FUNDED,
    buttonText: t('management.actions.mark_complete.button'),
    buttonIcon: <RiCheckboxCircleFill />,
    onClick: handleCompleteModalShow,
    complete: status === ProjectStatusEnum.COMPLETE,
    icon: <RiCalendarScheduleFill size={24} />,
  };

  const surveyAction = {
    title: t('management.actions.survey.title'),
    text: (
      <>
        {!completedImpactSurvey ? (
          <div className={classnames(styles.warning, styles.actionSubtitle)}>
            <RiAlertFill className={styles.warningIcon} />
            {t('management.actions.survey.text')}
          </div>
        ) : (
          <>{t('management.actions.survey.text_complete')}</>
        )}
      </>
    ),
    showButton: !!surveyUrl,
    buttonText: t('management.actions.survey.button'),
    buttonIcon: <RiBarChartGroupedFill />,
    onClick: handleOpenSurvey,
    complete: completedImpactSurvey,
    icon: <RiBarChartGroupedFill size={24} />,
  };

  const previewImpact = {
    title: t('management.impact.previewImpact.title'),
    text: t('management.impact.previewImpact.text'),
    showButton: true,
    buttonText: t('management.impact.previewImpact.button'),
    buttonIcon: <RiBarChartGroupedFill />,
    onClick: handleOpenPreviewSurvey,
    complete: false,
    icon: <RiBarChartGroupedFill size={24} />,
  };

  const viewImpact = {
    title: t('management.impact.viewImpact.title'),
    text: t('management.impact.viewImpact.text'),
    showButton: true,
    buttonText: t('management.impact.viewImpact.button'),
    buttonIcon: <RiBarChartGroupedFill />,
    onClick: handleViewImpact,
    complete: false,
    icon: <RiBarChartGroupedFill size={24} />,
  };

  const actions = [];

  if (status === ProjectStatusEnum.FUNDED) {
    if (updateCount < 2) {
      actions.push(updateAction, completeAction);
    } else {
      actions.push(completeAction, updateAction);
    }
  } else if (status === ProjectStatusEnum.COMPLETE) {
    completeAction.complete = true;
    if (updateCount < 2) {
      actions.push(updateAction, surveyAction, completeAction);
    } else {
      actions.push(surveyAction, updateAction, completeAction);
    }
  }

  const impact = [];
  if (status === ProjectStatusEnum.COMPLETE) {
    if (completedImpactSurvey) {
      impact.push(viewImpact);
    } else {
      impact.push(surveyAction);
    }
  } else {
    impact.push(previewImpact);
  }

  const getOfferTile = ({
    id,
    details,
    funding_profile,
    selectionId,
    selectionStatus,
  }: FundWithSelectionData) => {
    const showButtons = userCanManage && selectionStatus === SelectionStatusEnum.INVITED;
    return (
      <ManagementTile
        key={id}
        fundId={id}
        fundName={details.name}
        organizationName={funding_profile.name}
        fundingOffered={t(`common:currency_format`, {
          amount: projectData?.details.amount,
        })}
        fundDeadline={details.deadline_to}
        withFundLink
        negativeBtn={showButtons}
        onNegativeBtnClick={createHandleRejectClick(selectionId)}
        positiveBtn={showButtons}
        positiveBtnDisabled={!isProfileComplete}
        positiveBtnText={t('management.accept')}
        positiveBtnEndIcon={<CheckCircleIcon />}
        onPositiveBtnClick={createHandleAcceptClick(selectionId)}
        isActionLoading={false}
        withStatusChip={!showButtons}
        selectionStatus={selectionStatus}
        toolTipText={!isProfileComplete ? t(`management.warning_tooltip`) : ''}
      />
    );
  };

  return (
    <div>
      <SuccessModal
        image={
          <img
            src="/assets/SuccessModal/ProjectCompleteGraphic.svg"
            alt="Project Complete graphic"
          />
        }
        isOpen={isSuccessModalVisible}
        primaryButton={
          <Button buttonType="primary" onClick={() => setIsSuccessModalVisible(false)}>
            {t('management.completed.continue_button')}
          </Button>
        }
        title={t('management.completed.title')}
        bodyCopy={t('management.completed.congratulations_copy')}
        onClose={() => setIsSuccessModalVisible(false)}
      />
      <ConfirmModal
        isOpen={isEditProjectModalVisible}
        onClose={handleEditModalHide}
        title={t('management.edit_modal.title')}
        cancelText={t('management.edit_modal.cancel')}
        onCancel={handleEditModalHide}
        confirmText={t('management.edit_modal.confirm')}
        onConfirm={handleEditModalConfirm}
      >
        {t('management.edit_modal.text')}
      </ConfirmModal>

      {hasAcceptedFunding ? (
        <>
          <Link to={Routes.MY_PROJECTS} underline className={styles.returnLink}>
            <RiArrowLeftLine />
            {t('management.return_to_projects')}
          </Link>
          <div className={styles.headerContainer}>
            <h1>{projectName}</h1>
            <Button buttonType="secondary" to={Routes.PROJECT_DETAILS(projectId.toString())}>
              {t('management.view_project')}
            </Button>
          </div>
          {!isFundingSourceLoading && projectFundingSource && status && (
            <ProjectStatus
              status={status}
              fundedBy={{ name: projectFundingSource.details.name, id: projectFundingSource.id }}
            />
          )}

          <Tile className={styles.actionsContainer}>
            <>
              <h3 className={styles.actionsTileTitle}>{t('management.actions.title')}</h3>
              <p className={styles.actionsTileSubTitle}>{t('management.actions.subtitle')}</p>
              {actions &&
                actions.map(
                  (
                    { title, text, buttonText, onClick, buttonIcon, complete, showButton, icon },
                    i,
                  ) => (
                    <Action
                      title={title}
                      text={text}
                      buttonText={buttonText}
                      onClick={onClick}
                      buttonIcon={buttonIcon}
                      showButton={showButton}
                      complete={complete}
                      key={i}
                      icon={icon}
                    />
                  ),
                )}
              {projectData && (
                <CreateProjectUpdateModalController
                  isOpen={isUpdateModalOpen}
                  onClose={closeUpdateModal}
                  onOpen={openUpdateModal}
                  project={projectData}
                  updateProjectUpdates={updateProjectData}
                />
              )}
              <ConfirmModal
                isOpen={isCompleteProjectModalVisible}
                onClose={handleCompleteModalHide}
                title={t('management.complete_modal.title')}
                cancelText={t('management.complete_modal.cancel')}
                onCancel={handleCompleteModalHide}
                confirmText={t('management.complete_modal.confirm')}
                onConfirm={handleCompleteModalConfirm}
              >
                <Trans
                  ns="project"
                  i18nKey="management.complete_modal.text"
                  components={[<br />]}
                />
              </ConfirmModal>
            </>
          </Tile>
          {/* Hiding if not completed impact survey, once NP impact page is build this can be removed. */}
          {!completedImpactSurvey && (
            <Tile className={styles.actionsContainer}>
              <>
                <h3 className={styles.actionsTitle}>{t('management.impact.title')}</h3>
                <p className={styles.actionsSubTitle}>
                  <Trans
                    ns="project"
                    i18nKey="management.impact.subtitle"
                    components={[
                      <Link
                        href={
                          userData
                            ? getHelpCenterURL({
                                path: HelpCenterRoutes.POST_FUNDING_REQUIREMENTS,
                                user: userData,
                              })
                            : ''
                        }
                        underline
                      />,
                      <Link href="mailto:hello@actionfunder.org" underline />,
                    ]}
                  />
                </p>

                {impact &&
                  impact.map(
                    (
                      { title, text, buttonText, onClick, buttonIcon, complete, showButton, icon },
                      i,
                    ) => (
                      <Action
                        title={title}
                        text={text}
                        buttonText={buttonText}
                        onClick={onClick}
                        buttonIcon={buttonIcon}
                        showButton={showButton}
                        complete={complete}
                        key={i}
                        icon={icon}
                      />
                    ),
                  )}
                <ContentModal
                  isOpen={isSurveyPreviewModalVisible}
                  onClose={() => setIsSurveyPreviewModalVisible(false)}
                  wide
                >
                  {surveyPreviewConfig && !isSurveyPreviewConfigLoading ? (
                    <Survey
                      surveyConfig={camelCaseObject(surveyPreviewConfig) as any}
                      isSaving={false}
                      onSubmit={() => {}}
                      preview
                    />
                  ) : (
                    <Loader />
                  )}
                </ContentModal>
              </>
            </Tile>
          )}
        </>
      ) : (
        <>
          <div className={styles.headerWrapper}>
            <div>
              <h1>{projectName}</h1>
              {status && (
                <StatusChip label={t(`dashboard:group.project_status.${status}`)} color="primary" />
              )}
            </div>
            <div className={styles.managementButtons}>
              {userCanEdit && (
                <Button
                  buttonType="tertiary"
                  onClick={handleEditClick}
                  startIcon={<RiPencilFill />}
                >
                  {t('management.edit')}
                </Button>
              )}
              <Button
                buttonType="tertiary"
                to={Routes.PROJECT_DETAILS(projectId.toString())}
                startIcon={<RiEyeFill />}
              >
                {t('management.view')}
              </Button>
              {userCanArchive && <ArchiveProjectButton id={projectId} />}
            </div>
          </div>
          <OldTabs tabs={tabs} currentTab={currentTab} onTabChange={setCurrentTab} />
          <div className={styles.itemsContainer}>
            {currentTab === 0 &&
              (isListsLoading ? (
                <Loader />
              ) : (
                <>
                  {totalMatchesCount === 0 && (
                    <b>{t('management.matches_count', { count: totalMatchesCount || 0 })}</b>
                  )}
                  {matchesList && (
                    <>
                      {matchesList.map((match) => (
                        <ManagementTile
                          key={match.id}
                          fundId={match.fund.id}
                          fundName={match.fund.details.name}
                          organizationName={match.fund.funding_profile.name}
                          fundAmount={t(`common:currency_format`, {
                            amount: match.fund.details.starting_amount,
                          })}
                          fundDeadline={match.fund.details.deadline_to}
                          withFundLink
                          negativeBtn={userCanManage}
                          onNegativeBtnClick={() => rejectMatch(match.id, match.fund.details.name)}
                          confirmNegativeAction
                          confirmNegativeActionContent={
                            <Trans
                              ns="project"
                              i18nKey="management.confirm_reject_match"
                              components={[<b />]}
                              values={{ fundName: match.fund.details.name }}
                            />
                          }
                        />
                      ))}
                      <Pagination paginatedResponse={matchesPaginatedResponse} />
                    </>
                  )}
                </>
              ))}
            {currentTab === 1 &&
              (isListsLoading ? (
                <Loader />
              ) : (
                <>
                  {!isProfileComplete && (
                    <div className={styles.infoBox}>
                      <Grid container alignItems="center" justifyContent="center">
                        <ErrorOutlineIcon style={{ fontSize: 64 }} />
                        <Grid item xs={12} md={8} lg={6}>
                          <Trans
                            ns="project"
                            i18nKey="management.warning_message"
                            components={{ ahref: <a /> }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {invitationsList?.length === 0 && (
                    <b>{t('management.offers_count', { count: invitationsList?.length || 0 })}</b>
                  )}
                  {invitationsList && invitationsList.map(getOfferTile)}
                  <ConfirmModal
                    isOpen={isRejectFundingModalOpen}
                    onClose={onClose}
                    onConfirm={handleRejectInvitationToFund}
                    isConfirmBtnDanger
                  >
                    <Trans
                      ns="project"
                      i18nKey="management.confirm_reject_invite_to_fund"
                      components={[<b />]}
                      values={{ fundName: fundName }}
                    />
                  </ConfirmModal>
                </>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
