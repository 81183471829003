export interface FormatMailtoProps {
  emailAddress: string;
  subject?: string;
  body?: string;
  cc?: string;
}

export const formatMailto = ({ emailAddress, subject, body, cc }: FormatMailtoProps) => {
  // Format a mailto: link, to open a pre-defined email in an email editor when a link is clicked.
  const url = new URL(`mailto:${emailAddress}`);
  if (subject) {
    url.searchParams.append('subject', subject);
  }
  if (body) {
    url.searchParams.append('body', body);
  }
  if (cc) {
    url.searchParams.append('cc', cc);
  }
  return url.toString();
};
