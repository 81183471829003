import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorOption } from 'react-hook-form';

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';

import { createMenuItems, ContentModal } from '@Components';
import { Button, DatePicker, SelectInput, TextArea } from '@Components/common';
import { useSetFieldErrors } from '@Hooks';
import styles from './VotingConfigurationModal.module.scss';
import { FundVotingConfiguration } from '@Types';
import { ApiErrorObject } from 'Types/Errors';

export interface VotingConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FundVotingConfiguration) => void;
  votingConfiguration?: FundVotingConfiguration;
  votingConfigurationUpdateErrors?: ApiErrorObject;
  votingConfigurationUpdateLoading: boolean;
}

export const VotingConfigurationModal = ({
  isOpen,
  onClose,
  onSubmit,
  votingConfiguration,
  votingConfigurationUpdateErrors,
  votingConfigurationUpdateLoading,
}: VotingConfigurationModalProps) => {
  const { t } = useTranslation('fund');
  const defaultDescription = `${t('voting_configuration.page_intro_1')}

${t('voting_configuration.page_intro_2')}

${t('voting_configuration.page_intro_3')}`;
  const methods = useForm({
    defaultValues: {
      deadline: votingConfiguration?.deadline,
      maximum_votes_per_user: votingConfiguration?.maximum_votes_per_user,
      description: votingConfiguration?.description || defaultDescription,
    },
  });
  const { control, errors: fieldErrors, handleSubmit, setError } = methods;

  const wrappedSubmit = handleSubmit(onSubmit);

  useSetFieldErrors({
    fieldErrors: votingConfigurationUpdateErrors?.field_errors,
    // react-hook-form gives setError a restrictive type for the available field names for this one form,
    // so we need to generalise it to name: string here.
    setError: setError as (name: string, error: ErrorOption) => void,
    loading: votingConfigurationUpdateLoading,
  });

  // The backend can support any value, but for simplicity
  // we just provide a few standard values.
  const votesPerUserChoices = [
    {
      label: '1',
      menuItemProps: {
        value: '1',
      },
    },
    {
      label: '3',
      menuItemProps: {
        value: '3',
      },
    },
    {
      label: '5',
      menuItemProps: {
        value: '5',
      },
    },
    {
      label: 'No limit',
      menuItemProps: {
        value: '',
      },
    },
  ];

  return (
    <ContentModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('voting_configuration.sharing_link_heading')}
    >
      {votingConfiguration ? (
        <>
          <div className={styles.introText}>{t('voting_configuration.voting_set_up')}</div>
        </>
      ) : (
        <>
          <div className={styles.introText}>{t('voting_configuration.intro_text_1')}</div>
          <div className={styles.introText}>{t('voting_configuration.intro_text_2')}</div>
        </>
      )}
      <div className={styles.introText}>{t('voting_configuration.update_intro_text')}</div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={wrappedSubmit}>
          <div className={styles.fieldWrapper}>
            <FormControl error={!!fieldErrors.maximum_votes_per_user}>
              <InputLabel>{t('voting_configuration.maximum_votes_per_user')}</InputLabel>
              <Controller
                name="maximum_votes_per_user"
                control={control}
                defaultValue=""
                render={({ ref, onChange, value }) => (
                  <SelectInput
                    name="maximum_votes_per_user"
                    label={t('voting_configuration.maximum_votes_per_user')}
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    error={!!fieldErrors.maximum_votes_per_user}
                  >
                    {createMenuItems({ items: votesPerUserChoices })}
                  </SelectInput>
                )}
              />
              <FormHelperText>{fieldErrors.maximum_votes_per_user?.message}</FormHelperText>
            </FormControl>
          </div>
          <div className={styles.fieldWrapper}>
            <DatePicker
              name="deadline"
              required={true}
              label={t('voting_configuration.deadline')}
              error={!!fieldErrors.deadline}
              helperText={fieldErrors.deadline?.message}
            />
          </div>
          <div className={styles.fieldWrapper}>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ ref, onChange, value }) => (
                <TextArea
                  name="description"
                  required={true}
                  value={value}
                  inputRef={ref}
                  onChange={onChange}
                  label={t('voting_configuration.description')}
                  error={!!fieldErrors.description}
                  helperText={fieldErrors.description?.message}
                  minRows={10}
                />
              )}
            />
          </div>
          <Button buttonType="tertiary" onClick={wrappedSubmit}>
            {votingConfiguration ? (
              <>{t('voting_configuration.submit_update')}</>
            ) : (
              <>{t('voting_configuration.submit_create')}</>
            )}
          </Button>
        </form>
      </FormProvider>
    </ContentModal>
  );
};
