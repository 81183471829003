import { RiPlayCircleFill, RiImageFill } from 'react-icons/ri';
import styles from './ProjectThumbnail.module.scss';
import { ProjectStatus } from './ProjectStatus';

interface ProjectThumbnailProps {
  image?: string;
  video?: boolean;
  status: string;
}

export const ProjectThumbnail = ({ image, video, status }: ProjectThumbnailProps) => {
  return (
    <div className={styles.container}>
      {image ? (
        <div className={styles.imageWrapper}>
          <img src={image} alt="" className={styles.image} />
          {video && <RiPlayCircleFill className={styles.videoWatermark} />}
        </div>
      ) : (
        <div className={styles.imagePlaceholder}>
          <RiImageFill style={{ fontSize: '42px' }} />
        </div>
      )}
      <div className={styles.statusFlag}>
        <ProjectStatus status={status} small />
      </div>
    </div>
  );
};
