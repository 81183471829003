import { MouseEventHandler } from 'react';
import classnames from 'classnames';
import styles from './AFIcons.module.scss';

export interface AFLeftIconProps {
  className?: string;
  onClick?: MouseEventHandler;
}

export const AFLeftIcon = ({ className, onClick }: AFLeftIconProps) => {
  return (
    <svg
      className={classnames(className, styles.afIcon)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        d="M55.0208 27.7346L45.845 36.9104L27.4976 36.9061L36.6734 46.0819L27.519 55.2363L0.000191708 27.7175L27.5019 0.215764L36.6691 9.383L27.4933 18.5588L45.845 18.5588L55.0208 27.7346Z"
        fill="currentColor"
      />
    </svg>
  );
};
