import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateActionWrapper,
  FundDetailsController,
  Loader,
  FinaliseController,
} from '@Components';
import { useRouter, scrollToTop } from '@Helpers';
import { PathParams } from '@App/paths';
import styles from './FundForm.module.scss';
import { FundCriteriaController } from '@Components/FundCriteria/FundCriteriaController';
import { useGetFundData } from '@Hooks/Api';

export const FundForm = () => {
  const { t } = useTranslation('fund');
  const { params } = useRouter();
  const [currentStep, setCurrentStep] = useState(0);
  const [fundId, setFundId] = useState<number | null>(Number(params[PathParams.FUND_ID]) || null);
  const [isFundConfirmed] = useState(false);
  const [getFundData, { loading: isFundDataLoading, data: fundData }] = useGetFundData();
  const [fundDetailsData, setFundDetailsData] = useState();

  useEffect(() => {
    if (fundId) {
      getFundData(fundId);
    }
    // We don't need to re-fetch the data we've already got when setFundId is called,
    // and we don't want to cause components further down the line to unmount due to showing the Loader,
    // so don't include fundId in the dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFundData]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    scrollToTop();
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
    scrollToTop();
    if (fundId) {
      getFundData(fundId);
    }
  };

  const isCreated = !params[PathParams.FUND_ID];

  const hasMatches = fundData?.matches_count === undefined || fundData?.matches_count === 0;

  let steps = [];
  let pageHeading = [];
  let content = [];

  // If we are creating or editing a fund without options we want to add these in
  if (hasMatches) {
    steps.push({ label: t('steps.criteria') });
    pageHeading.push({ header: undefined, subheader: undefined });
    content.push(
      <FundCriteriaController
        fundData={fundData}
        fundDataLoading={isFundDataLoading}
        nextStep={nextStep}
        setFundId={setFundId}
      />,
    );
  }

  steps.push(...[{ label: t('steps.details') }, { label: t('steps.finalise') }]);

  pageHeading.push(
    ...[
      { header: t('details.header'), subheader: t('details.subheader') },
      { header: t('process.header') },
    ],
  );

  content.push(
    ...[
      <FundDetailsController
        currentStep={currentStep}
        stepsCount={steps.length}
        nextStep={nextStep}
        prevStep={prevStep}
        fundId={fundId}
        fundDetailsData={fundDetailsData}
        setFundDetailsData={setFundDetailsData}
      />,
      <FinaliseController
        currentStep={currentStep}
        stepsCount={steps.length}
        prevStep={prevStep}
        fundId={fundId}
        isFundConfirmed={isFundConfirmed}
      />,
    ],
  );

  return (
    <CreateActionWrapper
      steps={steps}
      currentStep={currentStep}
      {...pageHeading[currentStep]}
      childrenWrapperClassName={
        (isCreated || !isFundConfirmed) && currentStep === 0 ? styles.contentWrapper : ''
      }
    >
      {content[currentStep]}
    </CreateActionWrapper>
  );
};
