import React from 'react';
import styles from './UpsellCard.module.scss';
import { Grid } from '@material-ui/core';
import { Button, Tile } from '@Components/common';
import { useTranslation } from 'react-i18next';
import { RoutingPaths } from '@App/paths';

export const UpsellCard = () => {
  const { t } = useTranslation('dashboard');
  return (
    <Tile className={styles.container}>
      <Grid container direction="column">
        <Grid item className={styles.banner}>
          <img src={'/assets/Dashboard/UpsellCard/header.svg'} alt="" />
        </Grid>
        <Grid container direction="column" className={styles.textContainer} spacing={2}>
          <Grid item>
            <h3 className={styles.title}>{t('upsell_card_title')}</h3>
          </Grid>
          <Grid item>{t('upsell_card_text')}</Grid>
          <Grid item className={styles.buttonContainer}>
            <Button buttonType="secondary" to={RoutingPaths.CONTACT}>
              {t('upsell_card_cta')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Tile>
  );
};
