import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { FundVotingConfigurationSubmission } from '@Types';
import { Method } from 'axios';

export const useUpdateFundVotingConfiguration = () => {
  const [fetch, response] = useRequest();

  const updateFundVotingConfiguration = useCallback(
    (fundId: number, data: FundVotingConfigurationSubmission, configurationId?: number) => {
      let path;
      let method: Method;
      if (configurationId != null) {
        path = API.paths.fund_voting_configuration_update(fundId, configurationId);
        method = 'patch';
      } else {
        path = API.paths.fund_voting_configuration_create(fundId);
        method = 'post';
      }
      return fetch(path, method, {
        data,
      });
    },
    [fetch],
  );

  return [updateFundVotingConfiguration, response] as const;
};
