import { useCallback, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { GroupProfileData, GroupTypes } from '@Types';
import { useSetFieldErrors } from '@Hooks';
import { useUpdateGroupProfile } from '@Hooks/Api';
import { Loader } from '@Components';
import { useNotificationsContext } from '@Contexts';
import { useRouter } from '@Helpers';
import isServer from '@Helpers/isServer';
import { RoutingPaths } from '@App/paths';

import { useFormData } from './useFormData';
import { GroupProfile } from '../GroupProfile';
import { useProfileTypes } from '../useProfileTypes';

const TYPES = Object.keys(GroupTypes);

export const GroupProfileController = ({ forceNoProfile = false, organizationName = '' }) => {
  const { t } = useTranslation();
  const methods = useForm<GroupProfileData>();
  const { setError, reset } = methods;
  const [{ data: dataGroup, loading: loadingGroup, isError: noGroup }] = useFormData(
    forceNoProfile,
    organizationName,
  );
  const [updateGroupProfile, response] = useUpdateGroupProfile(noGroup);
  const { error, loading: isSaving } = response;
  const { push } = useRouter();
  const typeList = useProfileTypes('group_types', TYPES);
  const { error: errorNotification } = useNotificationsContext();

  useEffect(() => {
    if (!loadingGroup && dataGroup) {
      reset(dataGroup);
    }
  }, [reset, loadingGroup, dataGroup]);

  const onSubmit = useCallback(
    (data: GroupProfileData) => {
      updateGroupProfile(data)
        .then(() => {
          if (noGroup && !isServer && window.dataLayer) {
            window.dataLayer.push({ event: 'groupCreateProfile' });
          }
        })
        .catch(({ data }) => {
          if (data.detail[0]) {
            errorNotification(data.detail[0]);
          }
        });
    },
    [updateGroupProfile, noGroup, errorNotification],
  );

  const onCancel = useCallback(() => {
    push(RoutingPaths.DASHBOARD_GROUP);
  }, [push]);

  useSetFieldErrors({
    fieldErrors: error?.field_errors,
    setError,
    loading: loadingGroup,
  });

  if (!forceNoProfile && (loadingGroup || !dataGroup)) return <Loader />;

  return (
    <FormProvider {...methods}>
      <GroupProfile
        isFunder={false}
        onSubmit={methods.handleSubmit(onSubmit)}
        onCancel={onCancel}
        isSaving={isSaving}
        typeList={typeList}
        createMode={noGroup}
        headerDisclaimer={t('createAccount:group_profile.header_disclaimer')}
        focusAreaDisclaimer={
          <Trans
            ns="createAccount"
            i18nKey="group_profile.focus_areas_disclaimer"
            components={[<strong />]}
          />
        }
      />
    </FormProvider>
  );
};
