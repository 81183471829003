import React, { ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, Grid, GridSize } from '@material-ui/core';

import {
  CategoriesListController,
  PageTitle,
  SectionHeader,
  AddressSection,
  StatusChip,
} from '@Components';
import { Button, TextInput, CheckboxTile } from '@Components/common';
import { useErrorStateStyles } from '@Components/common/themeOverrides';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { useIsProfileComplete } from '@Helpers/useIsProfileComplete';

import { AdditionalInfoFields } from './AdditionalInfoFields';
import styles from './GroupProfile.module.scss';
import { create } from 'lodash';

const DESCRIPTION_MAX_LENGTH = 1000;

export interface ProfileType {
  value: string;
  label: string;
}

interface GroupProfileProps {
  onSubmit: (e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => Promise<void>;
  onCancel?: () => void;
  isSaving: boolean;
  isFunder: boolean;
  createMode: boolean;
  typeList: ProfileType[];
  headerDisclaimer: string;
  focusAreaDisclaimer: string | ReactElement;
}

// TODO: when GroupProfile and FundProfile diverge, please fork this view
export const GroupProfile: React.FC<GroupProfileProps> = ({
  onSubmit,
  onCancel,
  isSaving,
  isFunder,
  createMode,
  typeList,
  headerDisclaimer,
  focusAreaDisclaimer,
}) => {
  const { t } = useTranslation('createAccount');
  const { register, control, errors: fieldErrors } = useFormContext();
  const classes = useErrorStateStyles();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isProfileComplete } = useIsProfileComplete();

  useEffect(() => {
    if (isProfileComplete && scrollRef?.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isProfileComplete]);

  return (
    <div>
      <form onSubmit={onSubmit} autoComplete="off">
        <div className={styles.contentContainer}>
          <PageTitle
            title={
              createMode
                ? t('create_profile_common.header_create')
                : t('create_profile_common.header_edit')
            }
            subtitle={headerDisclaimer}
          />

          <SectionHeader bigHeader title={t('create_profile_common.key_org_details')} />
          <TextInput
            label={t('create_profile_common.org_name')}
            inputRef={register({ required: msgFieldRequired })}
            name="name"
            error={!!fieldErrors.name}
            helperText={fieldErrors.name?.message}
            disabled={isSaving}
          />

          {!createMode && (
            <TextInput
              label={t('create_profile_common.description')}
              placeholder={t('create_profile_common.description_placeholder')}
              displayCharLimit={DESCRIPTION_MAX_LENGTH}
              inputRef={register({
                maxLength: DESCRIPTION_MAX_LENGTH,
              })}
              inputProps={{
                maxLength: DESCRIPTION_MAX_LENGTH,
              }}
              name="description"
              multiline
              minRows={2}
              maxRows={5}
              error={!!fieldErrors.description}
              helperText={fieldErrors.description?.message}
              disabled={isSaving}
            />
          )}

          <SectionHeader
            title={t('create_profile_common.org_title')}
            subtitle={!isFunder && t('create_profile_common.org_text')}
          />

          <FormControl
            classes={{ root: !!fieldErrors.type ? classes.errorState : classes.nonError }}
            error={!!fieldErrors.type}
            fullWidth
          >
            <Controller
              name="type"
              rules={{ required: msgFieldRequired }}
              control={control}
              defaultValue={''}
              render={({ onChange, value, ref }) => (
                <Grid container spacing={3}>
                  {typeList.map((type) => (
                    <Grid key={type.value} item xs={12} sm={(12 / typeList.length) as GridSize}>
                      <CheckboxTile
                        disabled={isSaving || (!createMode && !isFunder)}
                        checked={value.includes(type.value)}
                        error={!!fieldErrors.type}
                        value={type.value}
                        name="type"
                        label={type.label}
                        onChange={onChange}
                        inputRef={ref}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            />
            {fieldErrors.type?.message && (
              <FormHelperText>{fieldErrors.type?.message}</FormHelperText>
            )}
          </FormControl>

          <SectionHeader
            title={t('create_profile_common.location')}
            subtitle={t('create_profile_common.add_primary_addr')}
          />

          <AddressSection isLoading={isSaving} />

          <SectionHeader
            title={t('create_profile_common.focus_areas')}
            subtitle={focusAreaDisclaimer}
          />

          <CategoriesListController name="categories" disabled={isSaving} required parentOnly />

          {!createMode && (
            <>
              <SectionHeader
                bigHeader
                title={
                  <span>
                    {t('create_profile_common.additional_information_title')}{' '}
                    {createMode && <StatusChip label={t('create_profile_common.optional')} />}
                  </span>
                }
                subtitle={
                  isFunder
                    ? t('funder_profile.additional_information_subtitle')
                    : t('group_profile.additional_information_subtitle')
                }
                className={styles.additionalInfoHeader}
                scrollRef={scrollRef}
              />

              <AdditionalInfoFields isSaving={isSaving} />
            </>
          )}

          <div className={styles.button}>
            {!createMode && (
              <Button onClick={onCancel} buttonType="tertiary">
                {t('common:cancel')}
              </Button>
            )}

            <Button onClick={onSubmit} buttonType="primary" disabled={isSaving}>
              {createMode ? t('common:create_profile') : t('common:save')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
