import React, { ReactElement } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal';

import styles from './SuccessModal.module.scss';
import { IconButton } from '@Components/common/Button/IconButton';
import { Tile } from '@Components/common';
import Confetti from 'react-confetti';

interface SuccessModalProps {
  image: ReactElement;
  isOpen: boolean;
  primaryButton: ReactElement;
  secondaryButton?: ReactElement;
  title: string;
  bodyCopy: string;
  onClose?: () => void;
}

export const SuccessModal = ({
  isOpen,
  onClose,
  title,
  bodyCopy,
  image,
  primaryButton,
  secondaryButton,
}: SuccessModalProps) => {
  return (
    <Modal open={isOpen} className={styles.modal}>
      <>
        <div className={styles.container}>
          {onClose && (
            <IconButton buttonType="text" className={styles.closeBtn} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
          <div className={styles.tileContainer}>
            <Tile className={styles.tile}>
              <>
                {image}
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.bodyCopy}>{bodyCopy}</p>
                <div className={styles.buttonContainer}>
                  {secondaryButton}
                  {primaryButton}
                </div>
              </>
            </Tile>
          </div>
        </div>
        <Confetti recycle={false} numberOfPieces={400} />
      </>
    </Modal>
  );
};
