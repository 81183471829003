import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectDetailsPage } from '@Components';
import { PathParams } from '@App/paths';
import { useRouter } from '@Helpers/useRouter';
import * as topLoader from '@Helpers/topLoader';
import {
  useGetProject,
  useGetProjectFundingSource,
  useGetProjectUpdates,
  useDeleteProjectUpdate,
} from '@Hooks/Api';
import { useNotificationsContext, useUserContext } from '@Contexts';
import { Project } from '@Types';

interface ProjectDetailsPageControllerProps {
  initialProjectData: Project;
}

export const ProjectDetailsPageController = ({
  initialProjectData,
}: ProjectDetailsPageControllerProps) => {
  const { params } = useRouter();
  const projectId = Number(params[PathParams.PROJECT_ID]);
  const { t } = useTranslation('project');
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const { userData } = useUserContext();
  const [getProjectData, { data: projectData }] = useGetProject();
  const [deleteUpdate, { loading: isDeleteUpdatesLoading }] = useDeleteProjectUpdate();
  const [getProjectUpdates, { paginatedData: projectUpdatePaginatedResponse }] =
    useGetProjectUpdates({ perPage: 6 });
  const [getProjectFundingSource, { data: projectFundingSource, loading: isFundingSourceLoading }] =
    useGetProjectFundingSource();

  useEffect(() => {
    if (userData?.user_type) {
      topLoader.start();
      getProjectData(projectId).finally(() => {
        topLoader.done();
      });
    }
  }, [getProjectData, projectId, userData?.user_type]);

  useEffect(() => {
    getProjectUpdates(projectId);
  }, [getProjectUpdates, projectData, projectId]);

  useEffect(() => {
    getProjectFundingSource(projectId);
  }, [getProjectFundingSource, projectId]);

  const deleteProjectUpdate = useCallback(
    (projectUpdateId: number) =>
      deleteUpdate(projectUpdateId)
        .then(() => {
          successNotification(t('projectUpdate:delete_update_success'));
          getProjectUpdates(projectId);
        })
        .catch(() => {
          errorNotification(t('projectUpdate:delete_update_fail'));
        }),
    [errorNotification, projectId, getProjectUpdates, deleteUpdate, successNotification, t],
  );

  const projectDataToDisplay = projectData || initialProjectData;
  const userCanManageUpdates = projectDataToDisplay.user_can_manage_updates;

  return (
    <ProjectDetailsPage
      project={projectDataToDisplay}
      updateProjectData={getProjectData}
      updateProjectUpdates={getProjectUpdates}
      deleteProjectUpdate={userCanManageUpdates ? deleteProjectUpdate : undefined}
      isDeleteUpdatesLoading={isDeleteUpdatesLoading}
      isFundingSourceLoading={isFundingSourceLoading}
      projectFundingSource={projectFundingSource}
      projectUpdatePaginatedResponse={projectUpdatePaginatedResponse}
    />
  );
};
