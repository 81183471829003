import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, NotFound404 } from '@Components';
import { useNotificationsContext, useUserContext } from '@Contexts';
import { useRouter } from '@Helpers';
import { useJoinCampaign, useGetCampaignData } from '@Hooks/Api';
import { QueryParams, RoutingPaths } from '@App/paths';
import { UserTypeEnum } from '@Types';

import { JoinCampaign, JoinCampaignStateEnum } from './JoinCampaign';

export const JoinCampaignController = () => {
  const { t } = useTranslation('common');
  const { params, push } = useRouter();
  const { userData, isUserLoading } = useUserContext();
  const [joinCampaign, { loading: isJoinCampaignLoading }] = useJoinCampaign();
  const { campaignCode } = params;
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const [getCampaignData, { data: campaignData, loading: isCampaignDataLoading }] =
    useGetCampaignData();
  const [pageState, setPageState] = useState<JoinCampaignStateEnum>(JoinCampaignStateEnum.OK);

  const handleJoinCampaign = useCallback(() => {
    if (userData?.organization_id && typeof campaignCode === 'string') {
      joinCampaign(userData.organization_id, campaignCode)
        .then(() => {
          push(RoutingPaths.DASHBOARD);
          successNotification(t('campaign.join_success'));
        })
        .catch(() => {
          errorNotification(t('campaign.join_failed'));
        });
    }
  }, [
    campaignCode,
    errorNotification,
    joinCampaign,
    push,
    successNotification,
    t,
    userData?.organization_id,
  ]);

  const handleCancel = useCallback(() => {
    push(RoutingPaths.DASHBOARD_GROUP);
  }, [push]);

  useEffect(() => {
    if (!isUserLoading && !userData) {
      push(`${RoutingPaths.SIGN_IN}?${QueryParams.CAMPAIGN_CODE}=${campaignCode}`);
    }
  }, [campaignCode, isUserLoading, push, userData]);

  useEffect(() => {
    if (typeof campaignCode === 'string') {
      getCampaignData(campaignCode).catch((err) => {
        switch (err?.data?.code) {
          case 'NOT_FOUND':
            setPageState(JoinCampaignStateEnum.INVALID_CODE);
            break;
          case 'ORGANIZATION_ALREADY_IN_CAMPAIGN':
            setPageState(JoinCampaignStateEnum.ALREADY_JOINED);
            break;
          case 'CAMPAIGN_HAS_ENDED':
            setPageState(JoinCampaignStateEnum.CAMPAIGN_EXPIRED);
            break;
          default:
            setPageState(JoinCampaignStateEnum.GENERAL);
        }
      });
    }
  }, [campaignCode, getCampaignData]);

  if (isUserLoading || isCampaignDataLoading) {
    return <Loader />;
  }

  if (userData?.user_type === UserTypeEnum.FUNDER) {
    return <NotFound404 />;
  }

  if (!userData) return null;

  return (
    <JoinCampaign
      pageState={pageState}
      campaignName={campaignData?.name}
      onJoinCampaign={handleJoinCampaign}
      onCancel={handleCancel}
      isJoiningCampaign={isJoinCampaignLoading}
    />
  );
};
