/*
Un-paginated version of useGetFundedProjects, needed for getting projects for reporting on the fund management page.
DON'T use this - use the paginated version. This will ideally be phased out in future.
*/
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { PagedResponse, Project } from '@Types';
import { useCallback } from 'react';

interface GetFundedProjectProps {
  fundId?: number | number[];
  organizationId?: number | number[];
}

export const useGetAllFundedProjects = () => {
  const [fetch, response] = useRequest<PagedResponse<Project>>();

  const getFundedProjects = useCallback(
    ({ fundId, organizationId }: GetFundedProjectProps) =>
      fetch(API.paths.funded_projects, 'get', {
        params: {
          fund_id: Array.isArray(fundId) ? fundId.join(',') : fundId,
          organization_id: Array.isArray(organizationId)
            ? organizationId.join(',')
            : organizationId,
          // Django rest framework doesn't give us an easy way to disable pagination, this is a temporary workaround.
          limit: 999,
        },
      }),
    [fetch],
  );

  return [getFundedProjects, response] as const;
};
