import { MouseEventHandler } from 'react';
import classnames from 'classnames';
import styles from './AFIcons.module.scss';

export interface AFRightUpIconProps {
  className?: string;
  onClick?: MouseEventHandler;
}

export const AFRightUpIcon = ({ className, onClick }: AFRightUpIconProps) => {
  return (
    <svg
      className={classnames(className, styles.afIcon)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 8 8"
    >
      <path d="M0.233398 7.29578V4.91608L2.81753 2.53748H0.233398V0.16333H7.9834V7.29578H5.40167V4.91608L2.81753 7.29578H0.233398Z" />
    </svg>
  );
};
