import { Link, PreviewCTA, Tile, Title } from '@Components/common';
import styles from './ProjectsPreview.module.scss';
import { Divider } from '@Components';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { RiAddCircleFill } from 'react-icons/ri';
import { Project } from '@Types';
import { PreviewCard } from './Components/PreviewCard';
import { HelpCenterRoutes, Routes } from '@App/paths';
import { useUserContext } from '@Contexts';
import { getHelpCenterURL } from '@Helpers/helpCenter';

export interface PreviewProps {
  projectsList: Project[];
  isProjectsListLoading: boolean;
}

export const ProjectsPreview = ({ projectsList, isProjectsListLoading }: PreviewProps) => {
  const { t } = useTranslation('project');
  const hasProjects = projectsList && projectsList.length > 0;
  const { userData, isUserLoading } = useUserContext();

  const howToApplyHelpCenterURL =
    userData && !isUserLoading
      ? getHelpCenterURL({
          path: HelpCenterRoutes.HOW_TO_APPLY_TO_FUND,
          user: userData,
        })
      : '';
  return (
    <Tile className={styles.container}>
      <>
        <Title
          title={t('preview.title')}
          subtitle={
            <Trans
              ns="project"
              i18nKey="preview.subtitle"
              components={[<Link underline to={howToApplyHelpCenterURL} />]}
            />
          }
          tooltip={t('preview.tooltip')}
          value={projectsList.length || 0}
        />
        <Divider small />
        <Grid container spacing={1} className={styles.projectContainer}>
          {!isProjectsListLoading &&
            projectsList &&
            projectsList.map((project) => (
              <Grid item className={styles.projectItem} key={project.id}>
                <PreviewCard project={project} />
              </Grid>
            ))}
        </Grid>
        <div className={styles.ctaContainer}>
          {hasProjects && <Divider small />}
          <PreviewCTA
            buttonText={hasProjects ? t('preview.cta') : t('preview.cta_no_projects')}
            icon={hasProjects ? undefined : <RiAddCircleFill />}
            to={hasProjects ? Routes.MY_PROJECTS : Routes.PROJECT_CREATE}
          />
        </div>
      </>
    </Tile>
  );
};
