import React from 'react';
import classnames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import styles from '../CategoriesList/CategoriesList.module.scss';
import { CategoryTile } from '../CategoryTile/CategoryTile';
import { Divider } from '@Components/Divider/Divider';
const DEFAULT_VALUE: never[] = [];
import { BeneficiaryPeopleTypesEnum } from '../types';

interface BeneficiaryListProps {
  name: string;
  beneficiariesList: string[];
  disabled?: boolean;
  required?: boolean;
}

export const BeneficiaryList = ({
  name,
  beneficiariesList,
  disabled,
  required,
}: BeneficiaryListProps) => {
  const { t } = useTranslation('common');
  const { t: tBeneficiaryTypes } = useTranslation('beneficiaryTypes');
  const { control, errors: fieldErrors } = useFormContext();

  return (
    <>
      <Controller
        name={'beneficiaryTypesPeople'}
        control={control}
        defaultValue={DEFAULT_VALUE}
        rules={{
          validate: (checked: number[]) =>
            (required && checked.length > 0) || (t('errors.no_beneficiaries') as string),
        }}
        render={({ value, onChange, ref }) => {
          return (
            <Grid
              container
              spacing={2}
              className={classnames({ [styles.categoriesError]: fieldErrors[name] })}
            >
              <Grid item xs={12}>
                <CategoryTile
                  id={0}
                  name={'All'}
                  checked={value.length == beneficiariesList.length}
                  description=""
                  image=""
                  disabled={disabled}
                  onChange={(e) => {
                    let newArray: string[] = [];
                    if (e.target.checked) {
                      newArray = [...beneficiariesList];
                    }
                    onChange(newArray);
                  }}
                  inputRef={ref}
                />
                <Divider className={styles.divider} />
              </Grid>
              {beneficiariesList.map((ben, i) => (
                <Grid item xs={12} key={i}>
                  <CategoryTile
                    id={i + 1}
                    name={
                      ben == BeneficiaryPeopleTypesEnum.OTHER
                        ? tBeneficiaryTypes(`people.other_funder`)
                        : tBeneficiaryTypes(`people.${ben}`)
                    }
                    checked={value.includes(ben)}
                    description=""
                    image=""
                    disabled={disabled}
                    onChange={(e) => {
                      let newArray = [];
                      if (e.target.checked) {
                        newArray = [...value, ben];
                      } else {
                        newArray = value.filter((benToCheck: string) => ben !== benToCheck);
                      }

                      onChange(newArray);
                    }}
                    inputRef={ref}
                  />
                  {i + 1 !== beneficiariesList.length && <Divider className={styles.divider} />}
                </Grid>
              ))}
            </Grid>
          );
        }}
      />
      {fieldErrors[name] && (
        <p className={styles.errorMessage}>{fieldErrors.categories?.message}</p>
      )}
    </>
  );
};
