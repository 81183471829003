import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { FieldErrors, useForm, useWatch } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { RiAddCircleFill, RiIndeterminateCircleFill } from 'react-icons/ri';

import styleVariables from '@Styles/_variables.module.scss';
import {
  DrawingMode,
  FundDetails as FundDetailsType,
  FundFormStep1,
  GroupTypes,
  LatLngExpression,
  Map2Props,
  FundPreviewDataParams,
} from '@Types';

import {
  Accordion as MaterialAccordion,
  AccordionSummary as MaterialAccordionSummary,
  AccordionDetails as MaterialAccordionDetails,
  Link,
} from '@material-ui/core';
import {
  useAddFundDetails,
  useCreateFundDetails,
  useGetLocalAuthorityWithFeature,
  useFundPreviewDataExport,
} from '@Hooks/Api';

import { CriteriaData, BeneficiaryPeopleTypesEnum } from './types';
import styles from './FundCriteria.module.scss';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPolygon } from 'geojson';
import { CriteriaColumn } from './CriteriaColumn';
import { useNotificationsContext } from '@Contexts';
import { Trans, useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import flip from '@turf/flip';
import { useGetFundPreviewData } from '@Hooks/Api/useGetFundPreviewData';
import { SavePlannedFundModal } from '@Components/FundCriteria/SavePlannedFundModal';
import { Button, Tile } from '@Components/common';
import { ExploreProjectCard } from '@Components/ExploreProjectCard/ExploreProjectCard';
import { AFLeftIcon } from '@Components/Icons/AFLeftIcon';
import { AFRightIcon } from '@Components/Icons/AFRightIcon';
import { ALL_CHOICE, GROUP_TYPE_CHOICES, GROUP_TYPE_CHOICES_WITH_ALL } from '@Helpers/choices';
import { useRouter } from 'next/router';
import { Routes, RoutingPaths } from '@App/paths';
import { useSetFieldErrors } from '@Hooks';
import { msgFieldRequired } from '@Helpers';
import { OpenDraftModal } from './OpenDraftModal';
import { Divider } from '@Components/Divider/Divider';
import { DownloadButtonController } from '@Components/common/DownloadButton/DownloadButtonController';
import bbox from '@turf/bbox';
import circle from '@turf/circle';
import { Units } from '@turf/helpers';
import { UK_BOUNDING_BOX } from '@Helpers/constants';

const Map2 = dynamic<Map2Props>(
  () => import('@Components/Map2/Map2').then((mod) => mod.Map2) as any,
  {
    ssr: false,
  },
);

export interface FundCriteriaProps {
  fundId?: number;
  fundDetails?: FundDetailsType;
  localAuthorities: { id: number; name: string }[];
  originalCategories: number[];
  originalPostcode: string;
  nextStep: () => void;
  setFundId: (id: number) => void;
}

export const FundCriteria = ({
  fundId,
  fundDetails,
  localAuthorities,
  originalCategories,
  originalPostcode,
  nextStep,
  setFundId,
}: FundCriteriaProps) => {
  const maxNumberOfMarkers = 110;
  const { t } = useTranslation('fund');
  const { t: tCommon } = useTranslation('common');
  const { t: tSocialValue } = useTranslation('socialValue');
  const { push } = useRouter();

  const { error: errorNotification } = useNotificationsContext();

  const [circles, setCircles] = useState<
    | Array<{
        center: LatLngExpression;
        radius: number;
      }>
    | undefined
  >(undefined);
  const [localAuthorityFeature, setLocalAuthorityFeature] = useState<Feature | null>(null);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const [statsAccordionOpen, setStatsAccordionOpen] = useState(false);
  const [draftsModalOpen, setDraftsModalOpen] = useState(false);
  const [showSaveAndExitForm, setShowSaveAndExitForm] = useState(false);
  const [boundingBox, setBoundingBox] = useState(UK_BOUNDING_BOX);
  const [minimumGrantAmountOpen, setMinimumGrantAmountOpen] = useState(false);

  const toggleStatsAccordionOpen = useCallback(() => {
    return setStatsAccordionOpen(!statsAccordionOpen);
  }, [statsAccordionOpen]);

  const removeAllOptionFromFocusAreas = useCallback((focusAreasWithAll: number[]) => {
    // CategoriesList uses 0 as a value to represent 'all', which
    // here we'll just ignore and leave as nothing selected.
    return focusAreasWithAll.filter((areaId: number) => areaId !== 0);
  }, []);

  const addAllOptionToFocusAreasIfNeeded = useCallback(
    (focusAreasWithoutAll: number[]) => {
      if (focusAreasWithoutAll.length == originalCategories.length) {
        return [0, ...focusAreasWithoutAll];
      } else {
        return focusAreasWithoutAll;
      }
    },
    [originalCategories.length],
  );

  const addAllOptionToNonProfitTypesIfNeeded = useCallback((groupTypesWithoutAll: GroupTypes[]) => {
    if (groupTypesWithoutAll.length == GROUP_TYPE_CHOICES.length) {
      return [ALL_CHOICE, ...groupTypesWithoutAll];
    } else {
      return groupTypesWithoutAll;
    }
  }, []);

  const methods = useForm<CriteriaData>({
    defaultValues: {
      maximum_grant_amount: null,
      minimum_grant_amount: null,
      starting_amount: null,
      name: null,
      radius: 15,
      postcode: originalPostcode || '',
      focusAreas: addAllOptionToFocusAreasIfNeeded(originalCategories),
      beneficiaryTypesPeople: fundDetails
        ? fundDetails.beneficiary_types_people
        : Object.values(BeneficiaryPeopleTypesEnum),
      drawingMode: fundDetails ? fundDetails.catchment_area_type : DrawingMode.RADIUS,
      nonProfitTypes: GROUP_TYPE_CHOICES_WITH_ALL,
    },
  });
  const { getValues, reset } = methods;

  const hasErrors = !isEmpty(methods.errors);

  // Catchment Area details
  const [featureCollection, setFeatureCollection] = useState<FeatureCollection | undefined>();
  const initialEditableFeatureCollection:
    | FeatureCollection<Geometry, GeoJsonProperties>
    | undefined = useMemo(() => {
    return fundDetails && fundDetails.areas
      ? {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: fundDetails.areas,
              properties: {},
            },
          ],
        }
      : undefined;
  }, [fundDetails]);
  const [editableFeatureCollection, setEditableFeatureCollection] = useState<
    FeatureCollection | undefined
  >(initialEditableFeatureCollection);

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  useEffect(() => {
    if (!initialDataLoaded) {
      if (fundDetails && localAuthorities) {
        const catchmentAreaType = fundDetails.catchment_area_type || DrawingMode.RADIUS;
        const categories = addAllOptionToFocusAreasIfNeeded(fundDetails.categories);
        reset({
          name: fundDetails.name,
          drawingMode: catchmentAreaType,
          focusAreas: categories,
          postcode: originalPostcode,
          radius: catchmentAreaType == DrawingMode.RADIUS ? fundDetails.radius : null,
          areas: catchmentAreaType == DrawingMode.POLYGON ? fundDetails.areas : null,
          maximum_grant_amount: fundDetails ? fundDetails.maximum_grant_amount : null,
          minimum_grant_amount: fundDetails ? fundDetails.minimum_grant_amount : null,
          starting_amount: fundDetails.starting_amount,
          nonProfitTypes: addAllOptionToNonProfitTypesIfNeeded(fundDetails.group_types_to_support),
          localAuthority:
            catchmentAreaType == DrawingMode.LOCAL_AUTHORITY ? fundDetails.local_authority : null,
          beneficiaryTypesPeople: fundDetails ? fundDetails.beneficiary_types_people : [],
        });
        if (fundDetails.minimum_grant_amount) {
          // If we're loading a fund with this field set, un-hide it.
          setMinimumGrantAmountOpen(true);
        }
      }
      // react-hook-form has some inconsistent behaviours where if we submit the data now,
      // the form data that gets submitted will still be out of date, even though
      // all our `useWatch` values below are up to date.
      // We can work around that by delaying until the next tick, to ensure
      // the actual form values have had time to get updated.
      setTimeout(() => {
        setInitialDataLoaded(true);
      }, 100);
    }
  }, [
    fundDetails,
    localAuthorities,
    reset,
    originalCategories,
    originalPostcode,
    initialDataLoaded,
    addAllOptionToFocusAreasIfNeeded,
    addAllOptionToNonProfitTypesIfNeeded,
  ]);

  const fundName: string | null | undefined = useWatch({
    name: 'name',
    control: methods.control,
  });
  const focusAreas: number[] | null | undefined = useWatch({
    name: 'focusAreas',
    control: methods.control,
  });
  const radius: number | null | undefined = useWatch({
    name: 'radius',
    control: methods.control,
  });
  const localAuthority: number | null | undefined = useWatch({
    name: 'localAuthority',
    control: methods.control,
  });
  const postcode: string | null | undefined = useWatch({
    name: 'postcode',
    control: methods.control,
  });
  const individualGrantAmount: string | null | undefined = useWatch({
    name: 'maximum_grant_amount',
    control: methods.control,
  });
  const minimumGrantAmount: string | null | undefined = useWatch({
    name: 'minimum_grant_amount',
    control: methods.control,
  });
  const startingAmount: string | null | undefined = useWatch({
    name: 'starting_amount',
    control: methods.control,
  });
  const drawingMode: DrawingMode | null | undefined = useWatch({
    name: 'drawingMode',
    control: methods.control,
  });
  const nonProfitTypes: string[] | null | undefined = useWatch({
    name: 'nonProfitTypes',
    control: methods.control,
  });
  const beneficiaryTypesPeople: string[] | null | undefined = useWatch({
    name: 'beneficiaryTypesPeople',
    control: methods.control,
  });

  // Last submitted values, avoid needing two separate places that calculate the data to submit
  // between the preview and the downloads.
  const [lastSubmittedData, setLastSubmittedData] = useState<FundPreviewDataParams | null>(null);
  const [projectDownloadData, setProjectDownloadData] = useState<any>(null);

  const [
    getSocialValueProjects,
    {
      data: socialValueProjectsData,
      loading: isSocialValueProjectsLoading,
      error: socialValueProjectsErrors,
    },
  ] = useGetFundPreviewData();

  const [getLocalAuthority] = useGetLocalAuthorityWithFeature();

  useEffect(() => {
    if (localAuthority) {
      getLocalAuthority(localAuthority).then(({ data }) => {
        setLocalAuthorityFeature(data.feature);
      });
    }
  }, [getLocalAuthority, localAuthority]);

  useEffect(() => {});

  const [
    createFundDetails,
    { loading: isCreateFundDetailsLoading, error: createFundDetailsError },
  ] = useCreateFundDetails();
  const [addFundDetails, { error: addFundDetailsError, loading: isAddFundDetailsLoading }] =
    useAddFundDetails();

  const selectedProjectData =
    socialValueProjectsData != null ? socialValueProjectsData.projects[selectedProjectIndex] : null;

  const {
    beneficiaries,
    community_group,
    nonprofit_count,
    total_funding_required,
    average_funding_required,
    registered_charity,
    social_enterprise,
    projects_map_details,
    total_project_count,
    volunteering_opportunities,
  } = socialValueProjectsData || {};

  useEffect(() => {
    const radius = getValues('radius');
    const featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    // If we're drawing a polygon, but haven't drawn it yet, don't show
    // any features, so we don't get in the way of the drawing.
    if (drawingMode != DrawingMode.POLYGON || editableFeatureCollection?.features.length) {
      featureCollection.features =
        projects_map_details?.features.slice(0, maxNumberOfMarkers) || [];
    }

    if (
      socialValueProjectsData &&
      socialValueProjectsData.initial_search_coords &&
      drawingMode == DrawingMode.RADIUS
    ) {
      featureCollection.features.unshift({
        type: 'Feature',
        geometry: socialValueProjectsData.initial_search_coords.geometry as Geometry,
        properties: {
          marker: 'home',
          popup: null,
        },
      });

      setCircles([
        {
          center: flip(socialValueProjectsData.initial_search_coords.geometry)
            .coordinates as LatLngExpression,
          radius: radius || 0,
        },
      ]);
    } else {
      setCircles(undefined);
    }

    featureCollection.features.forEach((feature) => {
      if (feature.properties) {
        if (selectedProjectData && selectedProjectData.id == feature.properties.projectId) {
          feature.properties.marker = 'pink';
        } else if (feature.properties.marker !== 'home') {
          // Reset the marker back to the default marker if it's unselected.
          // This also discards the 'highlightedProject' marker from the backend, which we
          // don't care about here.
          feature.properties.marker = 'default';
        }
      }
    });

    if (drawingMode === DrawingMode.LOCAL_AUTHORITY && localAuthorityFeature) {
      featureCollection.features.push(localAuthorityFeature);
    }

    setFeatureCollection(featureCollection);
  }, [
    socialValueProjectsData,
    projects_map_details?.features,
    selectedProjectData,
    getValues,
    localAuthorityFeature,
    drawingMode,
    editableFeatureCollection,
  ]);

  const onMarkerClick = useCallback(
    (featureProperties, event) => {
      const projectId = featureProperties && featureProperties.projectId;
      if (projectId != null && socialValueProjectsData != null) {
        const newSelectedProjectIndex = socialValueProjectsData.projects.findIndex(
          (project) => project.id == projectId,
        );
        if (newSelectedProjectIndex != null) {
          setSelectedProjectIndex(newSelectedProjectIndex);
        }
      }
    },
    [socialValueProjectsData],
  );

  const debouncedPreviewRequest = useMemo(() => {
    return debounce(async (submittedData) => {
      getSocialValueProjects(submittedData);
    }, 500);
  }, [getSocialValueProjects]);

  const validatedDebouncedPreviewRequest = useCallback(() => {
    methods.handleSubmit((formData) => {
      const submittedNonProfitTypes = formData.nonProfitTypes;
      const submittedDrawingMode = formData.drawingMode;
      const processedNonProfitTypes = submittedNonProfitTypes
        ? submittedNonProfitTypes.filter((type: string) => type !== 'ALL')
        : [];

      const dataToSubmit: FundPreviewDataParams = {
        name: formData.name || null,
        maximum_grant_amount: formData.maximum_grant_amount || null,
        minimum_grant_amount: formData.minimum_grant_amount || null,
        starting_amount: formData.starting_amount || null,
        postcode: formData.postcode || null,
        radius: null,
        categories: removeAllOptionFromFocusAreas(formData.focusAreas || originalCategories),
        catchment_type: formData.drawingMode || DrawingMode.RADIUS,
        areas: null,
        group_types_to_support: processedNonProfitTypes as GroupTypes[],
        video_pitch_required: false,
        local_authority: null,
        beneficiary_types_people: formData.beneficiaryTypesPeople || null,
      };

      if (
        submittedDrawingMode == DrawingMode.POLYGON &&
        editableFeatureCollection?.features.length
      ) {
        const multiPolygon: MultiPolygon = {
          type: 'MultiPolygon',
          coordinates: [],
        };
        editableFeatureCollection.features.forEach((feature) => {
          if (feature.geometry.type == 'Polygon') {
            multiPolygon.coordinates.push(feature.geometry.coordinates);
          } else if (feature.geometry.type == 'MultiPolygon') {
            feature.geometry.coordinates.forEach((polygonCoords) => {
              multiPolygon.coordinates.push(polygonCoords);
            });
          }
        });
        dataToSubmit.areas = multiPolygon;
      } else if (submittedDrawingMode == DrawingMode.RADIUS) {
        dataToSubmit.radius = formData.radius || null;
      } else if (submittedDrawingMode == DrawingMode.LOCAL_AUTHORITY) {
        dataToSubmit.local_authority = formData.localAuthority || null;
      }

      setLastSubmittedData(dataToSubmit);
      setProjectDownloadData(
        dataToSubmit && {
          maximum_grant_amount: dataToSubmit.maximum_grant_amount,
          minimum_grant_amount: dataToSubmit.minimum_grant_amount,
          postcode: dataToSubmit.postcode,
          radius: dataToSubmit.radius,
          categories: dataToSubmit.categories,
          catchment_type: dataToSubmit.catchment_type,
          areas: dataToSubmit.areas,
          group_types_to_support: dataToSubmit.group_types_to_support,
          video_pitch_required: dataToSubmit.video_pitch_required,
          local_authority: dataToSubmit.local_authority,
          beneficiary_types_people: dataToSubmit.beneficiary_types_people,
        },
      );
      debouncedPreviewRequest(dataToSubmit);
    })();
  }, [
    methods,
    debouncedPreviewRequest,
    // The feature collection is stored in state rather than in form fields, so we need to refresh this when it changes.
    editableFeatureCollection,
    originalCategories,
    removeAllOptionFromFocusAreas,
  ]);

  useEffect(() => {
    // Cancel the preview request if we navigate away
    return () => {
      debouncedPreviewRequest.cancel();
    };
  }, [debouncedPreviewRequest]);

  useEffect(() => {
    // This will kick off a preview request on each change,
    // and also after initial load.
    if (initialDataLoaded) {
      if (
        (drawingMode == DrawingMode.POLYGON && editableFeatureCollection == null) ||
        (drawingMode == DrawingMode.LOCAL_AUTHORITY && localAuthority == null)
      ) {
        // If we've switched to 'draw an area', but haven't selected an area yet,
        // don't refresh the data as it'll just return nothing and mess with the map focus.
        return;
      }
      validatedDebouncedPreviewRequest();
    }
    // validatedDebouncedPreviewRequest doesn't need to be a dependency here, as it results in circular updates.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    drawingMode,
    focusAreas,
    individualGrantAmount,
    minimumGrantAmount,
    startingAmount,
    nonProfitTypes,
    editableFeatureCollection,
    postcode,
    radius,
    fundName,
    initialDataLoaded,
    localAuthority,
    beneficiaryTypesPeople,
  ]);

  const validateForSave = useCallback(() => {
    if (lastSubmittedData == null) {
      return false;
    }
    // We only want to highlight these errors on save, not on submitting the info for the map,
    // as they're not required for that.
    let canSubmit = true;
    if (!lastSubmittedData.maximum_grant_amount) {
      methods.setError('maximum_grant_amount', {
        type: 'custom',
        message: msgFieldRequired,
      });
      canSubmit = false;
    }
    if (!lastSubmittedData.starting_amount) {
      methods.setError('starting_amount', {
        type: 'custom',
        message: msgFieldRequired,
      });
      canSubmit = false;
    }
    if (!lastSubmittedData.name) {
      methods.setError('name', {
        type: 'custom',
        message: msgFieldRequired,
      });
      canSubmit = false;
    }
    return canSubmit;
  }, [lastSubmittedData, methods]);

  const saveFund = useCallback(
    (submitCallback: () => void) => {
      if (!validateForSave()) {
        errorNotification(tSocialValue('fix_errors'));
        return;
      }
      const finalData = {
        ...lastSubmittedData,
        // This call uses 'catchment_area_type', not catchment_type.
        catchment_type: undefined,
        catchment_area_type: lastSubmittedData?.catchment_type,
        // Validation makes typing this as the final submitted data fiddly.
      } as unknown as FundFormStep1;
      if (fundId) {
        addFundDetails(fundId, finalData, true).then(() => {
          submitCallback();
        });
      } else {
        createFundDetails(finalData).then(({ data: { id } }) => {
          setFundId(id);
          submitCallback();
        });
      }
    },
    [
      lastSubmittedData,
      addFundDetails,
      createFundDetails,
      setFundId,
      fundId,
      validateForSave,
      errorNotification,
      tSocialValue,
    ],
  );

  const onCancel = useCallback(() => {
    push(RoutingPaths.DASHBOARD_FUNDER);
  }, [push]);

  const saveAndContinue = useCallback(() => {
    saveFund(() => {
      nextStep();
    });
  }, [nextStep, saveFund]);

  const saveAndDownload = useCallback(
    (startJob: any) => {
      saveFund(() => {
        startJob();
      });
    },
    [saveFund],
  );

  const openDownloadModal = useCallback(() => {
    setShowSaveAndExitForm(true);
  }, [setShowSaveAndExitForm]);

  const selectDraftFund = useCallback((fundId) => {
    // Note that this deliberately avoids using router.push, as that will just change the fundId
    // parameter of the route but not actually reload the page, and it's a lot simpler to just
    // reload than handle the fundId changing and everything needing reloaded.
    window.location.href = Routes.FUND_EDIT(fundId);
  }, []);

  const showMinimumGrantAmount = useCallback(() => {
    setMinimumGrantAmountOpen(true);
  }, []);

  // We need to show errors from the preview projects, OR
  // from creating the fund, OR adding details to a fund.
  useSetFieldErrors({
    fieldErrors: socialValueProjectsErrors?.field_errors,
    setError: methods.setError,
    loading: isSocialValueProjectsLoading,
  });
  useSetFieldErrors({
    fieldErrors: addFundDetailsError?.field_errors,
    setError: methods.setError,
    loading: isAddFundDetailsLoading,
  });
  useSetFieldErrors({
    fieldErrors: createFundDetailsError?.field_errors?.details as FieldErrors | undefined,
    setError: methods.setError,
    loading: isCreateFundDetailsLoading,
  });

  const selectProjectByIndex = useCallback(
    (index) => {
      if (socialValueProjectsData) {
        const newSelectedProject = socialValueProjectsData.projects[index];
        if (newSelectedProject) {
          setSelectedProjectIndex(index);
        }
      }
    },
    [socialValueProjectsData],
  );

  const previousProjectIndex = selectedProjectIndex > 0 ? selectedProjectIndex - 1 : null;
  const nextProjectIndex =
    socialValueProjectsData && selectedProjectIndex < socialValueProjectsData.projects.length - 1
      ? selectedProjectIndex + 1
      : null;

  const topRowStats = [
    {
      ns: 'socialValue',
      i18nKey: 'totalizers.total_funding_required',
      value: total_funding_required || 0,
    },
    {
      ns: 'socialValue',
      i18nKey: 'totalizers.average_funding_required',
      value: average_funding_required || 0,
    },
    {
      ns: 'socialValue',
      i18nKey: 'totalizers.estimated_beneficiaries',
      value: beneficiaries || 0,
    },
    {
      ns: 'socialValue',
      i18nKey: 'totalizers.volunteering_opportunities',
      value: volunteering_opportunities || 0,
    },
  ];
  const bottomRowStats = [
    {
      ns: 'socialValue',
      i18nKey: 'totalizers.nonprofit_count',
      value: nonprofit_count || 0,
    },
    {
      ns: 'socialValue',
      i18nKey: 'totalizers.community_group',
      value: community_group || 0,
    },
    {
      ns: 'socialValue',
      i18nKey: 'totalizers.registered_charities',
      value: registered_charity || 0,
    },
    {
      ns: 'socialValue',
      i18nKey: 'totalizers.social_enterprise',
      value: social_enterprise || 0,
    },
  ];

  // Handling auto zooming
  useEffect(() => {
    if (drawingMode == DrawingMode.UK_WIDE) {
      setBoundingBox(UK_BOUNDING_BOX);
    } else if (drawingMode == DrawingMode.LOCAL_AUTHORITY && localAuthorityFeature) {
      let turfBbox = bbox(localAuthorityFeature);

      setBoundingBox([
        [turfBbox[1], turfBbox[0]],
        [turfBbox[3], turfBbox[2]],
      ]);
    } else if (drawingMode == DrawingMode.POLYGON && editableFeatureCollection?.features.length) {
      let turfBbox = bbox(editableFeatureCollection);

      setBoundingBox([
        [turfBbox[1], turfBbox[0]],
        [turfBbox[3], turfBbox[2]],
      ]);
    } else if (drawingMode == DrawingMode.RADIUS && circles) {
      let center = circles[0].center;
      center.reverse();

      // GeoJSON doesn't support circles, so we need to create a polygon that is roughly the same shape.
      // The steps value is the number of points to use to approximate the circle.
      let options: {
        steps: number;
        units: Units;
      } = { steps: 10, units: 'miles' };
      let turfCircle = circle(center, circles[0].radius, options);

      let turfBbox = bbox(turfCircle);

      setBoundingBox([
        [turfBbox[1], turfBbox[0]],
        [turfBbox[3], turfBbox[2]],
      ]);
    }
  }, [
    drawingMode,
    editableFeatureCollection?.features.length,
    localAuthorityFeature,
    circles,
    editableFeatureCollection,
  ]);

  return (
    <div className={styles.fundCriteriaContainer}>
      <Tile className={styles.criteriaColumnTile}>
        <div className={styles.criteriaColumnScrollContainer}>
          <h2 className={styles.fundCriteriaHeading}>{tSocialValue('criteria.header')}</h2>
          <p>{tSocialValue('criteria.subtitle')}</p>
          <Link
            className={styles.linkButton}
            onClick={() => {
              setDraftsModalOpen(true);
            }}
          >
            {tSocialValue('criteria.saved_drafts')}
          </Link>
          <Divider small />
          <FormProvider {...methods}>
            <CriteriaColumn
              localAuthorities={localAuthorities}
              resultsLoading={isSocialValueProjectsLoading}
              errors={socialValueProjectsErrors}
              minimumGrantAmountOpen={minimumGrantAmountOpen}
              showMinimumGrantAmount={showMinimumGrantAmount}
            />
          </FormProvider>
        </div>
      </Tile>
      <div className={styles.resultsContainer}>
        <MaterialAccordion
          expanded={statsAccordionOpen}
          className={styles.totalizers}
          onChange={toggleStatsAccordionOpen}
        >
          <MaterialAccordionSummary
            expandIcon={
              <>
                {statsAccordionOpen ? (
                  <RiIndeterminateCircleFill className={styles.smallIcon} />
                ) : (
                  <div className={styles.iconAndLabel}>
                    <RiAddCircleFill className={styles.smallIcon} />
                    <div className={styles.iconText}>{tSocialValue('view_more')}</div>
                  </div>
                )}
              </>
            }
          >
            <div>
              <h3 className={styles.totalizersTitle}>{tSocialValue('local_need_analysis')}</h3>
              <div>
                <div className={styles.totalizerTopRow}>
                  <div className={styles.totalizerProjectMatchValue}>
                    {total_project_count != null && (
                      <Trans
                        ns="landingPage"
                        i18nKey="totalizers.project_matches"
                        components={[<div />, <strong />]}
                        values={{ amount: total_project_count }}
                        count={total_project_count}
                      />
                    )}
                    <img
                      src="/assets/Explore/underline.svg"
                      alt="underline"
                      className={styles.underline}
                    />
                  </div>
                  {topRowStats.map((total, i) => {
                    return (
                      <div className={styles.totalizerStat} key={i}>
                        <Trans
                          ns={total.ns}
                          i18nKey={total.i18nKey}
                          components={[<strong />, <p className={styles.text} />]}
                          values={{ amount: total.value }}
                          count={total.value}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </MaterialAccordionSummary>
          <MaterialAccordionDetails>
            <div className={styles.totalizerBottomRow}>
              {bottomRowStats.map((total, i) => {
                return (
                  <div className={styles.totalizerStat} key={i}>
                    <Trans
                      ns={total.ns}
                      i18nKey={total.i18nKey}
                      components={[<strong />, <p className={styles.text} />]}
                      values={{ amount: total.value }}
                      count={total.value}
                    />
                  </div>
                );
              })}
            </div>
          </MaterialAccordionDetails>
        </MaterialAccordion>
        <div className={styles.map}>
          <Map2
            boundingBox={boundingBox}
            circles={circles}
            drawingMode={drawingMode || undefined}
            initialEditableFeatureCollection={initialEditableFeatureCollection}
            featureCollection={featureCollection}
            height={450}
            markers={{
              home: {
                backgroundColor: styleVariables.AFNavyBlue500,
                component: <HomeWorkIcon htmlColor="white" />,
              },
              default: {
                leafletIconName: 'default',
              },
              pink: {
                leafletIconName: 'pink',
              },
            }}
            message={{
              content: t('landingPage:project_count_warning'),
              // This isn't a great checker as the polygons and postcode marker will eat into it
              // but it's a temp measure so I'm allowing it
              visible:
                (featureCollection && featureCollection.features.length > maxNumberOfMarkers) ||
                false,
            }}
            onMarkerClick={onMarkerClick}
            setEditableFeatureCollection={setEditableFeatureCollection}
          />
        </div>
        {selectedProjectData && (
          <div className={styles.projectPreviewContainer}>
            <div className={styles.leftRightIconContainer}>
              {previousProjectIndex != null && (
                <AFLeftIcon
                  className={styles.leftRightIcon}
                  onClick={() => selectProjectByIndex(previousProjectIndex)}
                />
              )}
            </div>
            <div className={styles.exploreCardContainer}>
              <ExploreProjectCard project={selectedProjectData} />
            </div>
            <div className={styles.leftRightIconContainer}>
              {nextProjectIndex != null && (
                <AFRightIcon
                  className={styles.leftRightIcon}
                  onClick={() => selectProjectByIndex(nextProjectIndex)}
                />
              )}
            </div>
          </div>
        )}
        <div className={styles.buttonsRow}>
          <Button buttonType="text" onClick={onCancel}>
            {tCommon('cancel')}
          </Button>
          <div className={styles.buttonsRowRightGroup}>
            <DownloadButtonController
              buttonType="secondary"
              useStartJob={useFundPreviewDataExport}
              jobArgs={projectDownloadData}
              beforeDownload={saveAndDownload}
              afterDownload={openDownloadModal}
              forceDisabled={hasErrors}
            >
              {tSocialValue('save_and_download')}
            </DownloadButtonController>
            <Button buttonType="primary" onClick={saveAndContinue} disabled={hasErrors}>
              {tSocialValue('save_and_continue')}
            </Button>
          </div>
        </div>
      </div>
      <SavePlannedFundModal
        isOpen={showSaveAndExitForm}
        onClose={() => {
          setShowSaveAndExitForm(false);
        }}
        onExit={onCancel}
      />
      <OpenDraftModal
        isOpen={draftsModalOpen}
        onClose={() => {
          setDraftsModalOpen(false);
        }}
        onSelect={selectDraftFund}
      />
    </div>
  );
};
