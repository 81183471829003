import { useUserContext } from '@Contexts';
import { GroupProfileController } from './GroupProfileController';

export const CreateGroupProfileController = () => {
  const { userData, isUserLoading } = useUserContext();

  if (isUserLoading) {
    return null;
  }

  return (
    <GroupProfileController forceNoProfile organizationName={userData?.organization_name ?? ''} />
  );
};
