import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import style from './DeliveryPlan.module.scss';
import { FormControl, FormHelperText, FormLabel, InputLabel } from '@material-ui/core';
import { TimeframeEstimateUnitEnum } from '@Types';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { useFormContext, Controller } from 'react-hook-form';
import {
  TextInput,
  SelectInput,
  Link,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
} from '@Components/common';
import {
  createMenuItems,
  CreateMenuItemsProps,
  BudgetBreakdown,
  FieldNote,
  SectionHeader,
} from '@Components';
import classnames from 'classnames';
import { getBeneficiaryPeopleCategories } from '@Helpers/getBeneficiaryPeopleCategories';
import { HelpCenterRoutes } from '@App/paths';
import { useUserContext } from '@Contexts';
import { getHelpCenterURL } from '@Helpers/helpCenter';

const INPUT_MAX_LENGTH = 1000;
const EXPECTED_IMPACT_MIN_LENGTH = 200;

interface DeliveryPlanProps {
  isLoading: boolean;
  onSubmit: any;
  buttons: JSX.Element;
  grantAmount?: number;
  budgetBreakdownElementsCount?: number;
  volunteeringOpportunity: boolean;
  setHasVolunteeringOpportunity: any;
  hasVolunteeringOpportunity: boolean;
}

export const DeliveryPlan: React.FC<DeliveryPlanProps> = ({
  isLoading,
  onSubmit,
  buttons,
  grantAmount,
  budgetBreakdownElementsCount,
  volunteeringOpportunity,
  setHasVolunteeringOpportunity,
  hasVolunteeringOpportunity,
}) => {
  const { t } = useTranslation('project');
  const { t: tBeneficiaryTypes } = useTranslation('beneficiaryTypes');
  const { register, control, errors: fieldErrors, watch } = useFormContext();

  const beneficiaryTypesPeople = watch('delivery_plan.beneficiary_types_people');
  const hasOtherPeopleBeneficiaryTypes = beneficiaryTypesPeople?.includes('other');
  const timeframeUnitOptions: CreateMenuItemsProps['items'] = useMemo(
    () =>
      Object.keys(TimeframeEstimateUnitEnum).map((key) => ({
        label: t(`delivery.estimate_timeframe_unit.${key}`),
        menuItemProps: {
          value: key,
        },
      })),
    [t],
  );

  const valueFromString = (value: any) => {
    if (value == 'true') {
      setHasVolunteeringOpportunity(true);
    } else {
      setHasVolunteeringOpportunity(false);
    }
    if (value == null || value === '') {
      return null;
    }
    return value === 'true';
  };

  const valueToString = (value: string) => {
    if (value == null || value === '') {
      return '';
    }
    return value ? 'true' : 'false';
  };

  useEffect(() => {
    if (volunteeringOpportunity) {
      setHasVolunteeringOpportunity(true);
    }
  }, [setHasVolunteeringOpportunity, volunteeringOpportunity]);

  const beneficiaryPeopleCategories = getBeneficiaryPeopleCategories(tBeneficiaryTypes);
  const { userData, isUserLoading } = useUserContext();
  const budgetBreakdownGuideURL =
    userData && !isUserLoading
      ? getHelpCenterURL({
          path: HelpCenterRoutes.BUDGET_BREAKDOWN_GUIDE,
          user: userData,
        })
      : '';
  const impactReportingGuideURL =
    userData && !isUserLoading
      ? getHelpCenterURL({
          path: HelpCenterRoutes.IMPACT_REPORTING_GUIDE,
          user: userData,
        })
      : '';
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <SectionHeader
        title={t('delivery.budget_header')}
        subtitle={
          <Trans
            ns="project"
            i18nKey="delivery.budget_description"
            components={[<Link href={budgetBreakdownGuideURL} primary />]}
          />
        }
      />
      <BudgetBreakdown grantAmount={grantAmount} elementsCount={budgetBreakdownElementsCount} />
      <SectionHeader
        title={t('delivery.estimate_header')}
        subtitle={t('delivery.estimate_description')}
      />
      <div className={style.rowWrapper}>
        <TextInput
          label={t('delivery.estimate_label')}
          name="delivery_plan.timeframe_estimate_number"
          type="number"
          inputRef={register({ required: msgFieldRequired })}
          error={!!fieldErrors.delivery_plan?.timeframe_estimate_number}
          helperText={fieldErrors.delivery_plan?.timeframe_estimate_number?.message}
          disabled={isLoading}
        />
        <FormControl error={!!fieldErrors.delivery_plan?.timeframe_estimate_unit}>
          <InputLabel>{t('delivery.estimate_period')}</InputLabel>
          <Controller
            name="delivery_plan.timeframe_estimate_unit"
            rules={{ required: msgFieldRequired }}
            control={control}
            defaultValue={''}
            render={({ ref, onChange, value }) => (
              <SelectInput
                inputRef={ref}
                label={t('delivery.estimate_period')}
                onChange={onChange}
                value={value}
                error={!!fieldErrors.delivery_plan?.timeframe_estimate_unit}
                disabled={isLoading}
              >
                {createMenuItems({ items: timeframeUnitOptions })}
              </SelectInput>
            )}
          />
          <FormHelperText>
            {fieldErrors.delivery_plan?.timeframe_estimate_unit?.message}
          </FormHelperText>
        </FormControl>
      </div>
      <SectionHeader
        title={t('delivery.beneficiaries_header')}
        subtitle={t('delivery.beneficiaries_description')}
      />
      <TextInput
        label={t('delivery.beneficiaries_number_label')}
        name="delivery_plan.beneficiaries"
        type="number"
        inputRef={register({ required: msgFieldRequired })}
        error={!!fieldErrors.delivery_plan?.beneficiaries}
        helperText={fieldErrors.delivery_plan?.beneficiaries?.message}
        disabled={isLoading}
      />
      <FormControl error={!!fieldErrors.delivery_plan?.beneficiary_types_people}>
        <FormLabel>
          <p>{t('delivery.beneficiary_types_people_label')}</p>
          <p className={style.subtitle}>{t('delivery.beneficiary_types_people_sublabel')}</p>
        </FormLabel>
        <CheckboxGroup
          name="delivery_plan.beneficiary_types_people"
          items={beneficiaryPeopleCategories}
          required
        />
        <FormHelperText>
          {fieldErrors.delivery_plan?.beneficiary_types_people?.message}
        </FormHelperText>
      </FormControl>
      {hasOtherPeopleBeneficiaryTypes && (
        <TextInput
          label={t('delivery.beneficiary_types_people_other_label')}
          name="delivery_plan.beneficiary_types_people_other"
          inputRef={register({ required: msgFieldRequired })}
          error={!!fieldErrors.delivery_plan?.beneficiary_types_people_other}
          helperText={fieldErrors.delivery_plan?.beneficiary_types_people_other?.message}
          disabled={isLoading}
        />
      )}
      <SectionHeader
        title={t('delivery.impact_header')}
        subtitle={t('delivery.impact_description')}
      />
      <TextInput
        label={t('delivery.impact_label')}
        displayCharLimit={INPUT_MAX_LENGTH}
        multiline
        name="delivery_plan.expected_impact"
        inputRef={register({
          required: msgFieldRequired,
          maxLength: INPUT_MAX_LENGTH,
          minLength: {
            value: EXPECTED_IMPACT_MIN_LENGTH,
            message: t('common:min_length_200'),
          },
        })}
        inputProps={{
          maxLength: INPUT_MAX_LENGTH,
        }}
        error={!!fieldErrors.delivery_plan?.expected_impact}
        helperText={fieldErrors.delivery_plan?.expected_impact?.message}
        disabled={isLoading}
      />
      <FieldNote icon={<EmojiObjectsOutlinedIcon style={{ fontSize: 15 }} />}>
        <Trans
          ns="project"
          i18nKey="delivery.expected_impact_info"
          components={[<Link href={impactReportingGuideURL} primary />]}
        />
      </FieldNote>
      <SectionHeader
        title={t('delivery.track_header')}
        subtitle={t('delivery.track_description')}
      />
      <TextInput
        label={t('delivery.track_label')}
        displayCharLimit={INPUT_MAX_LENGTH}
        multiline
        name="delivery_plan.track_record"
        inputRef={register({
          maxLength: INPUT_MAX_LENGTH,
        })}
        inputProps={{
          maxLength: INPUT_MAX_LENGTH,
        }}
        error={!!fieldErrors.delivery_plan?.track_record}
        helperText={fieldErrors.delivery_plan?.track_record?.message}
        disabled={isLoading}
      />

      <SectionHeader
        title={t('volunteering.header')}
        subtitle={t('volunteering.header_description')}
      />
      <div className={style.volunteeringContainer}>
        <RadioGroup
          items={[
            {
              label: 'No',
              value: 'false',
            },
            { label: 'Yes', value: 'true' },
          ]}
          name="has_volunteering_opportunity"
          label={t('volunteering.question')}
          row
          valueToString={valueToString}
          valueFromString={valueFromString}
          defaultValue={volunteeringOpportunity ? true : false}
        />
        {hasVolunteeringOpportunity && (
          <>
            <Controller
              name="has_insurance"
              control={control}
              rules={{ required: msgFieldRequired }}
              defaultValue={volunteeringOpportunity ? true : false}
              render={({ ref, onChange, value }) => (
                <Checkbox
                  inputRef={ref}
                  value={ref.current.checked}
                  checked={Boolean(value)}
                  error={!!fieldErrors.has_insurance}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  disabled={isLoading}
                  helperText={
                    !!fieldErrors.has_insurance ? t('volunteering.insurance_help_text') : ''
                  }
                >
                  <span className={classnames(!!fieldErrors.has_insurance && style.checkboxError)}>
                    {t('volunteering.insurance_checkbox')}
                  </span>
                </Checkbox>
              )}
            />
            <TextInput
              label={t('volunteering.number_of_volunteers')}
              name="volunteering_opportunity.number_of_volunteers"
              type="number"
              inputRef={register({ required: msgFieldRequired })}
              error={!!fieldErrors.volunteering_opportunity?.number_of_volunteers}
              helperText={fieldErrors.volunteering_opportunity?.number_of_volunteers?.message}
              disabled={isLoading}
            />
            <TextInput
              label={t('volunteering.description')}
              displayCharLimit={INPUT_MAX_LENGTH}
              multiline
              minRows={5}
              maxRows={10}
              name="volunteering_opportunity.description"
              inputRef={register({
                maxLength: INPUT_MAX_LENGTH,
                required: msgFieldRequired,
              })}
              inputProps={{
                maxLength: INPUT_MAX_LENGTH,
              }}
              error={!!fieldErrors.volunteering_opportunity?.description}
              helperText={fieldErrors.volunteering_opportunity?.description?.message}
              disabled={isLoading}
            />
          </>
        )}
      </div>
      {buttons}
    </form>
  );
};
