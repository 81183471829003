import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import dynamic from 'next/dynamic';
import { useSignIn, SignInVariables } from '@Hooks/Api/useSignIn';
import { SignInProps } from './SignIn';
import { useRouter } from '@Helpers/useRouter';
import { QueryParams, RoutingPaths } from '@App/paths';
import { useUserContext } from '@Contexts';
import { getJoinCampaignPath } from '@Helpers';
import { setFieldErrors } from '@Hooks/useSetFieldErrors';

/** TODO: Workaround for class name hydration issue between server and client */
const SignInNoSSR = dynamic<SignInProps>(
  () => import('./SignIn').then((mod) => mod.SignIn) as any,
  {
    ssr: false,
  },
);

export const SignInController = () => {
  const { t } = useTranslation('createAccount');
  const methods = useForm();
  const { register, handleSubmit, errors, setError } = methods;
  const { userData, isUserLoading } = useUserContext();
  const { push, params } = useRouter();
  const { signIn, response } = useSignIn();
  const { error, isError, loading } = response;
  const campaignCode = params[QueryParams.CAMPAIGN_CODE];

  const userLoaded = !isUserLoading && userData;

  useEffect(() => {
    if (userLoaded) {
      if (typeof campaignCode === 'string') {
        push(getJoinCampaignPath(campaignCode));
      } else {
        // Redirect if a user is logged in
        // Warning: we should not check for authToken.hasToken(), because it can be outdated!
        push(RoutingPaths.DASHBOARD);
      }
    }
  }, [userLoaded, push, campaignCode]);

  const onSubmit = useCallback(
    (data: SignInVariables) => {
      signIn(data);
    },
    [signIn],
  );

  useEffect(() => {
    if (isError) {
      setError('email', { message: t('errors.invalid_login') });
    }
    if (error?.field_errors && !loading) {
      setFieldErrors(
        error?.field_errors,
        // We're not expecting any non-field errors here
        () => {},
        loading,
      );
    }
  }, [isError, error, setError, loading, t]);

  return (
    <FormProvider {...methods}>
      <SignInNoSSR
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        fieldErrors={errors}
        isLoading={loading}
      />
    </FormProvider>
  );
};
