import { useEffect, useState } from 'react';
import { useGroupProfile } from '@Hooks/Api';

export const useFormData = (forceNoProfile = false, organizationName = '') => {
  const [fetchGroup, groupResponse] = useGroupProfile();
  const [skipGroupResponse, setSkipGroupResponse] = useState({
    data: {
      name: organizationName,
    },
    loading: false,
    isError: true,
    error: undefined,
  });

  useEffect(() => {
    if (!forceNoProfile) {
      fetchGroup();
    }
  }, [fetchGroup, forceNoProfile]);

  // If we forceNoProfile === true, then we're in creating mode, no need to fetch profile:
  useEffect(() => {
    setSkipGroupResponse({
      data: {
        name: organizationName,
      },
      loading: false,
      isError: true,
      error: undefined,
    });
  }, [organizationName]);

  return [forceNoProfile ? skipGroupResponse : groupResponse] as const;
};
