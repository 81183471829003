import { MouseEventHandler } from 'react';
import classnames from 'classnames';
import styles from './AFIcons.module.scss';

export interface AFRightIconProps {
  className?: string;
  onClick?: MouseEventHandler;
}

export const AFRightIcon = ({ className, onClick }: AFRightIconProps) => {
  return (
    <svg
      className={classnames(className, styles.afIcon)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        d="M0.000238057 27.7175L9.17604 18.5417L27.5234 18.5459L18.3476 9.37014L27.502 0.215759L55.0208 27.7346L27.5191 55.2363L18.3519 46.0691L27.5277 36.8933L9.17604 36.8933L0.000238057 27.7175Z"
        fill="currentColor"
      />
    </svg>
  );
};
