import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ContentModal, Loader } from '@Components';

import styles from '../CategoriesList/CategoriesListController.module.scss';
import { useTranslation } from 'react-i18next';
import { BeneficiaryPeopleTypesEnum } from '../types';
import { BeneficiaryList } from './BeneficiaryList';

interface BeneficiaryListControllerProps {
  name: string;
}

export const BeneficiaryListController = ({ name }: BeneficiaryListControllerProps) => {
  const { t } = useTranslation('socialValue');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { errors: fieldErrors } = useFormContext();

  const beneficiaryTypesValue: string[] | null | undefined = useWatch({
    name: 'beneficiaryTypesPeople',
  });
  const beneficiaryTypesCount = beneficiaryTypesValue?.length;

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <p
        className={styles.selectedCounter}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {t('beneficiaries_selected', { beneficiariesCount: beneficiaryTypesCount })}
      </p>
      {fieldErrors[name] && <p className={styles.errorMessage}>{fieldErrors[name]?.message}</p>}
      {/* We use keepMounted here to ensure the form field in this modal never leaves the DOM, as that removes the value from the form */}
      <ContentModal
        keepMounted
        isOpen={isModalOpen}
        onClose={closeModal}
        title={t('criteria.beneficiaries')}
      >
        <BeneficiaryList
          beneficiariesList={Object.values(BeneficiaryPeopleTypesEnum)}
          name={name}
          disabled={false}
          required={true}
        />
      </ContentModal>
    </>
  );
};
