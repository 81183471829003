import { useEffect, useState } from 'react';
import { useFunderProfile } from '@Hooks/Api';

export const useFormData = (forceNoProfile = false, organizationName = '') => {
  const [fetchFunding, fundingResponse] = useFunderProfile();
  const [skipFundingResponse, setSkipFundingResponse] = useState({
    data: {
      name: organizationName,
    },
    loading: false,
    isError: true,
    error: undefined,
  });

  useEffect(() => {
    if (!forceNoProfile) {
      fetchFunding();
    }
  }, [fetchFunding, forceNoProfile]);

  // If we forceNoProfile === true, then we're in creating mode, no need to fetch profile:
  useEffect(() => {
    setSkipFundingResponse({
      data: {
        name: organizationName,
      },
      loading: false,
      isError: true,
      error: undefined,
    });
  }, [organizationName]);

  return [forceNoProfile ? skipFundingResponse : fundingResponse] as const;
};
