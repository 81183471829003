import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { RoutingPaths } from '@App/paths';
import { Button, Link } from '@Components/common';
import isServer from '@Helpers/isServer';

import styles from './JoinCampaign.module.scss';
import { useRouter } from '@Helpers';

export enum JoinCampaignStateEnum {
  OK = 'OK',
  INVALID_CODE = 'INVALID_CODE',
  ALREADY_JOINED = 'ALREADY_JOINED',
  CAMPAIGN_EXPIRED = 'CAMPAIGN_EXPIRED',
  GENERAL = 'GENERAL',
}

interface JoinCampaignProps {
  pageState: JoinCampaignStateEnum;
  campaignName?: string;
  onJoinCampaign: () => void;
  onCancel: () => void;
  isJoiningCampaign?: boolean;
}

export const JoinCampaign: React.VFC<JoinCampaignProps> = ({
  pageState,
  campaignName,
  onJoinCampaign,
  onCancel,
  isJoiningCampaign,
}) => {
  const { t } = useTranslation('common');
  const { push } = useRouter();

  const goToDashboard = useCallback(() => {
    push(RoutingPaths.DASHBOARD_GROUP);
  }, [push]);

  const refreshPage = useCallback(() => {
    if (!isServer) {
      window.location.reload();
    }
  }, []);

  return (
    <div className={styles.container}>
      {pageState === JoinCampaignStateEnum.OK && (
        <>
          <h1 className={styles.header}>{t('campaign.join_header', { campaignName })}</h1>
          <div className={styles.buttonsWrapper}>
            <Button
              buttonType="tertiary"
              className={styles.button}
              onClick={onCancel}
              disabled={isJoiningCampaign}
            >
              {t('campaign.cancel')}
            </Button>
            <Button
              buttonType="primary"
              className={styles.button}
              onClick={onJoinCampaign}
              disabled={isJoiningCampaign}
            >
              {t('campaign.join_button')}
            </Button>
          </div>
        </>
      )}
      {pageState === JoinCampaignStateEnum.INVALID_CODE && (
        <>
          <h1 className={styles.header}>{t('campaign.join_invalid_code_header')}</h1>
          <p className={styles.text}>
            <Trans
              ns="common"
              i18nKey="campaign.join_invalid_code_text"
              components={[<Link secondary to="mailto:hello@actionfunder.org" />]}
            />
          </p>
          <div className={styles.buttonsWrapper}>
            <Button
              buttonType="tertiary"
              className={styles.button}
              onClick={goToDashboard}
              disabled={isJoiningCampaign}
            >
              {t('campaign.back_to_dashboard')}
            </Button>
          </div>
        </>
      )}
      {pageState === JoinCampaignStateEnum.ALREADY_JOINED && (
        <>
          <h1 className={styles.header}>{t('campaign.join_already_joined_header')}</h1>
          <p className={styles.text}>{t('campaign.join_already_joined_text')}</p>
          <div className={styles.buttonsWrapper}>
            <Button
              buttonType="tertiary"
              className={styles.button}
              onClick={goToDashboard}
              disabled={isJoiningCampaign}
            >
              {t('campaign.back_to_dashboard')}
            </Button>
          </div>
        </>
      )}
      {pageState === JoinCampaignStateEnum.CAMPAIGN_EXPIRED && (
        <>
          <h1 className={styles.header}>{t('campaign.join_campaign_expired_header')}</h1>
          <div className={styles.buttonsWrapper}>
            <Button
              buttonType="tertiary"
              className={styles.button}
              onClick={goToDashboard}
              disabled={isJoiningCampaign}
            >
              {t('campaign.back_to_dashboard')}
            </Button>
          </div>
        </>
      )}
      {pageState === JoinCampaignStateEnum.GENERAL && (
        <>
          <h1 className={styles.header}>{t('campaign.join_general_error_header')}</h1>
          <p className={styles.text}>
            <Trans
              ns="common"
              i18nKey="campaign.join_general_error_text"
              components={[<Link secondary to="mailto:hello@actionfunder.org" />]}
            />
          </p>
          <div className={styles.buttonsWrapper}>
            <Button
              buttonType="tertiary"
              className={styles.button}
              onClick={goToDashboard}
              disabled={isJoiningCampaign}
            >
              {t('campaign.back_to_dashboard')}
            </Button>
            <Button
              buttonType="primary"
              className={styles.button}
              onClick={refreshPage}
              disabled={isJoiningCampaign}
            >
              {t('campaign.join_general_error_button')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
