import { ThemeProvider } from '@material-ui/core/styles';
import dynamic from 'next/dynamic';
import { gkTheme } from './gkTheme';

/** TODO: Workaround for class name hydration issue between server and client */
const GreeneKingPageNoSSR = dynamic(
  () => import('./GreeneKingPage').then((mod) => mod.GreeneKingPage) as any,
  {
    ssr: false,
  },
);

export const GreeneKingPubsController = () => {
  return (
    <ThemeProvider theme={gkTheme}>
      <GreeneKingPageNoSSR />
    </ThemeProvider>
  );
};
