import React from 'react';
import styles from './ProfileCard.module.scss';
import { Grid } from '@material-ui/core';
import { Button, Tile } from '@Components/common';
import { Trans, useTranslation } from 'react-i18next';
import { Routes, RoutingPaths } from '@App/paths';
import { FundingProfileData, GroupProfileData, User, UserTypeEnum } from '@Types';
import { Divider, OrganizationAvatar, ProgressBar, Tooltip } from '@Components';
import { RiTeamFill, RiPencilFill, RiMapPin2Fill, RiQuestionLine } from 'react-icons/ri';
import { getOrganizationPagePath } from '@Helpers/getOrganizationPagePath';
import { useRouter } from '@Helpers/useRouter';
import { ProfileChecklist } from './Components/ProfileChecklist';

interface ProfileCardProps {
  profileData: GroupProfileData | FundingProfileData;
  userData: User;
  tooltipTitle: string;
  tooltipText: string;
}

export const ProfileCard = ({
  profileData,
  userData,
  tooltipTitle,
  tooltipText,
}: ProfileCardProps) => {
  const { t } = useTranslation('dashboard');
  const { push } = useRouter();
  const { avatar_thumbnail: avatarImage, name: orgName, city } = profileData;
  const {
    user_type: userType,
    progress: profileCompletionProgress,
    organization_id: organizationID,
  } = userData;

  const handleEditRedirect = () => {
    push(userType === UserTypeEnum.FUNDER ? Routes.FUNDER_PROFILE_EDIT : Routes.GROUP_PROFILE_EDIT);
  };
  const handleViewRedirect = () => {
    push(getOrganizationPagePath(Boolean(userType === UserTypeEnum.FUNDER), organizationID));
  };

  return (
    <Tile className={styles.container}>
      <Grid container direction="column">
        <Grid
          container
          direction="column"
          justifyContent="center"
          spacing={2}
          className={styles.userInfoContainer}
        >
          <Grid item className={styles.item}>
            <OrganizationAvatar avatarImage={avatarImage}>
              {<RiTeamFill size={40} />}
            </OrganizationAvatar>
          </Grid>
          <Grid item className={styles.item}>
            <h2 className={styles.name}>{orgName}</h2>
          </Grid>
          <Grid container className={styles.item} spacing={1}>
            <Grid item>
              <RiMapPin2Fill />
            </Grid>
            <Grid item>
              <span>{`${city}, UK`}</span>
            </Grid>
          </Grid>
        </Grid>
        <Divider small />
        {profileCompletionProgress < 100 ? (
          <Grid container direction="column" spacing={1}>
            <Grid item className={styles.rowContainer}>
              <span>{t('complete_profile')}</span>
              <Tooltip
                title={
                  <div className={styles.tooltipContent}>
                    <p>{tooltipTitle}</p>
                    <p>{tooltipText}</p>
                  </div>
                }
              >
                <div className={styles.helpIcon}>
                  <RiQuestionLine />
                </div>
              </Tooltip>
            </Grid>
            <Grid item className={styles.rowContainer}>
              <ProgressBar
                color="secondary"
                value={profileCompletionProgress}
                className={styles.progressBar}
              />
              <Trans
                ns="common"
                i18nKey="percentage_whole"
                values={{ percentage: profileCompletionProgress }}
              />
            </Grid>
            <Grid item>
              <ProfileChecklist profileData={profileData} userType={userType} />
            </Grid>
            <Divider small />
            <Grid item className={styles.item}>
              {userData.can_modify_organization_profile ? (
                <Button
                  buttonType="secondary"
                  onClick={handleEditRedirect}
                  startIcon={<RiPencilFill />}
                >
                  {t('edit')}
                </Button>
              ) : (
                <Button buttonType="secondary" onClick={handleViewRedirect}>
                  {t('view_profile')}
                </Button>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid item className={styles.item}>
            <Button buttonType="secondary" onClick={handleViewRedirect}>
              {t('view_profile')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Tile>
  );
};
