import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ProfileType } from '@Views/Profile/GroupProfile';

export const useProfileTypes = (prefix: string, types: string[]) => {
  const { t } = useTranslation();

  return useMemo(
    () => types.map((value) => ({ value, label: t(`${prefix}.${value}`) })) as ProfileType[],
    [prefix, t, types],
  );
};
