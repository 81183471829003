import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmModal, CreateActionButtons, Loader } from '@Components';
import { ProjectDetails } from '@Views/Project/ProjectDetails/ProjectDetails';
import { RoutingPaths } from '@App/paths';
import { useRouter } from '@Helpers/useRouter';
import { useSetFieldErrors } from '@Hooks';
import { useAddProjectDetails, useCreateProjectDetails, useGetProject } from '@Hooks/Api';
import { useNotificationsContext } from '@Contexts';
import {
  FieldErrors,
  ProjectDetails as ProjectDetailsType,
  PitchTypesEnum,
  ProjectStatusEnum,
} from '@Types';
import { useTranslation } from 'react-i18next';

interface ProjectDetailsProps {
  currentStep: number;
  stepsCount: number;
  nextStep: () => void;
  setProjectData: (
    id: number,
    grantAmount: number,
    isProjectEditable?: boolean,
    isProjectPublished?: boolean,
    isDeliveryCreated?: boolean,
    isProjectInitiallyPublished?: boolean,
  ) => void;
  projectId?: number;
  setPitchType: (pitchType: PitchTypesEnum | undefined) => void;
}

export const ProjectDetailsController: React.FC<ProjectDetailsProps> = ({
  currentStep,
  stepsCount,
  nextStep,
  setProjectData,
  projectId,
  setPitchType,
}) => {
  const { push } = useRouter();
  const methods = useForm();
  const { t } = useTranslation('project');
  const { handleSubmit, setError, reset, formState } = methods;
  const [
    createProjectDetails,
    { loading: isCreateProjectDetailsLoading, error: createProjectDetailsError },
  ] = useCreateProjectDetails();
  const [getProject, { loading: isGetProjectLoading, data: projectData }] = useGetProject();
  const [
    addProjectDetails,
    { loading: isAddProjectDetailsLoading, error: addProjectDetailsError },
  ] = useAddProjectDetails();
  const isFormSaving = isCreateProjectDetailsLoading || isAddProjectDetailsLoading;
  const { error: errorNotification, success: successNotification } = useNotificationsContext();

  useEffect(() => {
    if (projectId) {
      getProject(projectId)
        .then(({ data }) => {
          reset(data.details);
          setProjectData(
            data.id,
            Number(data.details.amount),
            data.user_can_edit,
            data.status !== ProjectStatusEnum.DRAFT,
            Boolean(data.delivery_plan),
            data.status !== ProjectStatusEnum.DRAFT,
          );
          setPitchType(
            (data.written_pitch && PitchTypesEnum.WRITTEN) ||
              (data.video_pitch && PitchTypesEnum.VIDEO) ||
              undefined,
          );
        })
        .catch(() => {
          /**
           * This function is used to fetch form data.
           * This catch prevents throwing an unhandled error and sending it to sentry,
           * if there is nothing to fetch (404)
           */
        });
    }
  }, [getProject, projectId, reset, setPitchType, setProjectData]);

  const onSubmit = useCallback(
    (data: ProjectDetailsType) => {
      // If the user hasn't made any changes but has data in the form
      // we don't need to make the update call
      if (!formState.isDirty && projectData) {
        nextStep();
      } else if (projectId) {
        addProjectDetails(projectId, { ...data, currency: 'GBP', is_sensitive: false }, true)
          .then(({ data }) => {
            setProjectData(projectId, Number(data.amount));
            successNotification(t('saved_step_1'));
            nextStep();
          })
          .catch(({ data }) => {
            if (data.detail[0]) {
              errorNotification(data.detail[0]);
            }
          });
      } else {
        createProjectDetails({ ...data, currency: 'GBP', is_sensitive: false })
          .then(({ data }) => {
            setProjectData(data.id, Number(data.details.amount));
            successNotification(t('saved_step_1'));
            nextStep();
          })
          .catch(({ data }) => {
            if (data.detail[0]) {
              errorNotification(data.detail[0]);
            }
          });
      }
    },
    [
      formState.isDirty,
      projectData,
      projectId,
      nextStep,
      addProjectDetails,
      setProjectData,
      successNotification,
      t,
      errorNotification,
      createProjectDetails,
    ],
  );

  useSetFieldErrors({
    fieldErrors: (projectId
      ? addProjectDetailsError?.field_errors
      : createProjectDetailsError?.field_errors?.details) as FieldErrors,
    setError,
    loading: isFormSaving,
  });

  const submit = handleSubmit(onSubmit);

  if (isGetProjectLoading) return <Loader />;

  return (
    <FormProvider {...methods}>
      <ProjectDetails
        isLoading={isFormSaving}
        onSubmit={submit}
        buttons={
          <CreateActionButtons
            currentStep={currentStep}
            stepsCount={stepsCount}
            nextButtonProps={{ buttonType: 'primary', onClick: submit, disabled: isFormSaving }}
            projectId={projectId}
          />
        }
      />
    </FormProvider>
  );
};
