import { Checkbox } from '@Components/common';
import { msgFieldRequired } from '@Helpers';
import { FormControl, FormGroup } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GROUP_TYPE_CHOICES, GROUP_TYPE_CHOICES_WITH_ALL } from '@Helpers/choices';
import styles from './NonProfitTypes.module.scss';
import { useCallback } from 'react';

export const NonProfitTypes = () => {
  const { t } = useTranslation('fund');
  const { control, errors: fieldErrors } = useFormContext();
  const fieldName = 'nonProfitTypes';
  const validationFunc = useCallback((value) => {
    const valueLength = value ? value.length : 0;
    return valueLength > 0 || msgFieldRequired;
  }, []);

  return (
    <div>
      <Controller
        name={fieldName}
        rules={{
          required: msgFieldRequired,
          validate: validationFunc,
        }}
        control={control}
        defaultValue={[]}
        render={({ ref, onChange, value }) => (
          <FormControl component="fieldset">
            <FormGroup>
              <Checkbox
                checked={value.includes('ALL')}
                onChange={(e) => {
                  let newArray: string[] = [];
                  if (e.target.checked) {
                    newArray = GROUP_TYPE_CHOICES_WITH_ALL;
                  } else {
                    newArray = [];
                  }
                  onChange(newArray);
                }}
                name={'All'}
              >
                All
              </Checkbox>
              {GROUP_TYPE_CHOICES.map((type, i) => {
                return (
                  <Checkbox
                    checked={value.includes(type)}
                    key={i}
                    onChange={(e) => {
                      let newArray = [];
                      if (e.target.checked) {
                        newArray = [...value, type];
                        if (newArray.length === 3) {
                          newArray.push('ALL');
                        }
                      } else {
                        newArray = value.filter(
                          (typeToCheck: string) => type !== typeToCheck && typeToCheck !== 'ALL',
                        );
                      }
                      onChange(newArray);
                    }}
                    name={type}
                  >
                    {t(`common:group_types:${type}`)}
                  </Checkbox>
                );
              })}
            </FormGroup>
          </FormControl>
        )}
      />
      {fieldErrors[fieldName] && (
        <p className={styles.errorMessage}>{fieldErrors[fieldName]?.message}</p>
      )}
    </div>
  );
};
