import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFound404, WelcomeBanner } from '@Components';
import { useUserContext } from '@Contexts';
import { FundStatusEnum, UserTypeEnum } from '@Types';
import styles from './FunderDashboard.module.scss';
import { useRouter } from '@Helpers';
import { Routes, RoutingPaths } from '@App/paths';
import { useFunderProfile, useMyFundsList } from '@Hooks/Api';
import { Placeholder } from '@Components/common/Placeholder/Placeholder';
import { HelpCenterCardController } from '@Components/HelpCenterCard/HelpCenterCardController';
import { ProfileCard } from '@Components/ProfileCard/ProfileCard';
import { FundsPreview } from '@Components/Funds/FundsPreview';
import { FunderDashboardTotalizers } from '../../Components/FunderDashboard/FunderDashboardTotalizers';
import { IncompleteProfileCard } from '@Components/ProfileCard/IncompleteProfileCard';
import { UpsellCard } from '@Components/UpsellCard/UpsellCard';
import { getCookie, setCookie } from '@Helpers/cookies';

export const FunderDashboard = () => {
  const { t } = useTranslation('dashboard');
  const { userData } = useUserContext();
  const [fetchFundsList, { loading: isFundsListLoading, data: fundsList }] = useMyFundsList();
  const { push } = useRouter();
  const [fetchFunderProfile, { loading: isProfileDataLoading, data: profileData }] =
    useFunderProfile();
  const [isWelcomeBannerVisible, setIsWelcomeBannerVisible] = useState<boolean>(
    getCookie('isWelcomeBannerHidden') !== 'true',
  );

  const welcomeBannerVideoURL = 'https://www.youtube.com/watch?v=yRf47tLvztM';

  const onCloseWelcomeBanner = useCallback(() => {
    setIsWelcomeBannerVisible(false);
    setCookie('isWelcomeBannerHidden', `true`, { expires: 90 });
  }, []);

  useEffect(() => {
    if (userData) {
      fetchFundsList([
        FundStatusEnum.LIVE,
        FundStatusEnum.CLOSED,
        FundStatusEnum.IN_DELIVERY,
        FundStatusEnum.COMPLETE,
      ]);
    }
  }, [fetchFundsList, userData]);

  useEffect(() => {
    fetchFunderProfile();
  }, [fetchFunderProfile]);

  if (userData?.user_type === UserTypeEnum.GROUP) {
    push(RoutingPaths.DASHBOARD_GROUP);
    return null;
  }

  if (!userData?.has_organization_profile && !isProfileDataLoading && !profileData) {
    push(Routes.FUNDER_PROFILE_CREATE);
    return null;
  }

  if (userData?.user_type !== UserTypeEnum.FUNDER) {
    return <NotFound404 />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        {isWelcomeBannerVisible && (
          <WelcomeBanner
            onClose={onCloseWelcomeBanner}
            title={t('funder.welcome_banner.title')}
            bodyText={t('funder.welcome_banner.text')}
            primaryButtonText={t('funder.welcome_banner.primary_button_text')}
            videoURL={welcomeBannerVideoURL}
            secondaryButtonText={t('funder.welcome_banner.secondary_button_text')}
            secondaryButtonLink={Routes.FUND_CREATE}
          />
        )}
        {!(userData.can_modify_organization_profile || (fundsList && fundsList.length > 0)) &&
          !isFundsListLoading && (
            <Placeholder heading={t('funder.no_access_heading')} text={t('funder.no_access')} />
          )}
        <FunderDashboardTotalizers organizationId={userData.organization_id} />
        <FundsPreview fundsList={fundsList} isFundsListLoading={isFundsListLoading} />
      </div>

      <div className={styles.sideBarContainer}>
        {profileData && userData ? (
          <ProfileCard
            profileData={profileData}
            userData={userData}
            tooltipTitle={t('funder.progress_tooltip_title')}
            tooltipText={t('funder.progress_tooltip_text')}
          />
        ) : (
          <IncompleteProfileCard userType={userData.user_type} />
        )}
        <UpsellCard />

        <HelpCenterCardController />
      </div>
    </div>
  );
};
