import {
  ApiErrorObject,
  Category,
  ExploreResults,
  InitialFocus,
  LatLngExpression,
  Map2Props,
} from '@Types';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ResultsPage.module.scss';
import { Button, Tile } from '@Components/common';
import dynamic from 'next/dynamic';
import { FeatureCollection, Geometry } from 'geojson';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import styleVariables from '@Styles/_variables.module.scss';
import { ProjectPopup } from '@Components/Map2/Popups';
import flip from '@turf/flip';
import { useTranslation } from 'react-i18next';
import { ContentModal, FilterModal, Loader, ProjectList, Totalizers } from '@Components';
import { RoutingPaths } from '@App/paths';
import { FeaturedMarker } from '@Components/Map2/Markers/FeaturedMarker';
import { useTimeout } from '@Hooks/useTimeout';

const Map2 = dynamic<Map2Props>(
  () => import('@Components/Map2/Map2').then((mod) => mod.Map2) as any,
  {
    ssr: false,
  },
);

interface ResultsPageProps {
  categoriesData: Category[] | undefined;
  postcode: string | null;
  setPostcode: (postcode: string | null) => void;
  category: number[] | null;
  setCategory: (category: number[] | null) => void;
  getMatches: (category: number[] | null, postcode: string | null) => void;
  previewProjectsData: ExploreResults | undefined;
  isMatchesPreviewLoading: boolean;
  numberOfRequests: number;
  numberOfRequestsCookie: number;
  errors?: ApiErrorObject;
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const ResultsPage = ({
  categoriesData,
  postcode,
  setPostcode,
  category,
  setCategory,
  getMatches,
  previewProjectsData,
  isMatchesPreviewLoading,
  numberOfRequests,
  numberOfRequestsCookie,
  errors,
  isFilterModalOpen,
  setIsFilterModalOpen,
}: ResultsPageProps) => {
  const {
    total_project_count,
    projects,
    limited_project_count,
    projects_map_details,
    community_group,
    beneficiaries,
    registered_charity,
    social_enterprise,
  } = previewProjectsData || {};
  const [newPostcode, setNewPostcode] = useState<string | null>(null);
  const [newCategory, setNewCategory] = useState<number[] | null>(null);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [featureCollection, setFeatureCollection] = useState<FeatureCollection | undefined>();
  const [refocus, setRefocus] = useState(0);
  const [circles, setCircles] = useState<
    | Array<{
        center: LatLngExpression;
        radius: number;
      }>
    | undefined
  >(undefined);
  const { t } = useTranslation('landingPage');
  const categoriesID = useMemo(
    () => categoriesData?.map((category) => category.id) || [],
    [categoriesData],
  );
  const maxNumberOfMarkers = 110;
  const totalNumberOfAllowedRequests = 2;
  const [popUpDelay, setPopUpDelay] = useState<number | null>(null);

  useEffect(() => {
    if (category) {
      setCurrentTab(1);
      getMatches(category, null);
    } else if (postcode) {
      getMatches(categoriesID, postcode);
    }
    // Complains categoriesID needs to be dependency but it
    // causes it to fire multiple times when searching by category.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: projects_map_details?.features.slice(0, maxNumberOfMarkers) || [],
    };
    setRefocus((refocus) => refocus + 1);

    if (previewProjectsData && previewProjectsData.initial_search_coords) {
      featureCollection.features.unshift({
        type: 'Feature',
        geometry: previewProjectsData.initial_search_coords.geometry as Geometry,
        properties: {
          marker: 'home',
          popup: null,
        },
      });

      setCircles([
        {
          center: flip(previewProjectsData.initial_search_coords.geometry)
            .coordinates as LatLngExpression,
          radius: 0,
        },
      ]);
    }
    featureCollection.features.forEach((feature) => {
      if (feature.properties && Object.keys(feature.properties).length === 0) {
        feature.properties = {
          marker: 'grey',
          popup: null,
        };
      }
    });

    setFeatureCollection(featureCollection);
  }, [previewProjectsData, projects_map_details?.features]);

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    if (numberOfRequests >= totalNumberOfAllowedRequests) {
      setPopUpDelay(35000);
    }
  }, [numberOfRequests]);

  const openFilter = () => {
    setIsFilterModalOpen(true);
  };

  useTimeout(() => {
    openFilter();
  }, popUpDelay);

  const handlePostcodeChange = useCallback(
    (event: any) => {
      setNewPostcode(event?.target.value);
      setNewCategory(categoriesID);
    },
    [categoriesID],
  );

  const valueFromString = (category: string) => {
    if (category) {
      setNewCategory([Number(category)]);
      setNewPostcode(null);
    }
    if (category == null || category === '') {
      return null;
    }
    return category;
  };

  const onSubmit = useCallback(() => {
    getMatches(newCategory, newPostcode);
    setPostcode(newPostcode);
    setCategory(newCategory);
  }, [getMatches, newCategory, newPostcode, setCategory, setPostcode]);

  if (isMatchesPreviewLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.resultsContainer}>
      <Tile className={styles.totalizers}>
        <Totalizers
          total_project_count={total_project_count}
          openFilter={openFilter}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          totals={[
            {
              ns: 'landingPage',
              i18nKey: 'totalizers.community_group',
              value: community_group || 0,
            },
            {
              ns: 'landingPage',
              i18nKey: 'totalizers.registered_charities',
              value: registered_charity || 0,
            },
            {
              ns: 'landingPage',
              i18nKey: 'totalizers.social_enterprise',
              value: social_enterprise || 0,
            },
            {
              ns: 'landingPage',
              i18nKey: 'totalizers.estimated_beneficiaries',
              value: beneficiaries || 0,
            },
          ]}
        />
      </Tile>
      <Tile className={styles.message}>
        <div className={styles.lockedFeatureContainer}>
          <img src="/assets/Explore/padlock.svg" alt="Padlock image" />
          <div className={styles.lockedFeatureText}>
            <h2>{t('locked_features.heading')}</h2>
            <p>{t('locked_features.subheading')}</p>
          </div>
        </div>
      </Tile>

      <Tile className={styles.projects}>
        <ProjectList
          projects={projects}
          total_project_count={total_project_count}
          limited_project_count={limited_project_count}
          postcode={postcode}
          category={category}
          categoriesData={categoriesData}
          openFilter={openFilter}
          numberOfRequests={numberOfRequests}
          totalNumberOfAllowedRequests={totalNumberOfAllowedRequests}
        />
      </Tile>
      <div className={styles.map}>
        <Map2
          circles={circles}
          height={800}
          initialFocus={
            (!isMatchesPreviewLoading &&
              featureCollection &&
              featureCollection.features.length > 0 &&
              InitialFocus.DATA) ||
            InitialFocus.UK_WIDE
          }
          refocus={refocus}
          featureCollection={featureCollection}
          markers={{
            home: {
              backgroundColor: styleVariables.AFNavyBlue500,
              component: <HomeWorkIcon htmlColor="white" />,
            },
            highlightedProject: {
              zIndexOffset: 1000,
              component: <FeaturedMarker />,
            },
            default: {
              leafletIconName: 'default',
            },
            grey: {
              leafletIconName: 'grey',
            },
          }}
          message={{
            content: t('project_count_warning'),

            visible:
              (featureCollection && featureCollection.features.length > maxNumberOfMarkers) ||
              false,
          }}
          popupFunctions={{
            default: ProjectPopup,
          }}
        />
      </div>
      <ContentModal isOpen={isFilterModalOpen} onClose={handleCloseFilterModal} wide>
        {numberOfRequestsCookie != null && numberOfRequestsCookie < totalNumberOfAllowedRequests ? (
          <FilterModal
            categoriesData={categoriesData}
            onSubmit={onSubmit}
            currentTab={currentTab}
            handlePostcodeChange={handlePostcodeChange}
            postcode={postcode}
            category={category}
            valueFromString={valueFromString}
            errors={errors}
            resultsLoading={isMatchesPreviewLoading}
          />
        ) : (
          <div className={styles.popupMaxSearchesContainer}>
            <h2>{t('popup_max_searches.title')}</h2>
            <p>{t('popup_max_searches.text')}</p>
            <Button buttonType="primary" to={RoutingPaths.REQUEST_DEMO} className={styles.demoBtn}>
              {t('book_a_demo')}
            </Button>
          </div>
        )}
      </ContentModal>
    </div>
  );
};
