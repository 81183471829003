import React, { useCallback, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EmojiPeople from '@material-ui/icons/EmojiPeople';

import {
  Loader,
  StatusChip,
  OldTabs,
  Tooltip,
  ManagementTile,
  Masonry,
  UpdatesListItem,
  FundResults,
  FieldNote,
  OldTabProps,
} from '@Components';
import { CopyToClipboard } from '@Components/CopyToClipboard/CopyToClipboard';
import {
  Match,
  Project,
  SelectionStatusEnum,
  Selection,
  ProjectUpdate,
  Fund,
  FundStatusEnum,
  FundVotingConfiguration,
} from '@Types';
import { getYoutubeThumbnailUrl } from '@Helpers/getYoutubeThumbnailUrl';
import { ApiErrorObject } from 'Types/Errors';

import { Progress } from './Progress';
import styles from './FundManagement.module.scss';
import { Button, Pagination } from '@Components/common';
import { useUserContext } from '@Contexts';
import { formatDate } from '@Helpers/formatDate';
import { RiPencilFill, RiEyeFill } from 'react-icons/ri';

import { VotingConfigurationModal } from './VotingConfigurationModal';
import { VotingLinkModal } from './VotingLinkModal';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';
import { Routes } from '@App/paths';
import { ArchiveFundButton } from '@Components/common/Button/ArchiveFundButton';

const MASONRY_COLUMNS = {
  default: 3,
  1279: 2,
  720: 1,
};

export enum VotingModalStatus {
  // We show the voting configuration modal, followed by the sharing link modal, but never both.
  // Use an enum to manage which one is open.
  'CONFIGURATION',
  'SHARING',
  'CLOSED',
}

export interface ProjectWithMatchData extends Project {
  matchId: number;
  inCampaign: boolean;
  isFunded: boolean;
  nominationCount: number;
}

interface FundManagementProps {
  fundId: number;
  fundName?: string;
  fundData: Fund;
  status?: string;
  fundAmount?: number;
  fundRemainingAmount?: number;
  tabs: OldTabProps[];
  currentTab: number;
  setCurrentTab: (tabIndex: number) => void;
  isMatchesListLoading: boolean;
  isSelectionsListLoading: boolean;
  shortlistedSelections?: Selection[];
  isHideUpdatesLoading: boolean;
  isFundedProjectsLoading: boolean;
  invitedSelections?: Selection[];
  fundedProjectsList: Project[];
  moveProjectToShortlist: (projectId: number, projectName: string) => Promise<void>;
  rejectMatchedProject: (matchId: number, projectName: string) => Promise<void>;
  removeProjectFromShortlist: (selectionId: number, projectName: string) => Promise<void>;
  inviteProjectToFund: (selectionId: number, projectName: string) => Promise<void>;
  hideProjectUpdate: (projectUpdateId: number) => Promise<void>;
  showVolunteeringOpportunityBanner?: boolean;
  onVotingConfigurationSubmit: (data: FundVotingConfiguration) => void;
  votingModalStatus: VotingModalStatus;
  setVotingModalStatus: React.Dispatch<React.SetStateAction<VotingModalStatus>>;
  votingConfigurationUpdateErrors?: ApiErrorObject;
  votingConfigurationUpdateLoading: boolean;
  updatesPaginatedResponse: PaginatedResponse<ProjectUpdate>;
  matchesPaginatedResponse: PaginatedResponse<Match>;
}

export const FundManagement: React.FC<FundManagementProps> = ({
  fundName,
  status,
  fundId,
  fundData,
  fundAmount,
  fundRemainingAmount,
  tabs,
  currentTab,
  setCurrentTab,
  isMatchesListLoading,
  isSelectionsListLoading,
  shortlistedSelections,
  isHideUpdatesLoading,
  isFundedProjectsLoading,
  invitedSelections,
  fundedProjectsList,
  moveProjectToShortlist,
  rejectMatchedProject,
  removeProjectFromShortlist,
  inviteProjectToFund,
  hideProjectUpdate,
  showVolunteeringOpportunityBanner,
  onVotingConfigurationSubmit,
  votingModalStatus,
  setVotingModalStatus,
  votingConfigurationUpdateErrors,
  votingConfigurationUpdateLoading,
  updatesPaginatedResponse,
  matchesPaginatedResponse,
}) => {
  const { t } = useTranslation('fund');
  const [loadingProjectId, setLoadingProjectId] = useState<number>();
  const { userData } = useUserContext();

  const { page: matchList } = matchesPaginatedResponse;

  const {
    loading: isUpdatesListLoading,
    page: updatesList,
    totalCount: fundProjectUpdatesTotalCount,
  } = updatesPaginatedResponse;

  const fundingRequestedShortlist =
    shortlistedSelections &&
    shortlistedSelections.reduce(
      (acc, curr) =>
        curr.status === SelectionStatusEnum.SHORTLISTED
          ? acc + Number(curr.project.details.amount)
          : acc,
      0,
    );

  const progressShortlist =
    fundingRequestedShortlist && typeof fundRemainingAmount === 'number'
      ? (fundingRequestedShortlist / fundRemainingAmount) * 100
      : 0;

  const fundingAllocated =
    typeof fundAmount === 'number' && typeof fundRemainingAmount === 'number'
      ? fundAmount - fundRemainingAmount
      : undefined;

  const fundingAccepted =
    invitedSelections &&
    invitedSelections.reduce(
      (acc, curr) =>
        curr.status === SelectionStatusEnum.INVITATION_ACCEPTED ||
        curr.status === SelectionStatusEnum.FUNDED
          ? acc + Number(curr.project.details.amount)
          : acc,
      0,
    );

  const progressAllocated = fundingAllocated && fundAmount && (fundingAllocated / fundAmount) * 100;
  const progressAccepted = fundingAccepted && fundAmount && (fundingAccepted / fundAmount) * 100;

  const createHandleMoveProjectToShortlist = useCallback(
    (projectId: number, projectName: string) => () => {
      setLoadingProjectId(projectId);
      moveProjectToShortlist(projectId, projectName).finally(() => {
        setLoadingProjectId(undefined);
      });
    },
    [moveProjectToShortlist],
  );

  const createHandleRejectMatchedProject = useCallback(
    (matchId: number, projectName: string, projectId: number) => () => {
      setLoadingProjectId(projectId);
      rejectMatchedProject(matchId, projectName).finally(() => {
        setLoadingProjectId(undefined);
      });
    },
    [rejectMatchedProject],
  );

  const createHandleRemoveProjectFromShortlist = useCallback(
    (selectionId: number, projectName: string, projectId: number) => () => {
      setLoadingProjectId(projectId);
      removeProjectFromShortlist(selectionId, projectName).finally(() => {
        setLoadingProjectId(undefined);
      });
    },
    [removeProjectFromShortlist],
  );

  const createHandleInviteProjectToFund = useCallback(
    (selectionId: number, projectName: string, projectId: number) => () => {
      setLoadingProjectId(projectId);
      inviteProjectToFund(selectionId, projectName).finally(() => {
        setLoadingProjectId(undefined);
      });
    },
    [inviteProjectToFund],
  );

  const canSeeTileButtons = fundData.user_can_manage;
  const userCanEdit =
    status === FundStatusEnum.DRAFT || status === FundStatusEnum.AWAITING_APPROVAL;
  const votingConfiguration = fundData?.voting_configuration;
  const userCanArchive = fundData?.user_can_archive;

  const votingEnabled =
    [FundStatusEnum.LIVE, FundStatusEnum.CLOSED].includes(fundData.status) &&
    userData?.selection_voting_enabled;

  const handleVotingModalClose = () => {
    setVotingModalStatus(VotingModalStatus.CLOSED);
  };

  return (
    <>
      <div className={styles.headerWrapper}>
        <div>
          <h1>{fundName}</h1>
          {status && (
            <StatusChip label={t(`dashboard:funder.fund_status.${status}`)} color="primary" />
          )}
        </div>
        <div className={styles.managementButtons}>
          {userCanEdit && (
            <Button
              buttonType="tertiary"
              to={Routes.FUND_EDIT(fundId)}
              startIcon={<RiPencilFill />}
            >
              {t('management.edit')}
            </Button>
          )}
          <Button buttonType="tertiary" to={Routes.FUND_DETAILS(fundId)} startIcon={<RiEyeFill />}>
            {t('management.view')}
          </Button>
          {userCanArchive && <ArchiveFundButton id={fundId} />}
        </div>
      </div>
      <OldTabs tabs={tabs} currentTab={currentTab} onTabChange={setCurrentTab} />
      {status == FundStatusEnum.LIVE || status == FundStatusEnum.CLOSED ? (
        <>
          <VotingConfigurationModal
            isOpen={votingModalStatus == VotingModalStatus.CONFIGURATION}
            onClose={handleVotingModalClose}
            votingConfiguration={votingConfiguration}
            onSubmit={onVotingConfigurationSubmit}
            votingConfigurationUpdateErrors={votingConfigurationUpdateErrors}
            votingConfigurationUpdateLoading={votingConfigurationUpdateLoading}
          />
          {votingConfiguration && (
            <VotingLinkModal
              isOpen={votingModalStatus == VotingModalStatus.SHARING}
              onClose={handleVotingModalClose}
              votingConfiguration={votingConfiguration}
            />
          )}

          {currentTab === 1 && (
            <Progress
              variant="determinate"
              progress={progressShortlist > 100 ? 100 : progressShortlist}
              className={styles.progressBox}
              danger={progressShortlist > 100}
            >
              {votingConfiguration && (
                <>
                  {!votingConfiguration.deadline_passed && (
                    <div className={styles.progressContent}>
                      <span className={styles.progressTitle}>
                        {t('management.voting_deadline')}
                      </span>
                      <div className={styles.progressAmountsWrapper}>
                        <span>{formatDate(votingConfiguration.deadline)}</span>
                      </div>
                    </div>
                  )}
                  <div className={styles.progressContent}>
                    <span className={styles.progressTitle}>
                      {votingConfiguration.deadline_passed ? (
                        <>{t('management.voting_finished')}</>
                      ) : (
                        <>{t('management.voting_in_progress')}</>
                      )}
                    </span>
                    <div className={styles.progressAmountsWrapper}>
                      <span className={classnames(styles.mainAmount, styles.mainAmountShortlist)}>
                        {votingConfiguration.total_votes}
                      </span>
                      <span>
                        <Trans
                          ns="fund"
                          i18nKey="management.voter_count"
                          components={[<b />]}
                          values={{ count: votingConfiguration.total_voters }}
                        />
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className={styles.progressContent}>
                <span className={styles.progressTitle}>
                  {t('management.progress_funding_requested')}:
                </span>
                <div className={styles.progressAmountsWrapper}>
                  <span
                    className={classnames(
                      styles.mainAmount,
                      styles.mainAmountShortlist,
                      progressShortlist > 100 && styles.mainAmountDanger,
                    )}
                  >
                    {t('common:currency_format', { amount: fundingRequestedShortlist })}
                  </span>
                  <span>
                    <Trans
                      ns="fund"
                      i18nKey="management.progress_out_of_shortlist"
                      components={[<b />]}
                      values={{ amount: fundRemainingAmount }}
                    />
                  </span>
                </div>
              </div>
            </Progress>
          )}
          {currentTab === 2 && (
            <Progress
              variant="buffer"
              progress={progressAccepted || 0}
              progressBuffer={progressAllocated}
              className={styles.progressBox}
            >
              <div className={styles.progressContent}>
                <span className={styles.progressTitle}>
                  {t('management.progress_funding_allocated')}:
                </span>
                <div className={styles.progressAmountsWrapper}>
                  <span
                    className={classnames(
                      styles.mainAmount,
                      styles.mainAmountInvited,
                      progressShortlist > 100 && styles.requestedAmountDanger,
                    )}
                  >
                    {t('common:currency_format', { amount: fundingAllocated })}
                  </span>
                  <span className={styles.amountAcceptedWrapper}>
                    <Trans
                      ns="fund"
                      i18nKey="management.progress_with_accepted"
                      components={[<b className={styles.amountAccepted} />]}
                      values={{ amount: fundingAccepted }}
                    />
                  </span>
                  <span>
                    <Trans
                      ns="fund"
                      i18nKey="management.progress_out_of_offered"
                      components={[<b />]}
                      values={{ amount: fundAmount }}
                    />
                  </span>
                </div>
              </div>
            </Progress>
          )}
        </>
      ) : (
        <>
          {currentTab === 0 && (
            <Progress
              variant="buffer"
              progress={progressAccepted || 0}
              progressBuffer={progressAccepted}
              className={styles.progressBox}
            >
              <div className={styles.progressContent}>
                <span className={styles.progressTitle}>
                  {t('management.progress_funding_allocated')}:
                </span>
                <div className={styles.progressAmountsWrapper}>
                  <span
                    className={classnames(
                      styles.mainAmount,
                      styles.mainAmountInvited,
                      progressShortlist > 100 && styles.requestedAmountDanger,
                    )}
                  >
                    {t('common:currency_format', { amount: fundingAllocated })}
                  </span>
                  <span className={styles.amountAcceptedWrapper}>
                    <Trans
                      ns="fund"
                      i18nKey="management.progress_with_accepted"
                      components={[<b className={styles.amountAccepted} />]}
                      values={{ amount: fundingAccepted }}
                    />
                  </span>
                  <span>
                    <Trans
                      ns="fund"
                      i18nKey="management.progress_out_of_offered"
                      components={[<b />]}
                      values={{ amount: fundAmount }}
                    />
                  </span>
                </div>
              </div>
            </Progress>
          )}
        </>
      )}
      <div className={styles.itemsContainer}>
        {status !== FundStatusEnum.COMPLETE && status !== FundStatusEnum.IN_DELIVERY ? (
          <>
            {currentTab === 0 &&
              (isMatchesListLoading ? (
                <Loader />
              ) : (
                <>
                  {showVolunteeringOpportunityBanner && (
                    <FieldNote
                      className={styles.highlightedInfo}
                      icon={<EmojiPeople style={{ fontSize: 18 }} />}
                    >
                      <Trans
                        ns="fund"
                        i18nKey="management.volunteeringMessage"
                        components={[
                          <a href="mailto:hello@actionfunder.org?subject=Upgrade%20request%20-%20employee%20engagement" />,
                        ]}
                      />
                    </FieldNote>
                  )}
                  {matchList && (
                    <>
                      {matchList.map(
                        ({
                          id: matchId,
                          in_campaign: inCampaign,
                          is_funded: isFunded,
                          group_nomination_count: nominationCount,
                          project: {
                            id,
                            details,
                            group_profile,
                            video_pitch,
                            written_pitch,
                            has_volunteering_opportunity,
                            user_can_view_volunteering_opportunities,
                          },
                        }) => (
                          <div key={id} className={styles.tileWrapper}>
                            <ManagementTile
                              projectId={id}
                              videoThumbnail={getYoutubeThumbnailUrl(video_pitch?.video_url_1)}
                              projectImage={written_pitch?.image}
                              projectName={details.name}
                              organizationName={group_profile.name}
                              location={details.city}
                              fundingNeeded={t(`common:currency_format`, {
                                amount: details.amount,
                              })}
                              positiveBtn={canSeeTileButtons}
                              positiveBtnText={t('management.shortlist_button')}
                              positiveBtnEndIcon={<ArrowForwardIcon />}
                              onPositiveBtnClick={createHandleMoveProjectToShortlist(
                                id,
                                details.name,
                              )}
                              negativeBtn={canSeeTileButtons}
                              hasVolunteeringOpportunity={has_volunteering_opportunity}
                              showVolunteering={user_can_view_volunteering_opportunities}
                              onNegativeBtnClick={createHandleRejectMatchedProject(
                                matchId,
                                details.name,
                                id,
                              )}
                              confirmNegativeAction
                              confirmNegativeActionContent={
                                <Trans
                                  ns="fund"
                                  i18nKey="management.confirm_reject_match"
                                  components={[<b />]}
                                  values={{ projectName: details.name }}
                                />
                              }
                              isActionLoading={loadingProjectId === id}
                              badge={
                                nominationCount > 0 || inCampaign
                                  ? t('management.campaign_badge')
                                  : undefined
                              }
                            />
                            {isFunded && (
                              <Tooltip
                                title={t('management.project_unavailable_tooltip') as string}
                              >
                                <div className={styles.tileOverlay}>
                                  <span>{t('management.project_unavailable')}</span>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        ),
                      )}
                      <Pagination paginatedResponse={matchesPaginatedResponse} />
                    </>
                  )}
                </>
              ))}
            {currentTab === 1 &&
              (isSelectionsListLoading ? (
                <Loader />
              ) : (
                <>
                  <div className={styles.shortlistTopRow}>
                    <b>
                      {t('management.shortlist_count', {
                        count: shortlistedSelections?.length || 0,
                      })}
                    </b>
                    {votingEnabled && (
                      <>
                        {votingConfiguration && !votingConfiguration.deadline_passed && (
                          <CopyToClipboard
                            buttonText={t('management.copy_voting_link')}
                            successText={t('management.copy_voting_link_success')}
                            valueToCopy={votingConfiguration.sharing_link}
                          />
                        )}
                        {(!votingConfiguration || votingConfiguration.is_editable) && (
                          <div>
                            <Button
                              buttonType="tertiary"
                              onClick={() => {
                                setVotingModalStatus(VotingModalStatus.CONFIGURATION);
                              }}
                            >
                              {votingConfiguration ? (
                                <>{t('management.view_voting_configuration')}</>
                              ) : (
                                <>{t('management.enable_voting')}</>
                              )}
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {shortlistedSelections &&
                    shortlistedSelections.map(
                      ({
                        id: selectionId,
                        status: selectionStatus,
                        in_campaign: inCampaign,
                        group_nomination_count: nominationCount,
                        vote_count: voteCount,
                        project: {
                          id,
                          details,
                          group_profile,
                          video_pitch,
                          written_pitch,
                          delivery_plan,
                          volunteering_opportunity,
                          user_can_view_volunteering_opportunities,
                        },
                      }) => (
                        <div key={id} className={styles.tileWrapper}>
                          <ManagementTile
                            projectId={id}
                            videoThumbnail={getYoutubeThumbnailUrl(video_pitch?.video_url_1)}
                            projectImage={written_pitch?.image}
                            projectName={details.name}
                            voteCount={voteCount}
                            showVotes={votingConfiguration != null}
                            organizationName={group_profile.name}
                            location={details.city}
                            fundingNeeded={t(`common:currency_format`, { amount: details.amount })}
                            positiveBtn={canSeeTileButtons}
                            positiveBtnText={t('management.offer_funding_button')}
                            positiveBtnStartIcon={<CheckCircleIcon />}
                            onPositiveBtnClick={createHandleInviteProjectToFund(
                              selectionId,
                              details.name,
                              id,
                            )}
                            confirmPositiveAction
                            confirmPositiveActionContent={
                              <Trans
                                ns="fund"
                                i18nKey="management.confirm_invite_to_fund"
                                components={[<b />]}
                                values={{ projectName: details.name }}
                              />
                            }
                            negativeBtn={canSeeTileButtons}
                            negativeBtnText={t('management.remove_from_shortlist_button')}
                            onNegativeBtnClick={createHandleRemoveProjectFromShortlist(
                              selectionId,
                              details.name,
                              id,
                            )}
                            deliveryPlan={delivery_plan}
                            selectionStatus={selectionStatus}
                            isActionLoading={loadingProjectId === id}
                            badge={
                              nominationCount > 0 || inCampaign
                                ? t('management.campaign_badge')
                                : undefined
                            }
                            hasVolunteeringOpportunity={!!volunteering_opportunity}
                            showVolunteering={user_can_view_volunteering_opportunities}
                          />
                          {selectionStatus === SelectionStatusEnum.UNAVAILABLE && (
                            <Tooltip title={t('management.project_unavailable_tooltip') as string}>
                              <div className={styles.tileOverlay}>
                                <span>{t('management.project_unavailable')}</span>
                              </div>
                            </Tooltip>
                          )}
                          {selectionStatus === SelectionStatusEnum.NO_LONGER_MATCHES && (
                            <Tooltip
                              title={t('management.selection_no_longer_matches_tooltip') as string}
                            >
                              <div className={styles.tileOverlay}>
                                <span>{t('management.selection_no_longer_matches')}</span>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      ),
                    )}
                </>
              ))}
            {currentTab === 2 &&
              (isSelectionsListLoading ? (
                <Loader />
              ) : (
                <>
                  <b>{t('management.offered_count', { count: invitedSelections?.length || 0 })}</b>
                  {invitedSelections &&
                    invitedSelections.map(
                      ({
                        status: selectionStatus,
                        in_campaign: inCampaign,
                        group_nomination_count: nominationCount,
                        project: {
                          id,
                          details,
                          group_profile,
                          video_pitch,
                          written_pitch,
                          volunteering_opportunity,
                          user_can_view_volunteering_opportunities,
                        },
                      }) => (
                        <ManagementTile
                          key={id}
                          projectId={id}
                          videoThumbnail={getYoutubeThumbnailUrl(video_pitch?.video_url_1)}
                          projectImage={written_pitch?.image}
                          projectName={details.name}
                          organizationName={group_profile.name}
                          location={details.city}
                          fundingNeeded={t(`common:currency_format`, { amount: details.amount })}
                          selectionStatus={selectionStatus}
                          withStatusChip
                          badge={
                            nominationCount > 0 || inCampaign
                              ? t('management.campaign_badge')
                              : undefined
                          }
                          hasVolunteeringOpportunity={!!volunteering_opportunity}
                          showVolunteering={user_can_view_volunteering_opportunities}
                        />
                      ),
                    )}
                </>
              ))}
          </>
        ) : (
          <>
            {currentTab === 0 &&
              (isFundedProjectsLoading || isUpdatesListLoading ? (
                <Loader />
              ) : (
                <>
                  {fundedProjectsList && (
                    <>
                      {fundedProjectsList.map(
                        ({
                          id,
                          details,
                          group_profile,
                          video_pitch,
                          written_pitch,
                          status,
                          update_count,
                        }) => (
                          <ManagementTile
                            key={id}
                            projectId={id}
                            videoThumbnail={getYoutubeThumbnailUrl(video_pitch?.video_url_1)}
                            projectImage={written_pitch?.image}
                            projectName={details.name}
                            organizationName={group_profile.name}
                            location={details.city}
                            fundingNeeded={t(`common:currency_format`, { amount: details.amount })}
                            status={status}
                            withStatusChip
                            updatesCount={update_count}
                          />
                        ),
                      )}
                    </>
                  )}
                </>
              ))}
            {currentTab === 1 &&
              (isUpdatesListLoading || isHideUpdatesLoading ? (
                <Loader />
              ) : (
                <>
                  <div className={styles.updatesBoxHeader}>
                    <div className={styles.updatesBoxTitle}>
                      <strong className={styles.updateTitle}>
                        {t('details_page.updates_header', {
                          updatesCount: fundProjectUpdatesTotalCount || 0,
                        })}
                      </strong>
                    </div>
                  </div>
                  {updatesList && updatesList.length > 0 && (
                    <>
                      <Masonry breakpointCols={MASONRY_COLUMNS}>
                        {updatesList.map(
                          ({
                            id,
                            image,
                            description,
                            created,
                            likes,
                            video_url,
                            organization_id,
                            organization_name,
                            avatar_thumbnail,
                            project_id,
                            project_name,
                          }) => (
                            <UpdatesListItem
                              key={id}
                              id={id}
                              image={image}
                              video={video_url}
                              description={description}
                              createdAt={created}
                              likes={likes}
                              likeCount={likes.length}
                              organizationId={organization_id}
                              organizationName={organization_name}
                              organizationAvatar={avatar_thumbnail}
                              projectId={project_id}
                              projectName={project_name}
                              onHideProjectUpdate={hideProjectUpdate}
                              user={userData}
                            />
                          ),
                        )}
                      </Masonry>
                      <Pagination paginatedResponse={updatesPaginatedResponse} />
                    </>
                  )}
                </>
              ))}
            {currentTab === 2 &&
              (isFundedProjectsLoading || isUpdatesListLoading ? (
                <Loader />
              ) : (
                <FundResults
                  fundId={fundId}
                  fundData={fundData}
                  fundedProjectsList={fundedProjectsList}
                  updatesPaginatedResponse={updatesPaginatedResponse}
                />
              ))}
          </>
        )}
      </div>
    </>
  );
};
