import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';

import { Button, Link } from '@Components/common';
import { QueryParams, RoutingPaths } from '@App/paths';
import { useRouter } from '@Helpers';
import isServer from '@Helpers/isServer';

import styles from './SignUpErrorModal.module.scss';

export enum SignUpErrorModalStateEnum {
  HIDDEN = 'HIDDEN',
  USER_TYPE = 'USER_TYPE',
  WRONG_CODE = 'WRONG_CODE',
  GENERAL = 'GENERAL',
}

interface SignUpErrorModalProps {
  modalState: SignUpErrorModalStateEnum;
  closeModal: () => void;
}

export const SignUpErrorModal: React.FC<SignUpErrorModalProps> = ({ modalState, closeModal }) => {
  const { t } = useTranslation('createAccount');
  const { params, push } = useRouter();

  const campaignCode = params[QueryParams.CAMPAIGN_CODE];

  const handleGoToCreateAffiliateGroup = useCallback(() => {
    push(`${RoutingPaths.SIGNUP_GROUP}?${QueryParams.CAMPAIGN_CODE}=${campaignCode}`);
    closeModal();
  }, [campaignCode, closeModal, push]);

  const handleGoToCreateFunder = useCallback(() => {
    push(RoutingPaths.SIGNUP_FUNDER);
    closeModal();
  }, [closeModal, push]);

  const handleGoToCreateGroup = useCallback(() => {
    push(RoutingPaths.SIGNUP_GROUP);
    closeModal();
  }, [closeModal, push]);

  const handleRefreshPage = useCallback(() => {
    if (!isServer) {
      window.location.reload();
    }
    closeModal;
  }, [closeModal]);

  return (
    <Modal open={modalState !== SignUpErrorModalStateEnum.HIDDEN} className={styles.container}>
      <div className={styles.modal}>
        {modalState === SignUpErrorModalStateEnum.USER_TYPE && (
          <>
            <h3 className={styles.title}>{t('signing.signup_error_modal.user_type_header')}</h3>
            <p className={styles.text}>
              <Trans
                ns="createAccount"
                i18nKey="signing.signup_error_modal.user_type_text"
                components={[
                  <Link
                    to={`${RoutingPaths.SIGNUP_GROUP}?${QueryParams.CAMPAIGN_CODE}=${campaignCode}`}
                    onClick={handleGoToCreateAffiliateGroup}
                    secondary
                  />,
                  <Link
                    to={RoutingPaths.SIGNUP_FUNDER}
                    onClick={handleGoToCreateFunder}
                    secondary
                  />,
                ]}
              />
            </p>
          </>
        )}
        {modalState === SignUpErrorModalStateEnum.WRONG_CODE && (
          <>
            <h3 className={styles.title}>{t('signing.signup_error_modal.wrong_code_header')}</h3>
            <p className={styles.text}>
              <Trans
                ns="createAccount"
                i18nKey="signing.signup_error_modal.wrong_code_text"
                components={[<Link href="mailto:hello@actionfunder.org" secondary />]}
              />
            </p>
            <div className={styles.buttonsWrapper}>
              <Button buttonType="tertiary" onClick={closeModal} className={styles.button}>
                {t('signing.signup_error_modal.close_button')}
              </Button>
              <Button
                buttonType="primary"
                className={styles.button}
                onClick={handleGoToCreateGroup}
              >
                {t('signing.signup_error_modal.wrong_code_button')}
              </Button>
            </div>
          </>
        )}
        {modalState === SignUpErrorModalStateEnum.GENERAL && (
          <>
            <h3 className={styles.title}>{t('signing.signup_error_modal.general_header')}</h3>
            <p className={styles.text}>
              <Trans
                ns="createAccount"
                i18nKey="signing.signup_error_modal.general_text"
                components={[<Link href="mailto:hello@actionfunder.org" secondary />]}
              />
            </p>
            <div className={styles.buttonsWrapper}>
              <Button buttonType="primary" className={styles.button} onClick={handleRefreshPage}>
                {t('signing.signup_error_modal.general_button')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
