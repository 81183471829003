/* A row of text appearing to explain the absence of a component, for example because there's no data to display */
import styles from './Placeholder.module.scss';

interface PlaceholderProps {
  heading: string;
  text: string;
}

export const Placeholder = ({ text, heading }: PlaceholderProps) => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.heading}>{heading}</h1>

      {text && <p className={styles.text}>{text}</p>}
    </div>
  );
};
