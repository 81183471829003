import { FormControl, InputAdornment, InputLabel, useMediaQuery } from '@material-ui/core';
import { CriteriaData } from './types';
import styles from './CriteriaColumn.module.scss';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { CategoriesListController } from './CategoriesList/CategoriesListController';
import { ApiErrorObject, DrawingMode } from '@Types';
import { useTranslation } from 'react-i18next';
import { SelectInput, TextInput } from '@Components/common';
import { msgFieldRequired } from '@Helpers';
import { VALIDATE_POSTCODE_REGEX } from '@Helpers/fieldValidation';
import { useSetFieldErrors } from '@Hooks/useSetFieldErrors';
import { Divider } from '@Components/Divider/Divider';
import { createMenuItems } from '@Components/MenuItems/MenuItems';
import { useTheme } from '@material-ui/core/styles';
import { NonProfitTypes } from './NonProfitTypes';
import { Autocomplete } from '@material-ui/lab';
import { Tooltip } from '@Components/Tooltip/Tooltip';
import { RiAddCircleFill, RiQuestionLine } from 'react-icons/ri';
import { BeneficiaryListController } from './BeneficiaryList/BeneficiaryListController';

export const CriteriaColumn = ({
  localAuthorities,
  resultsLoading,
  errors,
  minimumGrantAmountOpen,
  showMinimumGrantAmount,
}: {
  localAuthorities: { id: number; name: string }[];
  resultsLoading: boolean;
  errors?: ApiErrorObject;
  minimumGrantAmountOpen: boolean;
  showMinimumGrantAmount: () => any;
}) => {
  const { t } = useTranslation('socialValue');
  const { t: tCommon } = useTranslation('common');
  const { control, errors: fieldErrors } = useFormContext();

  const methods = useFormContext<CriteriaData>();

  useSetFieldErrors({
    fieldErrors: errors?.field_errors,
    setError: methods.setError,
    loading: resultsLoading,
  });

  const drawingMode = useWatch({ name: 'drawingMode' });

  const theme = useTheme();
  const screenSm = useMediaQuery(theme.breakpoints.down('sm'));
  const drawingModeItems = [
    {
      label: 'Radius',
      menuItemProps: {
        value: DrawingMode.RADIUS,
      },
    },
    {
      label: 'Local authority',
      menuItemProps: {
        value: DrawingMode.LOCAL_AUTHORITY,
      },
    },
    {
      label: 'UK wide',
      menuItemProps: {
        value: DrawingMode.UK_WIDE,
      },
    },
  ];
  // Don't include the 'draw an area' option on mobile for now.
  if (!screenSm) {
    drawingModeItems.splice(1, 0, {
      label: 'Draw an area',
      menuItemProps: {
        value: DrawingMode.POLYGON,
      },
    });
  }

  return (
    <form>
      <h3>{t('criteria.catchment_area')}</h3>
      <FormControl variant="outlined" className={styles.areaControlContainer}>
        <InputLabel id="drawingModeLabel">{t('criteria.drawing_mode')}</InputLabel>
        <Controller
          name="drawingMode"
          rules={{
            required: msgFieldRequired,
            validate: (val) => val !== -1 || msgFieldRequired,
          }}
          control={methods.control}
          render={({ ref, onChange, value }) => (
            <SelectInput
              id="drawingMode"
              label={t('criteria.drawing_mode')}
              labelId="drawingModeLabel"
              name="drawingMode"
              inputRef={ref}
              value={value}
              onChange={onChange}
              variant="outlined"
            >
              {createMenuItems({ items: drawingModeItems })}
            </SelectInput>
          )}
        />
      </FormControl>
      <div className={drawingMode !== DrawingMode.RADIUS ? styles.fieldHider : ''}>
        <TextInput
          label={'Postcode'}
          name="postcode"
          inputRef={methods.register({
            required: drawingMode === DrawingMode.RADIUS ? msgFieldRequired : false,
            pattern: {
              value: VALIDATE_POSTCODE_REGEX,
              message: tCommon('invalid_postcode'),
            },
          })}
          error={!!methods.errors.postcode}
          helperText={methods.errors.postcode?.message}
        />
      </div>
      {drawingMode == DrawingMode.LOCAL_AUTHORITY && (
        <Controller
          name="localAuthority"
          rules={{ required: msgFieldRequired }}
          control={control}
          render={({ ref, onChange, value }) => (
            <Autocomplete
              value={value}
              onChange={(_, val) => onChange(val)}
              options={localAuthorities.map((la) => la.id) || []}
              getOptionLabel={(option: number) =>
                localAuthorities.find((la) => la.id === option)?.name || ''
              }
              disableClearable
              disabled={false}
              renderInput={(params) => (
                <TextInput
                  label="Local authority"
                  type="text"
                  error={!!fieldErrors.localAuthority}
                  helperText={fieldErrors.localAuthority?.message}
                  inputRef={ref}
                  {...params}
                />
              )}
            />
          )}
        />
      )}
      <div className={drawingMode !== DrawingMode.RADIUS ? styles.fieldHider : ''}>
        <TextInput
          label={'Radius'}
          type="number"
          name="radius"
          inputRef={methods.register({
            required: drawingMode === DrawingMode.RADIUS ? msgFieldRequired : false,
          })}
          error={!!methods.errors.radius}
          helperText={methods.errors.radius?.message}
          InputProps={{
            endAdornment: <InputAdornment position="end">miles</InputAdornment>,
          }}
        />
      </div>
      <Divider small />
      <h3>{t('criteria.focus_areas')}</h3>
      <CategoriesListController name="focusAreas" />
      <Divider small />
      <h3>{t('criteria.beneficiaries')}</h3>
      <BeneficiaryListController name="beneficiaryTypesPeople" />
      <Divider small />
      <h3>{t('criteria.nonprofit_types')}</h3>
      <NonProfitTypes />
      <Divider small />
      <h3>{t('criteria.funding_amounts')}</h3>
      <div className={styles.tooltipRow}>
        <TextInput
          label={t('total_fund_amount', { currency: tCommon('global_currency') })}
          type="number"
          name="starting_amount"
          inputRef={methods.register()}
          error={!!fieldErrors.starting_amount}
          helperText={fieldErrors.starting_amount?.message}
        />
        <Tooltip
          disableFocusListener
          title={t('total_fund_amount_tooltip') as string}
          className={styles.tooltip}
        >
          <div>
            <RiQuestionLine />
          </div>
        </Tooltip>
      </div>
      <div className={styles.tooltipRow}>
        <TextInput
          className={styles.input}
          label={t('maximum_grant_amount', {
            currency: tCommon('global_currency'),
          })}
          inputRef={methods.register()}
          type="number"
          name="maximum_grant_amount"
          error={!!fieldErrors.maximum_grant_amount}
          helperText={fieldErrors.maximum_grant_amount?.message}
        />
        <Tooltip
          disableFocusListener
          title={t('maximum_grant_amount_tooltip') as string}
          className={styles.tooltip}
        >
          <div>
            <RiQuestionLine />
          </div>
        </Tooltip>
      </div>
      {minimumGrantAmountOpen ? (
        <div className={styles.tooltipRow}>
          <TextInput
            className={styles.input}
            label={t('minimum_grant_amount', {
              currency: tCommon('global_currency'),
            })}
            inputRef={methods.register({ required: false })}
            type="number"
            name="minimum_grant_amount"
            error={!!fieldErrors.minimum_grant_amount}
            helperText={fieldErrors.minimum_grant_amount?.message}
          />
          <Tooltip
            disableFocusListener
            title={t('minimum_grant_amount_tooltip') as string}
            className={styles.tooltip}
          >
            <div>
              <RiQuestionLine />
            </div>
          </Tooltip>
        </div>
      ) : (
        <div className={styles.showMinimumGrantAmount} onClick={showMinimumGrantAmount}>
          <RiAddCircleFill className={styles.showIcon} /> {t('show_minimum_grant_amount')}
        </div>
      )}
      <TextInput
        label={t('fund_name')}
        name="name"
        inputRef={methods.register()}
        error={!!fieldErrors.name}
        helperText={fieldErrors.name?.message}
      />
    </form>
  );
};
