import { Dispatch, SetStateAction, useEffect } from 'react';

import { Loader } from '@Components';
import { useCategories, useFunderProfile, useGetLocalAuthorities } from '@Hooks/Api';
import { FundCriteria } from './FundCriteria';
import { Fund } from '@Types';

export interface FundCriteriaControllerProps {
  fundData?: Fund;
  fundDataLoading: boolean;
  nextStep: () => void;
  setFundId: (id: number) => void;
}

export const FundCriteriaController = ({
  fundData,
  fundDataLoading,
  nextStep,
  setFundId,
}: FundCriteriaControllerProps) => {
  const [fetchCategories, { data: categoriesData, loading: isCategoriesLoading }] =
    useCategories(true);
  const [fetchFunding, { data: fundingProfile, loading: isFundingProfileLoading }] =
    useFunderProfile();
  const [getLocalAuthorities, { data: localAuthorities, loading: isLocalAuthoritiesLoading }] =
    useGetLocalAuthorities();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    getLocalAuthorities();
  }, [getLocalAuthorities]);

  useEffect(() => {
    if (fundData == null && !fundDataLoading) {
      // If we're not using the fund details, we'll default to using the profile values.
      fetchFunding();
    }
  }, [fetchFunding, fundData, fundDataLoading]);

  if (
    isCategoriesLoading ||
    !categoriesData ||
    isFundingProfileLoading ||
    fundDataLoading ||
    isLocalAuthoritiesLoading
  ) {
    return <Loader />;
  }

  return (
    <FundCriteria
      fundId={fundData?.id}
      fundDetails={fundData?.details}
      localAuthorities={localAuthorities || []}
      originalCategories={categoriesData.map((category) => category.id)}
      originalPostcode={
        // TODO-MAPS
        // @ts-ignore
        fundData?.details ? fundData.details.postcode : fundingProfile.postcode
      }
      nextStep={nextStep}
      setFundId={setFundId}
    />
  );
};
