import { AFRightUpIcon } from '@Components/Icons/AFRightUpIcon';
import styles from './FundName.module.scss';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export interface FundNameProps {
  name: string;
  status: string;
}

export const FundName = ({ name, status }: FundNameProps) => {
  const { t } = useTranslation('fund');

  return (
    <div className={styles.container}>
      <h3 className={styles.name}>{name}</h3>

      <div className={classnames(styles[status], styles.statusContainer)}>
        <AFRightUpIcon className={classnames(styles[status], styles.icon)} />
        {t(`fund_status.${status}`)}
      </div>
    </div>
  );
};
