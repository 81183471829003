import { Tile } from '@Components/common';
import React, { ReactElement } from 'react';
import styles from './TotalizerCard.module.scss';
import { Tooltip } from '@Components/Tooltip/Tooltip';
import { RiQuestionLine } from 'react-icons/ri';
import { TFunctionResult } from 'i18next';

export interface TotalizerProps {
  title: string;
  icon: ReactElement;
  amount: string | number | TFunctionResult;
  tooltipText?: string;
}

export interface TotalizerCardProps {
  totalizer: TotalizerProps;
}

export const TotalizerCard = ({ totalizer }: TotalizerCardProps) => {
  const { title, amount, icon, tooltipText } = totalizer;

  return (
    <Tile className={styles.container}>
      <>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{title}</h3>
          {tooltipText && (
            <Tooltip title={tooltipText}>
              <div className={styles.helpIcon}>
                <RiQuestionLine />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={styles.amountContainer}>
          {icon}
          <p className={styles.amount}>{amount}</p>
        </div>
      </>
    </Tile>
  );
};
