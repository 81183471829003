import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@Components/common';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import styles from './OpenDraftModal.module.scss';
import { useMyFundsList } from '@Hooks/Api';
import { ContentModal } from '@Components/ContentModal/ContentModal';
import { Loader } from '@Components/Loader/Loader';
import { FundStatusEnum } from '@Types';

interface OpenDraftModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (fundId: number) => void;
}

export const OpenDraftModal = ({ isOpen, onClose, onSelect }: OpenDraftModalProps) => {
  const { t } = useTranslation('socialValue');
  const { t: tCommon } = useTranslation('common');

  const [fetchFundsList, { loading: isFundsListLoading, data: fundsList }] = useMyFundsList();

  useEffect(() => {
    fetchFundsList([FundStatusEnum.DRAFT]);
  }, [fetchFundsList]);

  return (
    <ContentModal isOpen={isOpen} onClose={onClose}>
      {isFundsListLoading || fundsList == null ? (
        <Loader />
      ) : (
        <>
          <div className={styles.header}>
            <h2 className={styles.title}>{t('load_draft_modal.title')}</h2>
          </div>
          <div className={styles.content}>
            {fundsList.length == 0 ? (
              <p>{t('load_draft_modal.empty')}</p>
            ) : (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('load_draft_modal.name')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fundsList.map((draft) => (
                      <TableRow key={draft.id}>
                        <TableCell>{draft.details.name || 'Unnamed draft'}</TableCell>
                        <TableCell>
                          <Button
                            key={draft.id}
                            buttonType="primary"
                            onClick={() => {
                              onSelect(draft.id);
                            }}
                          >
                            {t('load_draft_modal.use')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </div>
        </>
      )}
      <div className={styles.footer}>
        <Button buttonType="tertiary" onClick={onClose}>
          {tCommon('cancel')}
        </Button>
      </div>
    </ContentModal>
  );
};
