import { useCallback, useState, useEffect } from 'react';
import { ErrorOption, useForm, FormProvider } from 'react-hook-form';

import { Organization, UserTypeEnum } from '@Types';
import { useSetFieldErrors } from '@Hooks';
import { useSignUp, SignUpVariables } from '@Hooks/Api';
import { useRouter } from '@Helpers/useRouter';
import { QueryParams, RoutingPaths } from '@App/paths';
import * as authToken from '@Helpers/authToken';

import { SignUpProps } from './SignUp';
import { SignUpErrorModal, SignUpErrorModalStateEnum } from './SignUpErrorModal';
import dynamic from 'next/dynamic';
import { useOrganizationLookup } from '@Hooks/useOrganizationLookup';
import { DuplicateOrganizationWarningModal } from './DuplicateOrganizationWarningModal';

/** TODO: Workaround for class name hydration issue between server and client */
const SignUpNoSSR = dynamic<SignUpProps>(
  () => import('./SignUp').then((mod) => mod.SignUp) as any,
  {
    ssr: false,
  },
);

interface SignUpControllerProps {
  userType: UserTypeEnum | undefined;
  nominationToken?: string;
  defaultOrganizationName?: string;
  defaultEmail?: string;
}

export const SignUpController = ({
  userType,
  nominationToken,
  defaultOrganizationName,
  defaultEmail,
}: SignUpControllerProps) => {
  const { push, params } = useRouter();
  const methods = useForm({
    defaultValues: {
      organisation_name: defaultOrganizationName,
      email: defaultEmail,
    },
  });
  const { register, handleSubmit, errors, setError: setErrorWronglyTyped, control } = methods;
  // react-hook-form has a typing bug where setting defaultValues in useForm incorrectly changes the type of setError,
  // restricting the allowed error fields to just those with default values.
  // As such, we need to cast it back to the correct type.
  const setError = setErrorWronglyTyped as (name: string, error: ErrorOption) => void;
  const { signUp, response } = useSignUp();
  const { error, loading } = response;
  const [errorModalState, setErrorModalState] = useState(SignUpErrorModalStateEnum.HIDDEN);
  const campaignCode = params[QueryParams.CAMPAIGN_CODE];
  const { organizationLookup } = useOrganizationLookup();
  const [duplicateOrganizations, setDuplicateOrganizations] = useState<Organization[]>([]);
  const [submittedData, setSubmittedData] = useState<SignUpVariables | null>(null);
  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  useEffect(() => {
    if (campaignCode && userType === UserTypeEnum.FUNDER) {
      setErrorModalState(SignUpErrorModalStateEnum.USER_TYPE);
    }
  }, [campaignCode, userType]);

  const closeErrorModal = useCallback(() => {
    setErrorModalState(SignUpErrorModalStateEnum.HIDDEN);
  }, []);

  const rawSubmit = useCallback(
    (data: SignUpVariables) => {
      signUp({
        ...data,
        nomination: nominationToken,
        campaign_affiliate_code: typeof campaignCode === 'string' ? campaignCode : undefined,
      })
        .catch((err) => {
          if (
            err?.data?.code === 'CAMPAIGN_DOES_NOT_EXIST' ||
            // TODO: Handle expired campaigns separately
            err?.data?.code === 'CAMPAIGN_HAS_ENDED'
          ) {
            setErrorModalState(SignUpErrorModalStateEnum.WRONG_CODE);
          } else if (!Object.keys(err?.data?.field_errors)?.length) {
            setErrorModalState(SignUpErrorModalStateEnum.GENERAL);
          }
          setButtonsEnabled(true);
        })
        .then(() => {
          setButtonsEnabled(true);
        });
    },
    [campaignCode, signUp, nominationToken],
  );

  const submitFromModal = useCallback(() => {
    setDuplicateOrganizations([]);
    if (submittedData) {
      rawSubmit(submittedData);
    }
  }, [rawSubmit, setDuplicateOrganizations, submittedData]);

  const onSubmit = useCallback(
    (data: SignUpVariables) => {
      setButtonsEnabled(false);
      const organizationName = data.organisation_name;
      organizationLookup(organizationName).then((result) => {
        const duplicates = result.data;
        if (duplicates.length > 0) {
          // We need to warn the user about possible duplicate organisations before submitting.
          // Store the data we submitted so it can be submitted after the warning modal is accepted.
          setSubmittedData(data);
          setDuplicateOrganizations(duplicates);
        } else {
          rawSubmit(data);
        }
      });
    },
    [setSubmittedData, setDuplicateOrganizations, organizationLookup, rawSubmit],
  );

  const closeDuplicatesModal = useCallback(() => {
    setButtonsEnabled(true);
    setDuplicateOrganizations([]);
  }, [setDuplicateOrganizations]);

  useSetFieldErrors({ fieldErrors: error?.field_errors, setError, loading });

  if (authToken.hasToken()) {
    push(RoutingPaths.DASHBOARD);
    return null;
  }

  return (
    <FormProvider {...methods}>
      <SignUpNoSSR
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        fieldErrors={errors}
        userType={userType}
        isLoading={loading}
        buttonsEnabled={buttonsEnabled}
      />
      <SignUpErrorModal modalState={errorModalState} closeModal={closeErrorModal} />
      <DuplicateOrganizationWarningModal
        organizations={duplicateOrganizations}
        submit={submitFromModal}
        close={closeDuplicatesModal}
      />
    </FormProvider>
  );
};
