import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@Components';
import {
  useCreateSelection,
  useGetFundData,
  useGetFundMatches,
  useGetFundSelections,
  useRejectMatch,
  useDeleteSelection,
  useFundInvitesProject,
  useGetFundProjectUpdates,
  useHideProjectUpdate,
} from '@Hooks/Api';
import { useGetAllFundedProjects } from '@Hooks/Api/useGetAllFundedProjects';
import { useUpdateFundVotingConfiguration } from '@Hooks/Api/useUpdateFundVotingConfiguration';
import { useRouter } from '@Helpers/useRouter';
import { PathParams } from '@App/paths';
import { FundStatusEnum, FundVotingConfiguration, SelectionStatusEnum, Selection } from '@Types';
import { useNotificationsContext } from '@Contexts';

import { FundManagement, VotingModalStatus } from './FundManagement';
import router from 'next/router';
import { useGetCurrentTab } from '@Hooks/useGetCurrentTab';

const SHORTLIST_TAB_STATUSES = [
  SelectionStatusEnum.SHORTLISTED,
  SelectionStatusEnum.UNAVAILABLE,
  SelectionStatusEnum.NO_LONGER_MATCHES,
];

export const FundManagementController = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [getFundData, { loading: isFundDataLoading, data: fundData }] = useGetFundData();
  const [
    getFundedProjects,
    {
      data: fundedProjects,
      loading: isFundedProjectsLoading,
      // We need to fetch every project for the reporting components, as they do aggregates over these projects.
    },
  ] = useGetAllFundedProjects();
  const totalProjectsCount = fundedProjects?.results.length;
  const [getFundMatches, { paginatedData: matchesPaginatedResponse }] = useGetFundMatches();
  const {
    page: matchList,
    loading: isFundMatchesLoading,
    totalCount: totalMatchesCount,
  } = matchesPaginatedResponse;
  const [getFundSelections, { loading: isFundSelectionsLoading, data: fundSelectionsData }] =
    useGetFundSelections();
  const [getFundProjectUpdates, { paginatedData: updatesPaginatedResponse }] =
    useGetFundProjectUpdates();
  const { loading: isFundUpdatesLoading, totalCount: fundProjectUpdatesTotalCount } =
    updatesPaginatedResponse;
  const [createSelection] = useCreateSelection();
  const [rejectMatch] = useRejectMatch();
  const [deleteSelection] = useDeleteSelection();
  const [fundInvitesProject] = useFundInvitesProject();
  const [hideUpdate, { loading: isHideUpdatesLoading }] = useHideProjectUpdate();
  const [
    updateFundVotingConfiguration,
    { error: votingConfigurationUpdateErrors, loading: votingConfigurationUpdateLoading },
  ] = useUpdateFundVotingConfiguration();
  const { params } = useRouter();
  const fundId = Number(params[PathParams.FUND_ID]);
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const [votingModalStatus, setVotingModalStatus] = useState<VotingModalStatus>(
    VotingModalStatus.CLOSED,
  );
  const { t } = useTranslation('fund');

  useEffect(() => {
    if (fundId) {
      setIsInitialized(true);
      getFundData(fundId);
    }
  }, [getFundData, fundId]);

  useEffect(() => {
    if (fundId) {
      getFundMatches(fundId);
    }
  }, [fundId, getFundMatches]);

  useEffect(() => {
    if (fundId) {
      getFundSelections(fundId);
    }
  }, [fundId, getFundSelections]);

  useEffect(() => {
    if (fundId) {
      getFundProjectUpdates(fundId);
    }
  }, [fundId, getFundProjectUpdates]);

  useEffect(() => {
    getFundedProjects({ fundId });
  }, [fundId, getFundedProjects]);

  const moveProjectToShortlist = useCallback(
    (projectId: number, projectName: string) =>
      createSelection(fundId, projectId)
        .then(() => {
          successNotification(t('management.add_to_shortlist_success', { projectName }));
          getFundMatches(fundId);
          getFundSelections(fundId);
        })
        .catch((err) => {
          errorNotification(
            err?.data?.detail[0] || t('management.add_to_shortlist_fail', { projectName }),
          );
        }),
    [
      createSelection,
      errorNotification,
      fundId,
      getFundMatches,
      getFundSelections,
      successNotification,
      t,
    ],
  );

  const rejectMatchedProject = useCallback(
    (matchId: number, projectName: string) =>
      rejectMatch(matchId)
        .then(() => {
          successNotification(t('management.reject_match_success', { projectName }));
          getFundMatches(fundId);
        })
        .catch((err) => {
          errorNotification(
            err?.data?.detail[0] || t('management.reject_match_fail', { projectName }),
          );
        }),
    [errorNotification, fundId, getFundMatches, rejectMatch, successNotification, t],
  );

  const removeProjectFromShortlist = useCallback(
    (selectionId: number, projectName: string) =>
      deleteSelection(fundId, selectionId)
        .then(() => {
          successNotification(
            t('management.remove_project_from_shortlist_success', { projectName }),
          );
          getFundMatches(fundId);
          getFundSelections(fundId);
        })
        .catch((err) => {
          errorNotification(
            err?.data?.detail[0] ||
              t('management.remove_project_from_shortlist_fail', { projectName }),
          );
        }),
    [
      deleteSelection,
      errorNotification,
      fundId,
      getFundMatches,
      getFundSelections,
      successNotification,
      t,
    ],
  );

  const hideProjectUpdate = useCallback(
    (projectUpdateId: number) =>
      hideUpdate(projectUpdateId)
        .then(() => {
          successNotification(t('projectUpdate:hide_update_success'));
          getFundProjectUpdates(fundId);
        })
        .catch((err) => {
          errorNotification(err?.data?.detail[0] || t('projectUpdate:hide_update_fail'));
        }),
    [errorNotification, fundId, getFundProjectUpdates, hideUpdate, successNotification, t],
  );

  const inviteProjectToFund = useCallback(
    (selectionId: number, projectName: string) =>
      fundInvitesProject(fundId, selectionId)
        .then(() => {
          successNotification(t('management.invite_project_to_fund_success', { projectName }));
          getFundData(fundId);
          getFundSelections(fundId);
        })
        .catch((err) => {
          errorNotification(
            err?.data?.detail[0] || t('management.invite_project_to_fund_fail', { projectName }),
          );
        }),
    [
      errorNotification,
      fundId,
      fundInvitesProject,
      getFundData,
      getFundSelections,
      successNotification,
      t,
    ],
  );

  // if a project in the matched list has a volunteering opportunity
  // BUT the funder doesnt have access to see it, display the banner
  const showVolunteeringOpportunityBanner: boolean | undefined = matchList.some(
    (match) =>
      match.project.has_volunteering_opportunity &&
      !match.project.user_can_view_volunteering_opportunities,
  );

  let shortlistedSelections: Selection[] = [];
  let invitedSelections: Selection[] = [];

  fundSelectionsData.forEach((selection) => {
    if (SHORTLIST_TAB_STATUSES.find((el) => el === selection.status)) {
      shortlistedSelections.push(selection);
    } else {
      invitedSelections.push(selection);
    }
  });

  if (fundData?.voting_configuration) {
    // We receive all the selections for all tabs in one batch from the backend,
    // so if we need the shortlist sorted by vote count for users with voting enabled,
    // we need to do it ourselves.
    shortlistedSelections = shortlistedSelections.sort((selection1, selection2) => {
      return (selection2.vote_count || 0) - (selection1.vote_count || 0);
    });
  }

  const tabs = useMemo(
    () => [
      ...(fundData?.status !== FundStatusEnum.COMPLETE &&
      fundData?.status !== FundStatusEnum.IN_DELIVERY
        ? [
            {
              title: t('management.tabs.matches', {
                matchesCount: isFundMatchesLoading ? '...' : totalMatchesCount,
              }),
              slug: 'matches',
            },
            {
              title: t('management.tabs.shortlist', {
                shortlistCount: isFundSelectionsLoading ? '...' : shortlistedSelections?.length,
              }),
              slug: 'shortlist',
            },
            {
              title: t('management.tabs.invitations', {
                invitationsCount: invitedSelections?.length || 0,
              }),
              slug: 'invitations',
            },
          ]
        : [
            {
              title: t('management.tabs.funded_projects', {
                fundedProjectsCount: isFundedProjectsLoading ? '...' : totalProjectsCount,
              }),
              slug: 'funded-projects',
            },
            {
              title: t('management.tabs.updates', {
                updatesCount: isFundUpdatesLoading ? '...' : fundProjectUpdatesTotalCount,
              }),
              slug: 'updates',
            },
            { title: `${t('management.tabs.results')}`, slug: 'results' },
          ]),
    ],
    [
      fundData?.status,
      fundProjectUpdatesTotalCount,
      totalProjectsCount,
      invitedSelections?.length,
      isFundMatchesLoading,
      isFundSelectionsLoading,
      isFundUpdatesLoading,
      isFundedProjectsLoading,
      totalMatchesCount,
      shortlistedSelections?.length,
      t,
    ],
  );

  // Set tab based on router and update modal state
  let currentTab = useGetCurrentTab(params.fundManageSlug?.[0], tabs);

  const setTab = useCallback(
    (tabNumber: number) => {
      return router.push(`/fund/${fundId}/manage/${tabs[tabNumber].slug}`, undefined, {
        shallow: true,
      });
    },
    [fundId, tabs],
  );

  const onVotingConfigurationSubmit = useCallback(
    (data: FundVotingConfiguration) => {
      const isCreating = fundData?.voting_configuration == null;
      const fixedData = {
        ...data,
        // react-hook-form doesn't let us use null as a value, so replace ""
        // with null before sending to the server.
        maximum_votes_per_user: data.maximum_votes_per_user || null,
      };
      updateFundVotingConfiguration(fundId, fixedData, fundData?.voting_configuration?.id).then(
        () => {
          // Refetch fund data (including voting configuration) to update the page.
          getFundData(fundId);
          if (isCreating) {
            // After creation, we show the sharing link modal.
            setVotingModalStatus(VotingModalStatus.SHARING);
          } else {
            setVotingModalStatus(VotingModalStatus.CLOSED);
          }
        },
      );
    },
    [fundId, fundData, getFundData, updateFundVotingConfiguration],
  );

  if (isFundDataLoading || !isInitialized) return <Loader />;

  return (
    <>
      {fundData && (
        <FundManagement
          fundId={fundId}
          fundName={fundData.details.name}
          fundData={fundData}
          status={fundData.status}
          fundAmount={Number(fundData.details.starting_amount)}
          fundRemainingAmount={Number(fundData.details.remaining_amount)}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setTab}
          isMatchesListLoading={isFundMatchesLoading || isFundSelectionsLoading}
          isSelectionsListLoading={isFundSelectionsLoading}
          shortlistedSelections={shortlistedSelections}
          isHideUpdatesLoading={isHideUpdatesLoading}
          invitedSelections={invitedSelections}
          fundedProjectsList={fundedProjects?.results || []}
          isFundedProjectsLoading={isFundedProjectsLoading}
          moveProjectToShortlist={moveProjectToShortlist}
          rejectMatchedProject={rejectMatchedProject}
          removeProjectFromShortlist={removeProjectFromShortlist}
          inviteProjectToFund={inviteProjectToFund}
          hideProjectUpdate={hideProjectUpdate}
          showVolunteeringOpportunityBanner={showVolunteeringOpportunityBanner}
          onVotingConfigurationSubmit={onVotingConfigurationSubmit}
          votingModalStatus={votingModalStatus}
          setVotingModalStatus={setVotingModalStatus}
          votingConfigurationUpdateErrors={votingConfigurationUpdateErrors}
          votingConfigurationUpdateLoading={votingConfigurationUpdateLoading}
          updatesPaginatedResponse={updatesPaginatedResponse}
          matchesPaginatedResponse={matchesPaginatedResponse}
        />
      )}
    </>
  );
};
