import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFound404, Loader, WelcomeBanner } from '@Components';
import { useUserContext } from '@Contexts';
import { FundStatusEnum, UserTypeEnum } from '@Types';
import styles from './GroupDashboard.module.scss';
import { Routes, RoutingPaths } from '@App/paths';
import { useRouter } from '@Helpers';
import {
  useGetFundsForGroupMatches,
  useGetLocalAuthorities,
  useGetOrganizationProjects,
  useGroupProfile,
} from '@Hooks/Api';
import { Placeholder } from '@Components/common/Placeholder/Placeholder';
import { HelpCenterCardController } from '@Components/HelpCenterCard/HelpCenterCardController';
import { ProfileCard } from '@Components/ProfileCard/ProfileCard';
import { FundingOpportunitiesPreview } from '@Components/FundingOpportunities/FundingOpportunitiesPreview';
import { ProjectsPreview } from '@Components/Projects/ProjectsPreview';
import { IncompleteProfileCard } from '@Components/ProfileCard/IncompleteProfileCard';
import { getCookie, setCookie } from '@Helpers/cookies';

export const GroupDashboard = () => {
  const { t } = useTranslation('dashboard');
  const { userData } = useUserContext();
  const [fetchProjectsList, { loading: isProjectsListLoading, data: projectsList }] =
    useGetOrganizationProjects();
  const { push } = useRouter();
  const [fetchGroupProfile, { loading: isProfileDataLoading, data: profileData }] =
    useGroupProfile();
  const [getLiveFundsForGroupMatches, { paginatedData: liveFundMatchesResponse }] =
    useGetFundsForGroupMatches();
  const [getLocalAuthorities, { data: localAuthoritiesData, loading: isLocalAuthoritiesLoading }] =
    useGetLocalAuthorities();
  const [isWelcomeBannerVisible, setIsWelcomeBannerVisible] = useState<boolean>(
    getCookie('isWelcomeBannerHidden') !== 'true',
  );

  const welcomeBannerVideoURL = 'https://www.youtube.com/watch?v=TvVOt4QOeEg';

  const onCloseWelcomeBanner = useCallback(() => {
    setIsWelcomeBannerVisible(false);
    setCookie('isWelcomeBannerHidden', `true`, { expires: 90 });
  }, []);

  useEffect(() => {
    getLiveFundsForGroupMatches([FundStatusEnum.LIVE]);
  }, [getLiveFundsForGroupMatches]);

  useEffect(() => {
    fetchGroupProfile();
  }, [fetchGroupProfile]);

  useEffect(() => {
    if (userData) {
      fetchProjectsList(userData.organization_id);
    }
  }, [fetchProjectsList, userData]);

  useEffect(() => {
    getLocalAuthorities();
  }, [getLocalAuthorities]);

  if (userData?.user_type === UserTypeEnum.FUNDER) {
    push(RoutingPaths.DASHBOARD_FUNDER);
    return null;
  }

  if (userData?.user_type !== UserTypeEnum.GROUP) {
    return <NotFound404 />;
  }

  if (!userData?.has_organization_profile && !isProfileDataLoading && !profileData) {
    push(Routes.GROUP_PROFILE_CREATE);
  }

  if (isProfileDataLoading || isLocalAuthoritiesLoading || !localAuthoritiesData) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        {isWelcomeBannerVisible && (
          <WelcomeBanner
            onClose={onCloseWelcomeBanner}
            title={t('group.welcome_banner.title')}
            bodyText={t('group.welcome_banner.text')}
            primaryButtonText={t('group.welcome_banner.primary_button_text')}
            videoURL={welcomeBannerVideoURL}
            secondaryButtonText={t('group.welcome_banner.secondary_button_text')}
            secondaryButtonLink={Routes.FUNDING_OPPORTUNITIES('eligible')}
          />
        )}

        {!(userData.can_modify_organization_profile || (projectsList && projectsList.length > 0)) &&
          !isProjectsListLoading && (
            <Placeholder heading={t('group.no_access_heading')} text={t('group.no_access')} />
          )}

        <div className={styles.previewComponents}>
          <FundingOpportunitiesPreview
            localAuthorities={localAuthoritiesData}
            liveFundMatchesResponse={liveFundMatchesResponse}
          />
          <ProjectsPreview
            projectsList={projectsList}
            isProjectsListLoading={isProjectsListLoading}
          />
        </div>
      </div>
      <div className={styles.sideBarContainer}>
        {profileData && userData ? (
          <ProfileCard
            profileData={profileData}
            userData={userData}
            tooltipTitle={t('group.progress_tooltip_title')}
            tooltipText={t('group.progress_tooltip_text')}
          />
        ) : (
          <IncompleteProfileCard userType={userData.user_type} />
        )}
        <HelpCenterCardController />
      </div>
    </div>
  );
};
