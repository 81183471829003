import { useTranslation } from 'react-i18next';
import { Fund, LocalAuthority } from '@Types';
import { Tile, Title, PreviewCTA, Pagination } from '@Components/common';
import { Divider } from '@Components/Divider/Divider';
import { FundingOpportunityCard } from './Components/FundingOpportunityCard';
import styles from './FundingOpportunitiesPreview.module.scss';
import { Grid } from '@material-ui/core';
import { Routes } from '@App/paths';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';

export interface FundingOpportunitiesPreviewProps {
  liveFundMatchesResponse: PaginatedResponse<Fund>;
  localAuthorities: LocalAuthority[];
}

export const FundingOpportunitiesPreview = ({
  liveFundMatchesResponse,
  localAuthorities,
}: FundingOpportunitiesPreviewProps) => {
  const { t } = useTranslation('fundingOpportunities');
  const {
    loading: isLiveFundMatchesLoading,
    page: liveFundMatchesList,
    totalCount: liveFundMatchesListTotalCount,
  } = liveFundMatchesResponse;

  const hasMatches = !!liveFundMatchesList?.length && liveFundMatchesList.length > 0;
  return (
    <Tile className={styles.container}>
      <>
        <Title
          title={t('funding_opportunities_preview_title')}
          subtitle={t('subtitle')}
          tooltip={t('tooltip')}
          value={liveFundMatchesListTotalCount || 0}
        />
        <Divider small />
        <Grid container spacing={1} className={styles.fundMatchContainer}>
          {!isLiveFundMatchesLoading &&
            liveFundMatchesList &&
            liveFundMatchesList.map(
              ({ id, details, funding_profile, group_has_project_matched }) => (
                <Grid item className={styles.fundMatchItem} key={id}>
                  <FundingOpportunityCard
                    id={id}
                    details={details}
                    funding_profile={funding_profile}
                    localAuthorities={localAuthorities}
                    preview
                    groupHasProjectMatched={group_has_project_matched}
                  />
                </Grid>
              ),
            )}
          <Pagination paginatedResponse={liveFundMatchesResponse} />
        </Grid>
        <div className={styles.ctaContainer}>
          {hasMatches && <Divider small />}
          <PreviewCTA
            buttonText={t('funding_opportunities_preview_cta')}
            to={
              hasMatches
                ? Routes.FUNDING_OPPORTUNITIES('eligible')
                : Routes.FUNDING_OPPORTUNITIES('other')
            }
          />
        </div>
      </>
    </Tile>
  );
};
