import { Button, Tile } from '@Components/common';
import styles from './Action.module.scss';
import { ReactElement } from 'react';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

interface ActionProps {
  title: string;
  text: string | ReactElement;
  buttonText: string;
  complete: boolean;
  to?: string;
  buttonIcon?: ReactElement;
  onClick?: () => void;
  showButton: boolean;
  icon: ReactElement;
}

export const Action = ({
  title,
  text,
  to,
  buttonText,
  buttonIcon,
  onClick,
  complete,
  showButton,
  icon,
}: ActionProps) => {
  const { t } = useTranslation('project');

  return (
    <Tile className={styles.tile}>
      <div className={styles.container}>
        <div className={classnames(styles.textContainer, complete && styles.complete)}>
          <div className={styles.actionIcon}>{icon}</div>
          <div className={styles.text}>
            <h3 className={styles.title}>{title}</h3>
            <p>{text}</p>
          </div>
        </div>
        {complete ? (
          <div className={styles.completeIndicator}>
            <RiCheckboxCircleFill />
            <p>{t('management.complete')}</p>
          </div>
        ) : (
          <>
            {showButton && (
              <Button
                buttonType="secondary"
                to={to}
                onClick={onClick}
                startIcon={buttonIcon}
                className={styles.button}
              >
                {buttonText}
              </Button>
            )}
          </>
        )}
      </div>
    </Tile>
  );
};
