// Modal to show the sharing link for voting, shown immediately after voting is configured.
import { useTranslation } from 'react-i18next';

import { ContentModal } from '@Components';
import { FundVotingConfiguration } from '@Types';
import { CopyToClipboard } from '@Components/CopyToClipboard/CopyToClipboard';
import styles from './VotingConfigurationModal.module.scss';

export interface VotingLinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  votingConfiguration: FundVotingConfiguration;
}

export const VotingLinkModal = ({ isOpen, onClose, votingConfiguration }: VotingLinkModalProps) => {
  const { t } = useTranslation('fund');

  return (
    <ContentModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('voting_configuration.sharing_link_heading')}
    >
      <div className={styles.introText}>{t('voting_configuration.voting_set_up')}</div>
      <div className={styles.introText}>{t('voting_configuration.sharing_link_intro_text')}</div>
      <CopyToClipboard valueToCopy={votingConfiguration.sharing_link} />
    </ContentModal>
  );
};
