import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CreateActionWrapper, NotFound404, ProjectPitchController } from '@Components';

import { HelpCenterRoutes, PathParams } from '@App/paths';
import { useRouter } from '@Helpers/useRouter';
import { scrollToTop } from '@Helpers/scrollToTop';
import { ProjectDetailsController } from '@Views/Project/ProjectDetails/ProjectDetailsController';
import { DeliveryPlanController } from '@Views/Project/DeliveryPlan/DeliveryPlanController';
import { PitchTypesEnum } from '@Types';
import InfoIcon from '@material-ui/icons/Info';
import styles from './ProjectFormController.module.scss';
import { useUserContext } from '@Contexts';
import { getHelpCenterURL } from '@Helpers/helpCenter';
import { Link } from '@Components/common';

interface ProjectFormControllerProps {
  isEdit?: boolean;
}

export const ProjectFormController: React.FC<ProjectFormControllerProps> = ({ isEdit = false }) => {
  const { t } = useTranslation('project');
  const { params } = useRouter();
  const [currentStep, setCurrentStep] = useState(0);
  const [projectId, setProjectId] = useState<number | undefined>(
    Number(params[PathParams.PROJECT_ID]) || undefined,
  );
  const [grantAmount, setGrantAmount] = useState<number>();
  const [pitchType, setPitchType] = useState<PitchTypesEnum | undefined>();
  const [isProjectEditable, setIsProjectEditable] = useState<boolean>(true);
  const [isProjectPublished, setIsProjectPublished] = useState<boolean>(false);
  const [isDeliveryCreated, setIsDeliveryCreated] = useState<boolean>(false);
  const [isProjectInitiallyPublished, setIsProjectInitiallyPublished] = useState(false);

  // These are used to temporarily store create/edit project data to allow user the
  // ability to not lose data when navigating between tabs before saving.
  const [deliveryPlanFormData, setDeliveryPlanFormData] = useState();
  const [pitchFormData, setPitchFormData] = useState();
  const { userData, isUserLoading } = useUserContext();

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    scrollToTop();
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
    scrollToTop();
  };

  const setProjectData = (
    projectId: number,
    grantAmount: number,
    _isProjectEditable?: boolean,
    _isProjectPublished?: boolean,
    _isDeliveryCreated?: boolean,
    _isProjectInitiallyPublished?: boolean,
  ) => {
    setProjectId(projectId);
    setGrantAmount(grantAmount);
    if (_isProjectEditable !== undefined) {
      setIsProjectEditable(_isProjectEditable);
    }
    if (_isProjectPublished !== undefined) {
      setIsProjectPublished(_isProjectPublished);
    }
    if (_isDeliveryCreated !== undefined) {
      setIsDeliveryCreated(_isDeliveryCreated);
    }
    if (_isProjectInitiallyPublished !== undefined) {
      setIsProjectInitiallyPublished(_isProjectInitiallyPublished);
    }
  };

  const step_1_guide = userData
    ? getHelpCenterURL({
        path: HelpCenterRoutes.PROJECT_GUIDE_STEP_1,
        user: userData,
      })
    : '';

  const step_2_guide = userData
    ? getHelpCenterURL({
        path: HelpCenterRoutes.PROJECT_GUIDE_STEP_2,
        user: userData,
      })
    : '';

  const step_3_guide = userData
    ? getHelpCenterURL({
        path: HelpCenterRoutes.PROJECT_GUIDE_STEP_3,
        user: userData,
      })
    : '';

  const steps = [
    { label: t('steps.key_details') },
    { label: t('steps.pitch') },
    { label: t('steps.delivery_plan') },
  ];

  const pageHeading = [
    {
      header: isEdit ? t('details.header_edit') : t('details.header'),
      subheader: (
        <Trans
          ns="project"
          i18nKey="details.subheader"
          components={[<Link underline className={styles.subHeaderLink} href={step_1_guide} />]}
        />
      ),
    },
    {
      header: t('pitch.header'),
      subheader: (
        <Trans
          ns="project"
          i18nKey="pitch.subheader"
          components={[<Link underline className={styles.subHeaderLink} href={step_2_guide} />]}
        />
      ),
    },
    {
      header: t('delivery.header'),
      subheader: (
        <Trans
          ns="project"
          i18nKey="delivery.subheader"
          components={[<Link underline className={styles.subHeaderLink} href={step_3_guide} />]}
        />
      ),
    },
  ];

  const content = [
    <ProjectDetailsController
      currentStep={currentStep}
      stepsCount={steps.length}
      nextStep={nextStep}
      setProjectData={setProjectData}
      projectId={projectId}
      setPitchType={setPitchType}
    />,
    <ProjectPitchController
      currentStep={currentStep}
      stepsCount={steps.length}
      projectId={projectId}
      nextStep={nextStep}
      prevStep={prevStep}
      pitchType={pitchType}
      setPitchType={setPitchType}
      pitchFormData={pitchFormData}
      setPitchFormData={setPitchFormData}
    />,
    <DeliveryPlanController
      currentStep={currentStep}
      stepsCount={steps.length}
      projectId={projectId}
      setProjectData={setProjectData}
      isEdit={isEdit}
      prevStep={prevStep}
      grantAmount={grantAmount}
      isProjectPublished={isProjectPublished}
      isProjectInitiallyPublished={isProjectInitiallyPublished}
      isDeliveryCreated={isDeliveryCreated}
      setDeliveryPlanFormData={setDeliveryPlanFormData}
      deliveryPlanFormData={deliveryPlanFormData}
    />,
  ];

  if (!isProjectEditable) return <NotFound404 />;

  return (
    <>
      {isEdit && isProjectInitiallyPublished && (
        <div className={styles.editInfoWrapper}>
          <div className={styles.infoIcon}>
            <InfoIcon />
          </div>
          <div className={styles.editInfoNote}>{t('steps.status_edit_info')}</div>
        </div>
      )}
      <CreateActionWrapper steps={steps} currentStep={currentStep} {...pageHeading[currentStep]}>
        {content[currentStep]}
      </CreateActionWrapper>
    </>
  );
};
